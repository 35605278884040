import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Interval } from '../../../../../../Common/data/interval';
import { Direction } from '../../../../../../Common/data/position';
import { CrosswordTile, CrosswordTileEvent, QuestionWithCrosswordTiles, TilesState } from '../../../../../../Common/data/tile';
import { ICrosswordSize } from '../../../../../../Common/model/crossword';
import { ICWQuestionBase } from '../../../../../../Common/model/cwquestion';
import { TileUtil } from '../tile.util';

export enum ShowCrosswordAs {
  Matrix,
  List,
}

export interface IHandleTileEventTypeModel {
  getIntervalFromQuestion(cwQuestion: ICWQuestionBase): Interval;
  getFirstCWQuestionFromPoint(x: number, y: number): ICWQuestionBase;
  removeCharSetOnRowTile(rowTile: CrosswordTile): void;
  removeCharSetOnMatrixTile(matrixTile: CrosswordTile): void;
  addCharSetOnRowTile(rowTile: CrosswordTile, char: string): void;
  addCharSetOnMatrixTile(matrixTile: CrosswordTile, char: string): void;
  canCharsBeAdded(): boolean;
}

export interface IHandleMatrixTileEventType {
  handleMatrixTileEventType(
    tileEvent: CrosswordTileEvent,
    state: TilesState,
    tiles: CrosswordTile[][],
    matrixDirection: Direction,
  ): TilesState;
}
export interface IHandleRowTileEventType {
  handleRowTileEventType(tileEvent: CrosswordTileEvent, state: TilesState, tiles: CrosswordTile[]): TilesState;
}
export interface IHandleCrosswordView {
  handleMatrixTileEvent(tileEvent: CrosswordTileEvent, matrixDirection: Direction): void;
  handleRowTileEvent(tileEvent: CrosswordTileEvent, tiles: CrosswordTile[]): void;
  getMatrixCrosswordTiles(): CrosswordTile[][];
  getRowCrosswordTiles(): QuestionWithCrosswordTiles[];
  getCrosswordSize(): ICrosswordSize;
  tilesStateObservable(): Observable<TilesState>;
  tilesChangedObservable(): Observable<void>;
  setShowCrosswordAs(value: ShowCrosswordAs): void;
  showCrosswordAsObservable(): Observable<ShowCrosswordAs>;
}
export const DefaultTileState = { focused: { tile: null, cwQuestion: null, interval: null }, canAddChars: false };
export abstract class HandleCrosswordView implements IHandleCrosswordView {
  protected readonly currentTilesState = new BehaviorSubject<TilesState>(DefaultTileState);
  protected readonly tilesChangedSubject = new Subject<void>();
  protected matrixTiles: CrosswordTile[][];
  protected rowTiles: QuestionWithCrosswordTiles[];
  protected readonly handleMatrixEventTypes: IHandleMatrixTileEventType[] = [];
  protected readonly handleRowEventTypes: IHandleRowTileEventType[] = [];
  protected readonly showCrosswordAs = new BehaviorSubject<ShowCrosswordAs>(ShowCrosswordAs.Matrix);
  tilesChangedObservable(): Observable<void> {
    return this.tilesChangedSubject.asObservable();
  }
  tilesStateObservable(): Observable<TilesState> {
    return this.currentTilesState.asObservable();
  }
  showCrosswordAsObservable(): Observable<ShowCrosswordAs> {
    return this.showCrosswordAs.asObservable();
  }
  setShowCrosswordAs(value: ShowCrosswordAs) {
    if (this.showCrosswordAs.getValue() != value) {
      this.showCrosswordAs.next(value);
      this.onShowCrosswordAsChanged(value);
    }
  }
  getCurrentTilesState() {
    return this.currentTilesState.getValue();
  }
  getRowCrosswordTiles() {
    return this.rowTiles;
  }
  getMatrixCrosswordTiles() {
    return this.matrixTiles;
  }
  abstract handleMatrixTileEvent(tileEvent: CrosswordTileEvent, matrixDirection: Direction): void;
  abstract handleRowTileEvent(tileEvent: CrosswordTileEvent, tiles: CrosswordTile[]): void;
  abstract getCrosswordSize(): ICrosswordSize;
  protected abstract onShowCrosswordAsChanged(value: ShowCrosswordAs): void;
  protected updateTilesState(tilesState: TilesState, forceChange?: boolean) {
    if (!TileUtil.isTileStateEqual(tilesState, this.currentTilesState.getValue()) || forceChange) {
      this.currentTilesState.next(tilesState);
    }
  }
}
