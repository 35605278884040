<div
  #tileDiv
  class="tile stretch-max"
  [ngStyle]="{
    'border-left.px': greyStyle.left * greyWidth,
    'border-right.px': greyWidth,
    'border-top.px': greyStyle.top * greyWidth,
    'border-bottom.px': greyWidth,
    'border-style': StateAndColors.style,
    'border-color': StateAndColors.borderColor,
  }"
>
  <div
    class="stretch-max"
    [ngClass]="{ 'inner-div-edit': canEdit, 'inner-div-disabled': !canEdit }"
    [ngStyle]="{
      'border-left.px': highlightStyle.left * highLightWidth,
      'border-right.px': highlightStyle.right * highLightWidth,
      'border-top.px': highlightStyle.top * highLightWidth,
      'border-bottom.px': highlightStyle.bottom * highLightWidth,
      'border-style': StateAndColors.style,
      'border-color': StateAndColors.selectedBorderColor,
      'background-color': tile.type | crosswordTileStyle: tile.text : tile.typeData,
    }"
  >
    <ng-content></ng-content>
  </div>
</div>
