import { AssertUtil } from '../util/assert.util';
import { Direction, IPosition, Position } from './position';

export interface IInterval extends IPosition {
  length: number;
}

export class Interval extends Position implements IInterval {
  length: number;

  constructor(x = 0, y = 0, direction = Direction.MAINAXIS, length: number = 0) {
    super(x, y, direction);
    this.length = length;
  }

  parse(obj) {
    super.parse(obj);
    this.length = obj.length;
    return this;
  }
  toPosition() {
    return new Position(this.x, this.y, this.direction);
  }

  toPositions(): Position[] {
    const crossDirection: Direction = (this.direction + 1) % 2;
    const axisPositions = this.getSequence(this.length);
    const crossAxisPositions = axisPositions.map((p) => new Position(p.x, p.y, crossDirection));
    return [...axisPositions, ...crossAxisPositions];
  }
  subtractLength(length: number) {
    return new Interval(this.x, this.y, this.direction, this.length - length);
  }

  insideInterval(x: number, y: number) {
    if (this.direction === Direction.MAINAXIS) {
      return y === this.y && x >= this.x && x <= this.x + this.length - 1;
    } else {
      return x === this.x && y >= this.y && y <= this.y + this.length - 1;
    }
  }
  doesIntervalIntersect(interval: Interval) {
    if (this.direction === Direction.MAINAXIS && interval.direction == Direction.CROSSAXIS) {
      return this.insideInterval(interval.x, this.y) && interval.insideInterval(interval.x, this.y);
    }
    if (this.direction === Direction.CROSSAXIS && interval.direction == Direction.MAINAXIS) {
      return this.insideInterval(this.x, interval.y) && interval.insideInterval(this.x, interval.y);
    } else {
      return this.insideInterval(interval.x, interval.y) || interval.insideInterval(this.x, this.y);
    }
  }
  positionThatIntersects(interval: Interval) {
    AssertUtil.assert(() => this.direction != interval.direction);
    if (this.direction === Direction.MAINAXIS && interval.direction == Direction.CROSSAXIS) {
      return new Position(interval.x, this.y, this.direction);
    }
    if (this.direction === Direction.CROSSAXIS && interval.direction == Direction.MAINAXIS) {
      return new Position(this.x, interval.y, this.direction);
    }
  }
  equalInterval(interval: Interval) {
    return super.equal(interval) && this.length === interval.length;
  }
  static fromPosition(position: IPosition, length: number) {
    return new Interval(position.x, position.y, position.direction, length);
  }
}
