import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { ErrorResult } from '../../../../Common/data/error.result';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      filter((event) => event instanceof HttpResponse),
      map((event: HttpResponse<any>) => {
        if (event.status === 200 && event.body?.error) {
          const error: ErrorResult = event.body.error;
          throw new HttpErrorResponse({ error: error, status: error.code, statusText: error.message });
        }
        return event;
      }),
    );
  }
}
