import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { UserService } from '../services/user/user.service';

@Injectable()
export class CheckLoggedIn implements CanActivate {
  path: ActivatedRouteSnapshot[];
  route: ActivatedRouteSnapshot;

  constructor(
    private userService: UserService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {
    const redirectUrl = this.getResolvedUrl(route);
    await this.userService.waitUntilInitialized();
    const loggedIn = this.userService.isLoggedIn();

    if (loggedIn) {
      return true;
    }

    this.router.navigateByUrl(
      this.router.createUrlTree(['/login'], {
        queryParams: {
          redirectUrl,
        },
      }),
    );

    return false;
  }
  getResolvedUrl(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot.map((v) => v.url.map((segment) => segment.toString()).join('/')).join('/');
  }
}
