import { GenerateCrosswordData } from '../../data/generate.crossword.data';
import { PriorityValue } from '../../data/priority.value';
import { MatchPositionAndQuestion } from '../util/match.position.for.question';
import { BuildMatrix } from '../util/matrix';
import { RemoveQuestions } from '../util/remove.questions';
import { CrosswordBuildAction } from './build.action';

export class RepositionQuestionsToBetterPositions implements CrosswordBuildAction {
  constructor(
    private readonly settings: GenerateCrosswordData,
    private readonly matchPositionAndQuestion: MatchPositionAndQuestion,
    private readonly removeNumberOfQuestions: RemoveQuestions,
    private priority: PriorityValue
  ) {}
  run(cmatrixOrg: BuildMatrix) {
    const numberOfQuestions = this.settings.accuracy.repositionQuestions;
    const cmatrix = cmatrixOrg.copy();
    const questionsToFit = this.removeNumberOfQuestions.remove(numberOfQuestions, cmatrix, this.priority);
    let intervals = cmatrix.getOpenIntervals();
    for (const questionToFit of questionsToFit) {
      const qp = this.matchPositionAndQuestion.getPositionForQuestion(questionToFit, intervals);
      if (qp) {
        cmatrix.addQuestion(qp.position, qp.question);
        intervals = cmatrix.getOpenIntervals();
      }
    }
    return cmatrix.getScore().greatherThen(cmatrixOrg.getScore()) ? cmatrix : cmatrixOrg;
  }
}
