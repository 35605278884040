import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ICrosswordGame } from '../../../../../../Common/model/crossword.game';
import { IPlayer } from '../../../../../../Common/model/player';
import { ShareService } from '../../../services/share/share.service';
import { UserService } from '../../../services/user/user.service';

export interface ShareGameSettings {
  crosswordGame: ICrosswordGame;
  player: IPlayer;
}

@Component({
  selector: 'app-share-game',
  templateUrl: './share-game.component.html',
})
export class ShareGameComponent implements OnInit {
  supportsDeviceShare: boolean;
  initiated: boolean;
  constructor(
    public matDialogRef: MatDialogRef<ShareGameComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ShareGameSettings,
    private translateService: TranslateService,
    private shareService: ShareService,
    private userService: UserService,
  ) {}
  async ngOnInit() {
    this.supportsDeviceShare = await this.shareService.supportsDeviceShare();
    this.initiated = true;
  }

  getEmailLink() {
    return `mailto:?subject=${this.translateService.instant('misc.invite-email-subject')}?&body=${this.getBodyText()}.`;
  }

  onShareGame() {
    this.shareService.share(this.getBodyText(), this.getUrl());
  }

  private getBodyText() {
    return this.translateService.instant('misc.invite-body-text', {
      player: this.data.player.name,
      crosswordName: this.data.crosswordGame.initial.crossword.name,
      joinCode: this.data.crosswordGame.initial.joinCode,
    });
  }
  private getUrl() {
    const urlWithProtocol = `${window.location.origin}/?invitedBy=${this.userService.getLoggedIn().name}`;
    return urlWithProtocol;
  }
}
