import { PriorityValue } from '../../data/priority.value';
import { BuildMatrix, BuildQuestion } from './matrix';
import { RandomizeUtil } from './randomize.util';
export class RemoveQuestions {
  constructor() {}
  public remove(questionsCount: number, matrix: BuildMatrix, priority: PriorityValue) {
    const questionsToRemove = RandomizeUtil.shuffle(matrix.getQuestions())
      .filter((q) => this.shouldRemoveQuestion(q, priority))
      .slice(0, questionsCount);
    questionsToRemove.forEach((q) => matrix.removeQuestion(q.indexId));
    return questionsToRemove;
  }
  public removeOneQuestion(matrix: BuildMatrix, priority: PriorityValue) {
    const questionToRemove = RandomizeUtil.shuffle(matrix.getQuestions()).filter((q) => this.shouldRemoveQuestion(q, priority))[0];
    return matrix.removeQuestion(questionToRemove.indexId);
  }
  private shouldRemoveQuestion(question: BuildQuestion, priority: PriorityValue) {
    return question.priority == priority;
  }
}
