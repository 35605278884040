<div>
  <mat-select
    placeholder="{{ 'labels.question-priority' | translate }}"
    [value]="currentPrioritySelected | async"
    (valueChange)="currentPrioritySelected.next($event)"
  >
    @if (currentPrioritySelected | async) {
      <mat-select-trigger fxLayout="row" fxLayoutAlign="left center" fxLayoutGap="5px">
        <mat-icon [ngStyle]="{ color: getIconColor(currentPrioritySelected | async) }">circle</mat-icon>
        <div>{{ (currentPrioritySelected | async).label | translate }}</div>
      </mat-select-trigger>
    }
    @for (questionPriority of QuestionPriorities; track questionPriority) {
      <mat-option [value]="questionPriority">
        <mat-icon [ngStyle]="{ color: getIconColor(questionPriority) }">circle</mat-icon>
        {{ questionPriority.label | translate }}
      </mat-option>
    }
  </mat-select>
</div>
