import { Injectable } from '@angular/core';
import { ICrosswordGame } from '../../../../../Common/model/crossword.game';
import { CrosswordGameUtil } from '../../../../../Common/util/crossword.game.util';
import { SelectPlayerColorData } from '../../components/dialog/select-player-color/select-player-color.component';
import { DialogService } from '../../components/dialog/service/dialog.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class GamePlayerColorService {
  constructor(
    private userService: UserService,
    private dialogService: DialogService,
  ) {}

  async getNewPlayerColor(crosswordGame: ICrosswordGame, primaryColorIsTaken: boolean, allowCancel = false) {
    const availablePlayerColors = CrosswordGameUtil.getAvailablePlayerColors(crosswordGame);
    const data: SelectPlayerColorData = {
      gamePlayerColorsAvailable: availablePlayerColors,
      playersPrimaryColorIsTaken: primaryColorIsTaken,
      allowCancel: allowCancel,
    };
    const selectedColorData = await this.dialogService.showSelectPlayerColor(data);
    if (!selectedColorData) {
      return;
    }
    if (selectedColorData.rememberDecision) {
      const playerSettings = this.userService.getPlayerSettings();
      playerSettings.preferredPlayerColor = selectedColorData.gamePlayerColor;
      await this.userService.savePlayerSettings();
    }
    return selectedColorData.gamePlayerColor;
  }
}
