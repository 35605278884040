import { SelectionModel } from '@angular/cdk/collections';
import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { CWQuestionCategory, ICWQuestion } from '../../../../../../Common/model/cwquestion';
import { SearchQuestionsItem, SearchQuestionsQuery, SortDirection } from '../../../../../../Common/query/search.question.query';
import { CWQuestionUtil, FilterCWQuestion } from '../../../../../../Common/util/cwquestion.util';
import { IdUtil } from '../../../../../../Common/util/id.util';
import { IndexPriorityUtil } from '../../../../../../Common/util/index.priority.util';
import { ObjectUtil } from '../../../../../../Common/util/object.util';
import { CWQuestionService } from '../../../services/cwquestion/cwquestion.service';
import { UserService } from '../../../services/user/user.service';
import { FlexibleButtonInMenu, FlexibleButtonType } from '../../common/flexable-button-menu/model/flexible.button.in.menu';
import { UpdateFlexibleButtonUtil } from '../../common/flexable-button-menu/util/update.button.util';
import { MessageBoxType } from '../../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../dialog/service/dialog.service';
import { EditQuestionDialogService } from '../../dialog/service/edit-question-dialog.service';
import { CrosswordConstants } from '../../util/crossword.constants';
import { PriorityMatchColor } from '../../util/priority.match.color';
import { SizeChanged } from '../../util/resize.directive';
import { CrosswordBuildForm } from '../build-crossword.component';
import { CWQuestionDataSource } from './cwquestion.datasource';
import { LocalizedPaginatorIntl } from './localized.paginator';
import { FilterQuestions } from './models/filter.questions';
import { SelectCWQuestionType } from './select-cwquestion-type';
import { QuestionIds } from './select-filter-questions/select-filter-questions.component';

@Component({
  selector: 'app-select-cwquestions',
  templateUrl: './select-cwquestions.component.html',
  styleUrls: ['./select-cwquestions.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: LocalizedPaginatorIntl }],
})
export class SelectCWQuestionsComponent implements AfterViewInit, AfterContentInit, OnDestroy, OnInit {
  SelectCWQuestionType = SelectCWQuestionType;

  displayedColumns: string[] = [];
  cwQuestionsModel: CWQuestionDataSource;

  cwQuestionsSelection: SelectionModel<number>;
  @Input()
  crosswordBuildForm: CrosswordBuildForm;
  @Input()
  sampleQuestionsChanged: Subject<void>;
  @Input()
  type: SelectCWQuestionType;
  @Input()
  filterVisibleQuestions?: FilterCWQuestion;

  loadQuestionsQuery: SearchQuestionsQuery;
  filterQuestionIds: QuestionIds;
  @Output()
  selectedCWQuestionsChanged = new EventEmitter();
  @Output()
  addedNewQuestion = new EventEmitter();
  currentPlayerId: string;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  actionButtons: FlexibleButtonInMenu[] = [];
  private alive = true;
  constructor(
    private translateService: TranslateService,
    private cwQuestionService: CWQuestionService,
    private dialogService: DialogService,
    private editQuestionDialogService: EditQuestionDialogService,
    private userService: UserService,
  ) {
    this.loadQuestionsQuery = {
      pageIndex: 0,
      pageSize: 10,
    };
    this.filterQuestionIds = {};
  }

  async ngOnInit() {
    if (this.filterVisibleQuestions) {
      this.loadQuestionsQuery = { ...this.loadQuestionsQuery, advfilter: this.filterVisibleQuestions };
    }
    this.setFilterQuestionIds();
    this.displayedColumns = this.getBigScreenColumns();
    this.cwQuestionsModel = new CWQuestionDataSource(this.cwQuestionService);
    this.currentPlayerId = this.userService.getLoggedIn()._id;
    this.cwQuestionsSelection = new SelectionModel<number>(this.type != SelectCWQuestionType.SelectOne, []);
    this.cwQuestionsSelection.changed.subscribe(() => this.onSelectedQuestionsChanged());
  }

  ngAfterViewInit() {
    this.sort.sortChange.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.paginator.pageIndex = 0;
      const direction: SortDirection = this.sort.direction == 'asc' ? SortDirection.Ascending : SortDirection.Descending;
      this.loadQuestions({ ...this.loadQuestionsQuery, pageIndex: 0, sortColumn: this.sort.active, sortDirection: direction });
    });
    this.paginator.page
      .pipe(takeWhile(() => this.alive))
      .subscribe((value) => this.loadQuestions({ ...this.loadQuestionsQuery, pageIndex: value.pageIndex, pageSize: value.pageSize }));
  }
  ngAfterContentInit() {
    this.refreshActionButtons();
    this.setIdsAndLoadQuestions(false);
    this.sampleQuestionsChanged.pipe(takeWhile(() => this.alive)).subscribe(() => {
      this.setIdsAndLoadQuestions(true);
      this.setFilterQuestionIds();
    });
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
  applyFilter(filter: string) {
    this.paginator.pageIndex = 0;
    this.loadQuestions({ ...this.loadQuestionsQuery, filter: filter, pageIndex: 0 }, true);
  }
  onSelectedQuestionsChanged() {
    const ids = this.cwQuestionsSelection.selected;
    this.selectedCWQuestionsChanged.emit(ids);
    this.refreshActionButtons();
  }
  isAllQuestionsSelected() {
    const selectedIds = new Set<number>(this.cwQuestionsSelection.selected);
    const allIds = this.cwQuestionsModel.allIds.getValue();
    if (selectedIds.size == 0 || selectedIds.size != allIds.length) {
      return false;
    }
    return allIds.find((id) => !selectedIds.has(id)) == null;
  }
  onSelectedAllQuestions(value: boolean) {
    const ids = this.cwQuestionsModel.allIds.getValue();

    if (value) {
      this.cwQuestionsSelection.select(...ids);
    } else {
      this.cwQuestionsSelection.deselect(...ids);
    }
  }
  onRemoveSelectedQuestions() {
    const idsToRemove = this.cwQuestionsSelection.selected;
    this.crosswordBuildForm.cwQuestionsIds.setValue(IndexPriorityUtil.removeIds(this.crosswordBuildForm.cwQuestionsIds.value, idsToRemove));

    this.refreshActionButtons();
    this.sampleQuestionsChanged.next();
    this.cwQuestionsSelection.clear();
  }
  async onUseSelectedQuestions() {
    const result = await this.choosePriorityForSelectedQuestions();
    if (result) {
      this.sampleQuestionsChanged.next();
      this.cwQuestionsSelection.clear();
    }
  }

  async onChoosePriority() {
    await this.choosePriorityForSelectedQuestions();
  }
  onSizeChanged(size: SizeChanged) {
    this.displayedColumns = size.width < 500 ? this.getSmallScreenColumns() : this.getBigScreenColumns();
  }
  onFilterQuestionsChanged(filterQuestions: FilterQuestions) {
    this.paginator.pageIndex = 0;
    this.setIdsAndLoadQuestions(true, {
      ...this.loadQuestionsQuery,
      tags: filterQuestions.tags.length > 0 ? filterQuestions.tags : null,
      priorities: filterQuestions.priorities.length > 0 ? filterQuestions.priorities : null,
      answerLengths: filterQuestions.answerLengths.length > 0 ? filterQuestions.answerLengths : null,
      categories: filterQuestions.categories.length > 0 ? filterQuestions.categories : null,
      pageIndex: 0,
    });
  }
  async onDeleteQuestion(item: SearchQuestionsItem) {
    const question = CWQuestionUtil.getCWQuestionFromSearchQuestionsItem(item, this.getBrowserLang());
    const result = await this.dialogService.showMessageBox(
      '',
      this.translateService.instant('messages.remove-cwquestion', { question: question.base.question }),
      MessageBoxType.YesNo,
      true,
    );
    if (result) {
      await this.cwQuestionService.deleteCWQuestion(question);
      this.crosswordBuildForm.cwQuestionsIds.setValue(
        IndexPriorityUtil.getIndexesWithRemovedId(this.crosswordBuildForm.cwQuestionsIds.value, question.base.indexId),
      );
      this.setIdsAndLoadQuestions(false);
      this.refreshActionButtons();
    }
  }
  async onEditQuestion(item: SearchQuestionsItem) {
    const question = CWQuestionUtil.getCWQuestionFromSearchQuestionsItem(item, this.getBrowserLang());
    const cwQuestion: ICWQuestion = await this.editQuestionDialogService.showEditQuestionDialog({
      cwQuestion: question,
      filter: this.filterVisibleQuestions,
    });
    if (cwQuestion) {
      await this.cwQuestionService.updateCWQuestion(cwQuestion);
      this.reloadQuestions();
    }
  }
  async onAddOwnCWQuestion() {
    const emptyQuestion: ICWQuestion = CWQuestionUtil.getEmptyQuestion(this.translateService.getBrowserLang());
    const cwQuestion: ICWQuestion = await this.editQuestionDialogService.showEditQuestionDialog({
      cwQuestion: emptyQuestion,
      filter: this.filterVisibleQuestions,
    });
    if (cwQuestion) {
      const priority = await this.dialogService.showQuestionPriorityDialog(true);
      const cwQuestionsIds = this.crosswordBuildForm.cwQuestionsIds.value;
      this.crosswordBuildForm.cwQuestionsIds.setValue([...cwQuestionsIds, { index: cwQuestion.base.indexId, priority: priority }]);
      this.setIdsAndLoadQuestions(false);
      this.refreshActionButtons();
      this.addedNewQuestion.emit(cwQuestion.base.indexId);
    }
  }
  getCategoryKey(category: CWQuestionCategory) {
    return CrosswordConstants.CWQuestionCategories.find((c) => c.value == category)?.label;
  }
  canChangeQuestion(questionData: SearchQuestionsItem) {
    if (this.isAdministrator()) {
      return true;
    }
    if (IdUtil.idEquals(questionData.authorId, this.currentPlayerId)) {
      return true;
    }
    return false;
  }
  getPriorityCircleColor(item: SearchQuestionsItem) {
    const cwQuestionsIds = this.crosswordBuildForm.cwQuestionsIds.value;
    const priorityIndex = IndexPriorityUtil.findIndex(cwQuestionsIds, item.indexId);
    if (!priorityIndex) {
      return;
    }
    return PriorityMatchColor.getColor(priorityIndex.priority);
  }
  private refreshActionButtons() {
    this.actionButtons = [];
    if (this.type == SelectCWQuestionType.SelectOne) {
      this.actionButtons[0] = UpdateFlexibleButtonUtil.update(
        {
          id: 'add-own-question',
          text: this.translateService.instant('buttons.add-own-question'),
          icon: 'add',
          type: FlexibleButtonType.Standard,
          standardAction: {
            event: () => this.onAddOwnCWQuestion(),
          },
        },
        this.actionButtons[0],
      );
    } else if (this.type == SelectCWQuestionType.Select) {
      this.actionButtons[0] = UpdateFlexibleButtonUtil.update(
        {
          id: 'use-selected-questions',
          text: this.translateService.instant('buttons.use-selected-questions'),
          icon: 'check',
          disabled: !this.cwQuestionsSelection.hasValue(),
          type: FlexibleButtonType.Standard,
          standardAction: {
            event: () => this.onUseSelectedQuestions(),
          },
        },
        this.actionButtons[0],
      );
    } else if (this.type == SelectCWQuestionType.Selected) {
      this.actionButtons[0] = UpdateFlexibleButtonUtil.update(
        {
          id: 'remove-selected-questions',
          text: this.translateService.instant('buttons.remove-selected-questions'),
          icon: 'remove',
          disabled: !this.cwQuestionsSelection.hasValue(),
          type: FlexibleButtonType.Standard,
          standardAction: {
            event: () => this.onRemoveSelectedQuestions(),
          },
        },
        this.actionButtons[0],
      );
      this.actionButtons[1] = UpdateFlexibleButtonUtil.update(
        {
          id: 'add-own-question',
          text: this.translateService.instant('buttons.add-own-question'),
          icon: 'add',
          type: FlexibleButtonType.Standard,
          standardAction: {
            event: () => this.onAddOwnCWQuestion(),
          },
        },
        this.actionButtons[1],
      );
      this.actionButtons[2] = UpdateFlexibleButtonUtil.update(
        {
          id: 'generate-questions',
          text: this.translateService.instant('buttons.generate-questions'),
          icon: 'factory',
          type: FlexibleButtonType.Standard,
          standardAction: {
            event: () => this.onGenerateQuestions(),
          },
        },
        this.actionButtons[2],
      );
      this.actionButtons[3] = UpdateFlexibleButtonUtil.update(
        {
          id: 'choose-priority',
          text: this.translateService.instant('buttons.choose-priority'),
          icon: 'rule',
          disabled: !this.cwQuestionsSelection.hasValue(),
          type: FlexibleButtonType.Standard,
          standardAction: {
            event: () => this.onChoosePriority(),
          },
        },
        this.actionButtons[3],
      );
    }
  }
  async onGenerateQuestions() {
    await this.editQuestionDialogService.showGenerateQuestionsDialog({
      generateQuestions: (cwQuestions) => this.handleGenerateQuestions(cwQuestions).then,
    });
  }
  private async handleGenerateQuestions(cwQuestions: ICWQuestion[]) {
    const priority = await this.dialogService.showQuestionPriorityDialog(true);
    const cwQuestionsIds = this.crosswordBuildForm.cwQuestionsIds.value;
    const cwQuestionIdsToAdd = cwQuestions.map((cwQuestion) => ({ index: cwQuestion.base.indexId, priority: priority }));
    this.crosswordBuildForm.cwQuestionsIds.setValue([...cwQuestionsIds, ...cwQuestionIdsToAdd]);
    this.setIdsAndLoadQuestions(false);
    this.refreshActionButtons();
    cwQuestions.forEach((cwQuestion) => this.addedNewQuestion.emit(cwQuestion.base.indexId));
  }

  private isAdministrator() {
    return this.userService.getPlayerSettings().administrator;
  }
  private setIdsAndLoadQuestions(delay: boolean, questionQuery: SearchQuestionsQuery = this.loadQuestionsQuery) {
    if (this.type == SelectCWQuestionType.Select) {
      this.loadQuestions({ ...questionQuery, notIds: IndexPriorityUtil.getIndexs(this.crosswordBuildForm.cwQuestionsIds.value) }, delay);
    } else if (this.type == SelectCWQuestionType.Selected) {
      this.loadQuestions(
        {
          ...questionQuery,
          ids: IndexPriorityUtil.getIndexsWithPriorities(this.crosswordBuildForm.cwQuestionsIds.value, questionQuery.priorities),
        },
        delay,
      );
    } else if (this.type == SelectCWQuestionType.SelectOne) {
      const indexesInCrossword = this.crosswordBuildForm.crossword.value.crosswordQuestions.map((question) => question.cwQuestion.indexId);
      this.loadQuestions({ ...questionQuery, notIds: indexesInCrossword }, delay);
    }
  }
  private setFilterQuestionIds() {
    if (this.type == SelectCWQuestionType.Select) {
      this.filterQuestionIds = { notIds: IndexPriorityUtil.getIndexs(this.crosswordBuildForm.cwQuestionsIds.value) };
    } else if (this.type == SelectCWQuestionType.Selected) {
      this.filterQuestionIds = { ids: IndexPriorityUtil.getIndexs(this.crosswordBuildForm.cwQuestionsIds.value) };
    } else if (this.type == SelectCWQuestionType.SelectOne) {
      const indexesInCrossword = this.crosswordBuildForm.crossword.value.crosswordQuestions.map((question) => question.cwQuestion.indexId);
      this.filterQuestionIds = { notIds: indexesInCrossword };
    }
  }
  private loadQuestions(questionQuery: SearchQuestionsQuery, delay = false, force = false) {
    if (!ObjectUtil.isObjectEqual(questionQuery, this.loadQuestionsQuery) || force) {
      this.loadQuestionsQuery = questionQuery;
      if (delay) {
        this.cwQuestionsModel.loadQuestionsWithDelay(this.loadQuestionsQuery);
      } else {
        this.cwQuestionsModel.loadQuestions(this.loadQuestionsQuery);
      }
    }
  }
  private reloadQuestions() {
    this.loadQuestions(this.loadQuestionsQuery, false, true);
  }
  private getBrowserLang() {
    return this.translateService.getBrowserLang();
  }

  private async choosePriorityForSelectedQuestions() {
    const priority = await this.dialogService.showQuestionPriorityDialog(false);
    if (priority == null) {
      return false;
    }
    const indexes = IndexPriorityUtil.getIndexPriorties(this.cwQuestionsSelection.selected, priority);
    this.crosswordBuildForm.cwQuestionsIds.setValue(
      IndexPriorityUtil.getIndexesWithIndexes(this.crosswordBuildForm.cwQuestionsIds.value, indexes),
    );
    return true;
  }
  private getBigScreenColumns() {
    if (this.type == SelectCWQuestionType.Selected) {
      return ['selection', 'question', 'createdBy', 'updated', 'answerLength', 'category', 'tags', 'priority', 'edit'];
    } else {
      return ['selection', 'question', 'createdBy', 'updated', 'answerLength', 'category', 'tags', 'edit'];
    }
  }
  private getSmallScreenColumns() {
    if (this.type == SelectCWQuestionType.Selected) {
      return ['selection', 'question', 'priority', 'edit'];
    } else if (this.type == SelectCWQuestionType.SelectOne) {
      return ['selection', 'question', 'answerLength', 'edit'];
    } else {
      return ['selection', 'question', 'category', 'edit'];
    }
  }
}
