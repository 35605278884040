import { Interval } from '../../../../../../Common/data/interval';
import { Direction } from '../../../../../../Common/data/position';
import {
  CrosswordTile,
  CrosswordTileEvent,
  QuestionWithCrosswordTiles,
  TileEventType,
  TilesState,
} from '../../../../../../Common/data/tile';
import { ICrossword, ICrosswordCWQuestion, ICrosswordSize } from '../../../../../../Common/model/crossword';
import { QuestionBasePriority } from '../../../../../../Common/model/cwquestion';
import { TileUtil } from '../tile.util';
import { HandleCrosswordTileUtil } from './handle.crossword.tile.util';
import { HandleCrosswordView, IHandleMatrixTileEventType, IHandleRowTileEventType, ShowCrosswordAs } from './handle.crossword.view';

class HandleTileEventType implements IHandleMatrixTileEventType, IHandleRowTileEventType {
  constructor(protected handleTileEvents: ShowHandleCrosswordView) {}
  handleRowTileEventType(tileEvent: CrosswordTileEvent, state: TilesState, tiles: CrosswordTile[]): TilesState {
    const interval = tileEvent.tile ? new Interval(0, tileEvent.tile.y, Direction.MAINAXIS, tiles.length) : null;
    return interval ? { ...state, focused: { tile: null, interval: interval, cwQuestion: tileEvent.tile.cwQuestions[0] } } : state;
  }
  handleMatrixTileEventType(tileEvent: CrosswordTileEvent, state: TilesState): TilesState {
    const [cwQuestion, interval] = this.handleTileEvents.getMatrixQuestionAndIntervalFromPosition(tileEvent.tile.x, tileEvent.tile.y);
    return interval != null ? { ...state, focused: { tile: null, interval: interval, cwQuestion: cwQuestion } } : state;
  }
}

const DefaultTileState = { focused: { tile: null, cwQuestion: null, interval: null }, canAddChars: false };

export abstract class ShowHandleCrosswordView extends HandleCrosswordView {
  protected readonly questionIntervals = new Map<QuestionBasePriority, Interval>();
  protected readonly matchedRowMatrixTiles = new Map<CrosswordTile, CrosswordTile>();
  protected visibleQuestions: ICrosswordCWQuestion[];
  protected crossword: ICrossword;
  constructor(protected showAnswers: boolean) {
    super();
    this.handleMatrixEventTypes[TileEventType.QuestionTileClicked] = new HandleTileEventType(this);
    this.handleRowEventTypes[TileEventType.ReadonlyCharTileClicked] = new HandleTileEventType(this);
    this.handleMatrixEventTypes[TileEventType.ReadonlyCharTileClicked] = new HandleTileEventType(this);
  }

  refresh(crossword: ICrossword) {
    this.crossword = crossword;
    this.visibleQuestions = this.getVisibleQuestions();
    this.matchedRowMatrixTiles.clear();
    this.refreshQuestionIntervals();
    this.matrixTiles = this.createMatrixCrosswordTiles();
    this.rowTiles = this.createRowCrosswordTiles();
    this.tilesChangedSubject.next();
    this.updateTilesState(DefaultTileState);
  }
  getCrosswordSize(): ICrosswordSize {
    return this.crossword.size;
  }

  handleRowTileEvent(tileEvent: CrosswordTileEvent, tiles: CrosswordTile[]): void {
    const handleEvent = this.handleRowEventTypes[tileEvent.event];
    if (handleEvent != null) {
      this.updateTilesState(handleEvent.handleRowTileEventType(tileEvent, this.currentTilesState.getValue(), tiles));
    } else {
      throw new Error('Unhandled event:' + tileEvent);
    }
  }
  handleMatrixTileEvent(tileEvent: CrosswordTileEvent, matrixDirection: Direction): void {
    const handleEvent = this.handleMatrixEventTypes[tileEvent.event];
    if (handleEvent != null) {
      this.updateTilesState(
        handleEvent.handleMatrixTileEventType(tileEvent, this.currentTilesState.getValue(), this.matrixTiles, matrixDirection),
      );
    } else {
      throw new Error('Unhandled event:' + tileEvent);
    }
  }

  getMatrixQuestionAndIntervalFromPosition(x: number, y: number) {
    return [...this.questionIntervals].find(([, interval]) => interval.insideInterval(x, y));
  }
  protected onShowCrosswordAsChanged(value: ShowCrosswordAs) {
    this.updateTilesState(
      value == ShowCrosswordAs.Matrix ? this.getMatrixTilesStateFromRowTilesState() : this.getRowTilesStateFromMatrixTilesState(),
    );
  }
  protected abstract getVisibleQuestions(): ICrosswordCWQuestion[];

  protected refreshQuestionIntervals() {
    this.questionIntervals.clear();
    for (const crosswordQuestion of this.visibleQuestions) {
      const position = crosswordQuestion.position;
      const question = crosswordQuestion.cwQuestion;
      this.questionIntervals.set(
        { ...question, priority: crosswordQuestion.priority },
        new Interval(position.x, position.y, position.direction, question.answer.length + 1),
      );
    }
  }
  protected createRowCrosswordTiles(): QuestionWithCrosswordTiles[] {
    const questionWithCrosswordTiles: QuestionWithCrosswordTiles[] = [];

    for (const [cwQuestion, interval] of this.questionIntervals) {
      const rowTiles: CrosswordTile[] = [];
      for (let i = 1; i < interval.length; i++) {
        const point = interval.add(i);
        const matrixTile = this.matrixTiles[point.x][point.y];
        const rowTile: CrosswordTile = {
          ...matrixTile,
          x: rowTiles.length,
          y: questionWithCrosswordTiles.length,
          cwQuestions: [cwQuestion],
        };
        rowTiles.push(rowTile);
        this.matchedRowMatrixTiles.set(rowTile, matrixTile);
      }
      questionWithCrosswordTiles.push({ cwQuestion: cwQuestion, tiles: rowTiles });
    }
    return questionWithCrosswordTiles;
  }
  protected createMatrixCrosswordTiles() {
    const tiles: CrosswordTile[][] = TileUtil.createCrosswordTiles(this.crossword.size);
    TileUtil.setQuestionAndInputTilesFromCWQuestions(tiles, this.visibleQuestions);
    TileUtil.setSetCharTilesFromQuestions(tiles, this.visibleQuestions, this.showAnswers);
    return tiles;
  }
  private getMatrixTilesStateFromRowTilesState(): TilesState {
    const tilesState = this.currentTilesState.getValue();
    if (tilesState.focused.cwQuestion != null) {
      const question = this.getQuestionFromIndexId(tilesState.focused.cwQuestion.indexId);
      const interval = this.questionIntervals.get(question);
      return {
        canAddChars: false,
        focused: { tile: null, interval: interval, cwQuestion: tilesState.focused.cwQuestion },
      };
    } else {
      return { canAddChars: false, focused: { tile: null, interval: null, cwQuestion: null } };
    }
  }
  private getRowTilesStateFromMatrixTilesState(): TilesState {
    const tilesState = this.currentTilesState.getValue();
    if (tilesState.focused.cwQuestion != null) {
      return HandleCrosswordTileUtil.getFocusedRowStateFromFocusedQuestion(tilesState, this.rowTiles);
    } else {
      return { canAddChars: false, focused: { tile: null, interval: null, cwQuestion: null } };
    }
  }
  private getQuestionFromIndexId(indexId: number) {
    return [...this.questionIntervals.keys()].find((q) => q.indexId == indexId);
  }
}
