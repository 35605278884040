@if (!loggedIn) {
  <div fxFlexFill fxLayoutAlign="center center">
    <mat-card fxFlex="0 1 500px" style="margin: 5px 0px">
      <form id="login-form" fxLayout="column" [formGroup]="loginForm" (ngSubmit)="onSubmit()" novalidate>
        <mat-card-header>
          <mat-card-title>{{ 'titles.login' | translate }}</mat-card-title>
        </mat-card-header>
        <mat-card-content>
          <div fxLayout="column" fxLayoutGap="10px">
            <mat-form-field>
              <mat-label>{{ 'labels.enter-email' | translate }}</mat-label>
              <input id="login-email" matInput formControlName="email" required autocomplete="email" />
              @if (loginForm.controls.email.hasError('required')) {
                <mat-error>{{ 'errors.email-missing' | translate }}</mat-error>
              }
              @if (loginForm.controls.email.hasError('wrongEmail')) {
                <mat-error id="wrongEmail">{{ 'errors.wrong-email' | translate }}</mat-error>
              }
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'labels.enter-password' | translate }}</mat-label>
              <input id="login-password" matInput formControlName="password" type="password" required autocomplete="current-password" />
              @if (loginForm.controls.password.hasError('required')) {
                <mat-error>{{ 'errors.password-missing' | translate }}</mat-error>
              }
              @if (loginForm.controls.password.hasError('wrongPassword')) {
                <mat-error id="wrongPassword">{{ 'errors.wrong-password' | translate }}</mat-error>
              }
            </mat-form-field>
          </div>
        </mat-card-content>
        <mat-card-actions>
          <div fxFlex fxLayout="row" fxLayoutGap="10px" fxLayoutAlign="start center" style="padding: 0px 8px">
            @if (showCreateUser) {
              <a [routerLink]="'/createUser'" [queryParams]="{ redirectUrl: this.redirectUrl }" style="color: black">{{
                'misc.create-new-user' | translate
              }}</a>
            }
            <a test-id="forgot-password" [routerLink]="'/forgotPassword'" style="color: black">{{ 'misc.forgot-password' | translate }}</a>
            <div fxFlex fxLayoutAlign="end">
              <button id="login-login" mat-raised-button [disabled]="!loginForm.valid" type="submit" color="primary">
                {{ 'buttons.login' | translate }}
              </button>
            </div>
          </div>
        </mat-card-actions>
      </form>
    </mat-card>
  </div>
}
