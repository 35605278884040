@if (updatePlayerInfo && updatePassword) {
  <div fxFlexFill fxLayout="column" fxLayoutGap="10px">
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'panel-titles.change-username' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="end" fxLayoutGap="10px">
        <form fxLayout="column" fxFlex [formGroup]="updatePlayerInfo" (ngSubmit)="onUpdatePlayerInfo()" novalidate fxLayoutGap="10px">
          <mat-form-field>
            <mat-label>{{ 'labels.enter-name' | translate }}</mat-label>
            <input matInput formControlName="name" required autocomplete="username" />
            @if (updatePlayerInfo.controls.name.hasError('required')) {
              <mat-error>
                {{ 'errors.name-missing' | translate }}
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'labels.enter-email' | translate }}</mat-label>
            <input matInput formControlName="email" required autocomplete="email" />
            @if (updatePlayerInfo.controls.email.hasError('required')) {
              <mat-error>
                {{ 'errors.email-missing' | translate }}
              </mat-error>
            }
            @if (updatePlayerInfo.controls.email.hasError('email')) {
              <mat-error>
                {{ 'errors.email-adress-invalid' | translate }}
              </mat-error>
            }
          </mat-form-field>
          <mat-checkbox matInput formControlName="onlyImportentNotifications">{{
            'checkboxes.only-importent-notifications' | translate
          }}</mat-checkbox>
          <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
            <button mat-raised-button type="submit" [disabled]="!updatePlayerInfo.valid || !updatePlayerInfo.dirty" color="primary">
              {{ 'buttons.update-user' | translate }}
            </button>
          </div>
        </form>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'panel-titles.player-settings' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <app-flexable-button-menu fxFlex [buttons]="playerSettingsButtons"> </app-flexable-button-menu>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="true">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ 'panel-titles.change-password' | translate }}</mat-panel-title>
      </mat-expansion-panel-header>
      <div fxLayout.gt-sm="row" fxLayout.lt-sm="column" fxLayoutAlign="end">
        <form fxLayout="column" fxFlex [formGroup]="updatePassword" (ngSubmit)="onUpdatePassword()" novalidate fxLayoutGap="10px">
          <mat-form-field>
            <mat-label>{{ 'labels.enter-old-password' | translate }}</mat-label>
            <input matInput formControlName="oldPassword" type="password" required autocomplete="current-password" />
            @if (updatePassword.controls.oldPassword.hasError('required')) {
              <mat-error>
                {{ 'errors.password-missing' | translate }}
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'labels.enter-new-password' | translate }}</mat-label>
            <input matInput formControlName="newPassword" type="password" required autocomplete="new-password" />
            @if (updatePassword.controls.newPassword.hasError('required')) {
              <mat-error>
                {{ 'errors.password-missing' | translate }}
              </mat-error>
            }
            @if (updatePassword.controls.newPassword.hasError('minlength')) {
              <mat-error>
                {{ 'errors.password-to-short' | translate }}
              </mat-error>
            }
          </mat-form-field>
          <mat-form-field>
            <mat-label>{{ 'labels.enter-new-password-again' | translate }}</mat-label>
            <input
              matInput
              formControlName="confirmNewPassword"
              type="password"
              [errorStateMatcher]="confirmPasswordMatcher"
              autocomplete="new-password"
            />
            @if (updatePassword.hasError('notTheSamePassword')) {
              <mat-error>
                {{ 'errors.not-the-same-password' | translate }}
              </mat-error>
            }
          </mat-form-field>
          <div fxLayout="row" fxLayoutAlign="end" fxLayoutGap="10px">
            <button mat-raised-button type="submit" [disabled]="!updatePassword.valid" color="primary">
              {{ 'buttons.update-password' | translate }}
            </button>
          </div>
        </form>
      </div>
    </mat-expansion-panel>
    @if (adminPlayer) {
      <mat-expansion-panel [expanded]="true">
        <mat-expansion-panel-header>
          <mat-panel-title>{{ 'panel-titles.admin-player-settings' | translate }}</mat-panel-title>
        </mat-expansion-panel-header>
        <app-flexable-button-menu fxFlex [buttons]="adminActionButtons"> </app-flexable-button-menu>
      </mat-expansion-panel>
    }
  </div>
}
