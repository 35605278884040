/* eslint-disable @typescript-eslint/no-explicit-any */
import { GamePlayerColor } from '../model/crossword.game';
import { ICWQuestionBase, QuestionBasePriority } from '../model/cwquestion';
import { Interval } from './interval';
import { Direction } from './position';
import { PriorityValue } from './priority.value';
export enum TileContainerType {
  Row,
  Matrix,
}
export interface SetCharTileData {
  playerMatch: GamePlayerColor;
  thisTurn: boolean;
  showCorrect?: TileShowCorrect;
}
export enum TileShowCorrect {
  Correct,
  Wrong,
}
export enum TileType {
  Empty,
  AddQuestion,
  Question,
  PriorityQuestion,
  Input,
  SetChar,
}
export enum TileEventType {
  InFocus,
  TextAdded,
  TextRemoved,
  KeyUp,
  AddQuestionTileClicked,
  QuestionTileClicked,
  ReadonlyCharTileClicked,
}

export class BorderStyle {
  constructor(public left: number, public right: number, public top: number, public bottom: number) {}

  equal(borderStyle: BorderStyle) {
    if (
      this.left !== borderStyle.left ||
      this.right !== borderStyle.right ||
      this.top !== borderStyle.top ||
      this.bottom !== borderStyle.bottom
    ) {
      return false;
    }
    return true;
  }
}
export interface CrosswordTileEvent {
  tile: CrosswordTile;
  event: TileEventType;
  data: any;
}
export interface TileCWQuestion {
  priority: PriorityValue;
  direction: Direction;
}
export interface CrosswordTile {
  cwQuestions: ICWQuestionBase[];
  type: TileType;
  tileCWQuestion?: TileCWQuestion;
  text: string;
  x: number;
  y: number;
  typeData?: any;
}
export interface QuestionWithCrosswordTiles {
  cwQuestion: QuestionBasePriority;
  tiles: CrosswordTile[];
}
export interface CrosswordTileFontData {
  fontSize: number;
  smallFontSize: number;
  maxCharsPerLine: number;
  maxLines: number;
}
export interface TilesState {
  canAddChars: boolean;
  focused: {
    tile: CrosswordTile;
    cwQuestion: ICWQuestionBase;
    interval: Interval;
  };
}
export interface CharSetOnTile {
  char: string;
  x: number;
  y: number;
}
export interface CharsSetStatisitics {
  charsSetCount: number;
  charsSetScore: number;
  maxChars: number;
}
