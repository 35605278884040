import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { ICWQuestion } from '../../../../../../Common/model/cwquestion';
import { EditQuestionComponent, EditQuestionData } from '../../build-crossword/create-edit-question/edit-question.component';
import { GenerateQuestionsComponent, GenerateQuestionsData } from '../../build-crossword/create-edit-question/generate-questions.component';

@Injectable({
  providedIn: 'root',
})
export class EditQuestionDialogService {
  constructor(private matDialog: MatDialog) {}

  async showEditQuestionDialog(editQuestionData: EditQuestionData) {
    return (await lastValueFrom(
      this.matDialog
        .open(EditQuestionComponent, {
          data: editQuestionData,
          width: `${window.innerWidth - 20}px`,
          maxWidth: `${window.innerHeight - 20}px`,
          disableClose: true,
        })
        .afterClosed(),
    )) as ICWQuestion;
  }
  async showGenerateQuestionsDialog(generateQuestionsData: GenerateQuestionsData) {
    return (await lastValueFrom(
      this.matDialog
        .open(GenerateQuestionsComponent, {
          width: `${window.innerWidth - 20}px`,
          maxWidth: `${window.innerHeight - 20}px`,
          disableClose: true,
          data: generateQuestionsData,
        })
        .afterClosed(),
    )) as ICWQuestion[];
  }
}
