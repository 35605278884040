import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PriorityValue } from '../../../../../../../Common/data/priority.value';
import { TileType } from '../../../../../../../Common/data/tile';
import { ArrowDirection } from '../content/crossword-tile.component';

@Component({
  selector: 'app-show-priority',
  templateUrl: './show-priority.component.html',
  styleUrls: ['./show-priority.component.scss'],
})
export class ShowPriorityComponent implements OnChanges {
  PriorityValue = PriorityValue;
  ArrowDirection = ArrowDirection;
  TileType = TileType;
  @Input()
  set tileSize(value: number) {
    this.triangleSize = value / 6;
    this.encapsulatedTriangleSize = value / 7;
  }
  @Input()
  questionDirection: ArrowDirection;

  @Input()
  priority: PriorityValue;

  @Input()
  tileType: TileType;

  triangleSize: number;
  encapsulatedTriangleSize: number;

  priorityColorClass: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.priority) {
      this.priorityColorClass = this.getPriorityClass();
    }
  }
  private getPriorityClass() {
    switch (this.priority) {
      case PriorityValue.Low:
        return 'triangle-priority-low';
      case PriorityValue.Medium:
        return 'triangle-priority-medium';
      case PriorityValue.High:
        return 'triangle-priority-high';
      case PriorityValue.Theme:
        return 'triangle-priority-theme';
      default:
        throw new Error('Unsupported priority class:' + this.priority);
    }
  }
}
