import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CrosswordService } from 'src/app/services/crossword/crossword.service';
import { CrosswordDifficultyEnum, CrosswordListItem } from '../../../../../Common/model/crossword';
import { ICrosswordGame } from '../../../../../Common/model/crossword.game';
import { ComponentCommunicationService } from '../../services/componentcommunication/component-communication.service';
import { CrosswordGameService } from '../../services/crosswordgame/crossword-game.service';
import { LocalizedPaginatorIntl } from '../build-crossword/select-cwquestions/localized.paginator';
import { FlexibleButtonInMenu, FlexibleButtonType } from '../common/flexable-button-menu/model/flexible.button.in.menu';
import { SelectFilterGroup, SelectFilterItem } from '../common/select-filter-items/select-filter-items.component';
import { MessageBoxType } from '../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../dialog/service/dialog.service';
import { SortColumn, SortColumnsUtil, SortOn, compare } from '../util/sort.util';
import { WaitUtil } from '../util/wait.util';

interface CrosswordListItemUI extends CrosswordListItem {
  hovered?: boolean;
  authorName: string;
  difficultyAsString: string;
}

@Component({
  selector: 'app-list-crosswords',
  templateUrl: './list-crosswords.component.html',
  styleUrls: ['./list-crosswords.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: LocalizedPaginatorIntl }],
})
export class ListCrosswordsComponent implements OnInit {
  crosswordLisItems = new MatTableDataSource<CrosswordListItemUI>();

  dispayedColumns = ['name', 'authorName', 'difficultyAsString', 'date'];
  actionButtons: FlexibleButtonInMenu[] = [];
  @ViewChild(MatPaginator) paginator: MatPaginator;
  loaded = false;
  selectFilterGroups: SelectFilterGroup<CrosswordListItem>[] = [];
  allBuiltCrosswordListItems: CrosswordListItemUI[];
  private sortUtil: SortColumnsUtil<CrosswordListItemUI>;
  private currentSort: SortOn = { columnId: 'date', direction: 'desc' };
  constructor(
    private crosswordService: CrosswordService,
    private crosswordGameService: CrosswordGameService,
    private router: Router,
    componentCommunication: ComponentCommunicationService,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {
    componentCommunication.currentRouteTitle.next(translateService.instant('route-titles.list-crosswords'));
  }
  async ngOnInit() {
    const allCrosswordListItems = await this.crosswordService.getCrosswordListItems();
    this.allBuiltCrosswordListItems = allCrosswordListItems.map((c) => this.convertToUiItem(c));
    this.crosswordLisItems.data = this.allBuiltCrosswordListItems;
    this.populateFilterGroupItems();
    this.populateSortItems();
    this.applySort();
    this.refreshActionButtons();
    WaitUtil.waitUntilSet(() => this.paginator).then(() => (this.crosswordLisItems.paginator = this.paginator));
    this.crosswordLisItems.filterPredicate = (item: CrosswordListItemUI, filter: string) => {
      return item.name.toLowerCase().search(filter.toLowerCase()) > -1 || item.authorName.toLowerCase().search(filter.toLowerCase()) > -1;
    };
    this.loaded = true;
  }
  onSortChange(sort: Sort) {
    this.currentSort.columnId = sort.active;
    this.currentSort.direction = sort.direction;
    this.applySort();
    this.paginator?.firstPage();
  }
  async applyFilter(filter: string) {
    this.crosswordLisItems.filter = filter;
  }
  private convertToUiItem(item: CrosswordListItem): CrosswordListItemUI {
    return {
      ...item,
      hovered: false,
      difficultyAsString: this.getCrosswordDifficultyAsString(item),
      authorName: item.author.name,
    };
  }
  private populateFilterGroupItems() {
    this.selectFilterGroups.push(
      new SelectFilterGroup<CrosswordListItem>(
        this.translateService.instant('options.filter-difficulty'),
        [
          new SelectFilterItem(this.translateService.instant('difficulties.easy'), CrosswordDifficultyEnum.Easy),
          new SelectFilterItem(this.translateService.instant('difficulties.medium'), CrosswordDifficultyEnum.Medium),
          new SelectFilterItem(this.translateService.instant('difficulties.hard'), CrosswordDifficultyEnum.Hard),
        ],
        (crosswords, selectedValuesSet) => crosswords.filter((c) => selectedValuesSet.has(c.difficulty)),
      ),
    );
  }
  async onCrosswordSelected(crossword: CrosswordListItemUI) {
    this.router.navigate(['/crossword', crossword.externalId]);
  }
  async onOpenNextRandomCrossword() {
    const randomCrosswordIds = await this.crosswordService.getRandomCrosswordIds();
    if (randomCrosswordIds.length > 0) {
      this.openCrosswordGame(randomCrosswordIds[0]);
    } else {
      this.dialogService.showMessageBox('', this.translateService.instant('messages.played-all-random-crosswords'), MessageBoxType.Ok);
    }
  }
  async onSelectFilteredValuesChanged(filteredCrosswordListItems: CrosswordListItemUI[]) {
    this.crosswordLisItems.data = filteredCrosswordListItems;
    this.applySort();
    this.paginator?.firstPage();
  }
  private applySort() {
    this.crosswordLisItems.data = this.sortUtil.sortItem(this.currentSort, this.crosswordLisItems.data);
  }

  private getCrosswordDifficultyAsString(crosswordListItem: CrosswordListItem) {
    switch (crosswordListItem.difficulty) {
      case CrosswordDifficultyEnum.Easy:
        return this.translateService.instant('difficulties.easy');
      case CrosswordDifficultyEnum.Medium:
        return this.translateService.instant('difficulties.medium');
      case CrosswordDifficultyEnum.Hard:
        return this.translateService.instant('difficulties.hard');
    }
  }
  private refreshActionButtons() {
    this.actionButtons = [];
    this.actionButtons.push({
      id: 'start-crossword-game',
      text: this.translateService.instant('buttons.open-next-random-multiplayer-game-crossword'),
      icon: 'shuffle',
      type: FlexibleButtonType.Standard,
      standardAction: {
        event: () => this.onOpenNextRandomCrossword(),
      },
    });
  }
  private async openCrosswordGame(id: string) {
    this.dialogService.wrapInProgress(async () => {
      const crossword = await this.crosswordService.getCrossword(id);
      const crosswordGame = (await this.crosswordGameService.createCrosswordGame(crossword)) as ICrosswordGame;
      this.router.navigate(['/playCrossword', crosswordGame.externalId]);
    }, this.translateService.instant('messages.starting-crosword-puzzle'));
  }
  private populateSortItems() {
    this.sortUtil = new SortColumnsUtil([
      new SortColumn('name', (t1, t2, isAsc) => compare(t1.name.toLowerCase(), t2.name.toLowerCase(), isAsc)),
      new SortColumn('authorName', (t1, t2, isAsc) => compare(t1.authorName.toLowerCase(), t2.authorName.toLowerCase(), isAsc)),
      new SortColumn('difficultyAsString', (t1, t2, isAsc) => compare(t1.difficulty, t2.difficulty, isAsc)),
      new SortColumn('date', (t1, t2, isAsc) => compare(t1.date, t2.date, isAsc)),
    ]);
  }
}
