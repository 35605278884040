import { PriorityValue } from '../../../../../Common/data/priority.value';
import { CrosswordDifficultyEnum } from '../../../../../Common/model/crossword';
import { CWQuestionCategory, CWQuestionVisibility } from '../../../../../Common/model/cwquestion';

export interface NumberConstant {
  label: string;
  value: number;
}

export interface QuestionPriorityConstant {
  label: string;
  value: PriorityValue;
}
export interface QuestionCategoryConstant {
  label: string;
  value: CWQuestionCategory;
}
export class CrosswordConstants {
  public static readonly QuestionPriorities: QuestionPriorityConstant[] = [
    { label: 'question-priorities.low', value: PriorityValue.Low },
    { label: 'question-priorities.medium', value: PriorityValue.Medium },
    { label: 'question-priorities.high', value: PriorityValue.High },
    { label: 'question-priorities.theme', value: PriorityValue.Theme },
  ];
  public static readonly Difficulties: NumberConstant[] = [
    { label: 'difficulties.easy', value: CrosswordDifficultyEnum.Easy },
    { label: 'difficulties.medium', value: CrosswordDifficultyEnum.Medium },
    { label: 'difficulties.hard', value: CrosswordDifficultyEnum.Hard },
  ];
  public static readonly CWQuestionVisiblities: NumberConstant[] = [
    { label: 'cwquestionvisibility.all', value: CWQuestionVisibility.All },
    { label: 'cwquestionvisibility.onlyauthor', value: CWQuestionVisibility.OnlyAuthor },
  ];
  public static readonly CWQuestionCategories: QuestionCategoryConstant[] = [
    { label: 'question-category.word-play', value: CWQuestionCategory.WordPlay },
    { label: 'question-category.language', value: CWQuestionCategory.Language },
    { label: 'question-category.synonym', value: CWQuestionCategory.Synonym },
    { label: 'question-category.geography', value: CWQuestionCategory.Geography },
    { label: 'question-category.entertainment', value: CWQuestionCategory.Entertainment },
    { label: 'question-category.history', value: CWQuestionCategory.History },
    { label: 'question-category.arts-literature', value: CWQuestionCategory.ArtsLiterature },
    { label: 'question-category.science-nature', value: CWQuestionCategory.ScienceNature },
    { label: 'question-category.sports-leisure', value: CWQuestionCategory.SportsLeisure },
  ];
  static readonly NumberOfVisibleQuestionsToStart = 40;
  static readonly MaxNumberOfThemeQuesions: number = 15;
  static readonly MaxNumberOfQuestionsPerBuild = 5000;
  public static findCWQuestionCategoryLabel(category: CWQuestionCategory) {
    return CrosswordConstants.CWQuestionCategories.find((cat) => cat.value == category).label;
  }
  public static readonly MaxNumberOfChars = 11;
  public static readonly CharsAddedPerTurn = 7;
  public static readonly MaxCharsStored = 21;
  public static readonly CaptchaCharsUnset = 5;
}
