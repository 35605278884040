import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgcCookieConsentConfig, NgcCookieConsentService, NgcStatusChangeEvent } from 'ngx-cookieconsent';
import { MessageBoxType } from '../../components/dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../components/dialog/service/dialog.service';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { BrowserDeviceType, ComponentCommunicationService } from '../componentcommunication/component-communication.service';

enum CookieConsenOptions {
  Allow = 'allow',
  Deny = 'deny',
}

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  constructor(
    private ccService: NgcCookieConsentService,
    private translateService: TranslateService,
    private dialogService: DialogService,
    private componentCommunicationService: ComponentCommunicationService,
    private appSettingsService: AppSettingsService,
  ) {}
  initialize() {
    if (this.componentCommunicationService.deviceType.getValue() != BrowserDeviceType.Browser) {
      return;
    }
    this.changeConfigForCookieConsent();
    this.ccService.statusChange$.subscribe(async (event: NgcStatusChangeEvent) => {
      if (event.status == CookieConsenOptions.Allow) {
        this.consentedCookies();
      } else if (event.status == CookieConsenOptions.Deny) {
        await this.dialogService.showMessageBox('', this.translateService.instant('messages.cookies-denied'), MessageBoxType.Ok);
        this.deniedCookies();
      }
    });

    this.handleCookieConsent();
  }

  private changeConfigForCookieConsent() {
    const appsettings = this.appSettingsService.appSettings;
    const cookieConfig: NgcCookieConsentConfig = this.ccService.getConfig();
    cookieConfig.autoAttach = true;
    cookieConfig.cookie = {
      domain: appsettings.domain,
    };
    cookieConfig.content = {
      message: this.translateService.instant('misc.cookies-question'),
      allow: this.translateService.instant('buttons.allow-cookies'),
      deny: this.translateService.instant('buttons.deny-cookies'),
      link: this.translateService.instant('misc.cookies-learn-more'),
      header: '',
      policy: this.translateService.instant('misc.cookies-policy'),
    };
    this.ccService.destroy();
    this.ccService.init(cookieConfig);
  }

  private handleCookieConsent() {
    if (this.ccService.hasAnswered() && this.ccService.hasConsented()) {
      this.consentedCookies();
    } else {
      this.deniedCookies();
      console.log('Cookies not allowed');
    }
  }

  private deniedCookies() {
    this.componentCommunicationService.allowCookies.next(false);
    this.ccService.open();
  }

  private consentedCookies() {
    this.componentCommunicationService.allowCookies.next(true);
    this.ccService.destroy();
  }
}
