<h3 mat-dialog-title style="text-align: center">{{ title | translate }}</h3>
<mat-dialog-content>
  <div fxLayout="column" style="margin-top: 5px" fxLayoutGap="10px">
    <form id="login-form" fxLayout="column" style="margin-top: 5px" fxFlex [formGroup]="codeForm" (ngSubmit)="onOkPressed()" novalidate>
      <mat-form-field>
        <mat-label>{{ 'labels.enter-user-code' | translate }}</mat-label>
        <input
          test-id="enter-code"
          type="text"
          inputmode="numeric"
          matInput
          formControlName="code"
          required
          autocomplete="number"
          [maxlength]="length"
        />
        @if (codeForm.controls.code.hasError('required')) {
          <mat-error>
            {{ 'errors.user-code-missing' | translate }}
          </mat-error>
        }
        @if (
          codeForm.controls.code.hasError('minlength') ||
          codeForm.controls.code.hasError('maxlength') ||
          codeForm.controls.code.hasError('pattern')
        ) {
          <mat-error>
            {{ 'errors.user-code-incorrect-length' | translate: { numberQuantity: length } }}
          </mat-error>
        }
      </mat-form-field>
    </form>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center">
    <button test-id="ok" (click)="onOkPressed()" [disabled]="codeForm.invalid" mat-raised-button cdkFocusInital>
      {{ 'buttons.ok' | translate }}
    </button>
    <button test-id="cancel" (click)="onCancel()" mat-raised-button>
      {{ 'buttons.cancel' | translate }}
    </button>
    @if (data.type == UserCodeType.VerifyEmail) {
      <button mat-raised-button test-id="resend-verify-code-to-user" (click)="onResendVerifyCodeToUser()">
        {{ 'buttons.resend-verify-code-to-user' | translate }}
      </button>
    }
  </div>
</mat-dialog-actions>
