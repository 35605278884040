<h3 mat-dialog-title style="text-align: center">{{ 'help-instruction.title' | translate }}</h3>
<mat-dialog-content>
  @if (data.helpInstruction == HelpInstruction.Game) {
    <div innerHTML="{{ 'help-instruction.game' | translate }}"></div>
  }
  @if (data.helpInstruction == HelpInstruction.BuildCrossword) {
    <div innerHTML="{{ 'help-instruction.build-crossword' | translate }}"></div>
  }
  @if (data.helpInstruction == HelpInstruction.AddAppToHomescreen) {
    <div innerHTML="{{ 'help-instruction.add-app-to-home-screen' | translate }}"></div>
  }
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center">
    @if (data.showDismiss) {
      <button id="dissmiss-help" mat-raised-button [mat-dialog-close]="HelpDialogClose.CloseAndDismissInstruction">
        {{ 'buttons.close-and-dismiss' | translate }}
      </button>
    }
    <button id="close-help" mat-raised-button cdkFocusInital [mat-dialog-close]="HelpDialogClose.Close">
      {{ 'buttons.close' | translate }}
    </button>
  </div>
</mat-dialog-actions>
