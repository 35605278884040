import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Direction } from '../../../../../../../Common/data/position';
import { BorderStyle, CrosswordTile, TileContainerType, TileType, TilesState } from '../../../../../../../Common/data/tile';
import { TileUtil } from '../../../util/tile.util';

class StateAndColors {
  static style = '"solid"';
  static borderColor = '"#757575"';
  static selectedBorderColor = '"#212121"';
}
@Component({
  selector: 'app-crossword-tile-border',
  templateUrl: './crossword-tile-border.component.html',
  styleUrls: ['./crossword-tile-border.component.scss'],
})
export class CrosswordTileBorderComponent implements OnChanges, OnInit {
  TileType = TileType;
  StateAndColors = StateAndColors;

  @Input()
  matrixDirection: Direction;

  @Input()
  tile: CrosswordTile;

  @Input()
  tilesState: TilesState;

  @Input()
  tileContainerType: TileContainerType;

  greyStyle = new BorderStyle(0, 0, 0, 0);
  highlightStyle = new BorderStyle(0, 0, 0, 0);
  canEdit = true;
  highLightWidth = 0.1;
  greyWidth = 0.01;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.tilesState) {
      this.onTilesStateChanged();
    }
    if (changes.matrixDirection) {
      this.onTilesStateChanged();
      this.updateGreyStyle();
    }
  }
  ngOnInit(): void {
    this.updateGreyStyle();
  }

  private onTilesStateChanged() {
    const highlightBorderStyle =
      this.tileContainerType == TileContainerType.Matrix ? this.getMatrixHighLightBorderStyle() : this.getRowHighLightBorderStyle();
    if (!this.highlightStyle.equal(highlightBorderStyle)) {
      this.highlightStyle = highlightBorderStyle;
      this.updateGreyStyle();
    }

    if (this.tile.type === TileType.Input) {
      this.canEdit = this.tilesState.canAddChars || this.tile.text.length > 0;
    }
  }
  private getRowHighLightBorderStyle() {
    return TileUtil.getRowHightlightBorderStyle(this.tile.x, this.tile.y, this.tilesState.focused.interval);
  }

  private getMatrixHighLightBorderStyle() {
    if (this.matrixDirection == Direction.MAINAXIS) {
      return TileUtil.getMatrixHightlightBorderStyleMain(this.tile.x, this.tile.y, this.tilesState.focused.interval);
    } else {
      return TileUtil.getMatrixHightlightBorderStyleCross(this.tile.x, this.tile.y, this.tilesState.focused.interval);
    }
  }

  private updateGreyStyle() {
    this.greyStyle =
      this.tileContainerType == TileContainerType.Matrix ? this.getMatrixGreyBorderStyle() : TileUtil.getRowGreyStyle(this.tile.x);
  }
  private getMatrixGreyBorderStyle() {
    if (this.matrixDirection == Direction.MAINAXIS) {
      return TileUtil.getMatrixGreyStyleMain(this.tile.x, this.tile.y);
    } else {
      return TileUtil.getMatrixGreyStyleCross(this.tile.x, this.tile.y);
    }
  }
}
