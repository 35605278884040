<mat-form-field fxFlex>
  <mat-label>{{ 'options.select-filter' | translate }}</mat-label>
  <mat-select id="select-filter-items" [formControl]="form" multiple>
    <mat-select-trigger>
      {{ form.value?.length > 0 ? form.value[0].title : '' }}
      @if (form.value?.length > 1) {
        <span class="additional-selection">{{ 'options.additional-selection' | translate: { count: form.value.length - 1 } }} </span>
      }
    </mat-select-trigger>

    @for (group of selectFilterGroups; track group) {
      <mat-optgroup>
        <div>
          <mat-checkbox [checked]="isGroupSelected(group)" (change)="toggleSelection(group)" (click)="$event.stopPropagation()">
            {{ group.title }}
          </mat-checkbox>
          <button mat-button (click)="group.expanded = !group.expanded">
            <mat-icon>{{ group.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
          </button>
        </div>
        @for (selectItem of group.selectItems; track selectItem) {
          <mat-option [value]="selectItem" class="option" [style.display]="group.expanded ? 'flex' : 'none'">
            {{ selectItem.title }}
          </mat-option>
        }
      </mat-optgroup>
    }
    <mat-option [style.display]="'none'"></mat-option>
  </mat-select>
</mat-form-field>
