import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ComponentCommunicationService } from '../../services/componentcommunication/component-communication.service';
import { UserService } from '../../services/user/user.service';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent {
  @Output()
  toggleSidenav = new EventEmitter<void>();

  constructor(
    private router: Router,
    private userService: UserService,
    public componentCommunication: ComponentCommunicationService,
  ) {}

  public async logout() {
    await this.userService.logout();
    this.router.navigate(['/login']);
  }
}
