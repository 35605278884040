import { AccuracyParameters } from '../../data/generate.crossword.data';
import { PriorityValue } from '../../data/priority.value';
import { BuildMatrix } from '../util/matrix';
import { RemoveQuestions } from '../util/remove.questions';
import { CrosswordBuildAction } from './build.action';

export class RemoveQuestionsForBetterFit implements CrosswordBuildAction {
  constructor(private readonly accuracy: AccuracyParameters, private removeQuestions: RemoveQuestions, private priority: PriorityValue) {}
  run(matrix: BuildMatrix) {
    const percentage = this.accuracy.removePercent;
    const numberOfQuestionsToRemove = Math.round(matrix.getQuestions().length * (percentage / 100));
    this.removeQuestions.remove(numberOfQuestionsToRemove, matrix, this.priority);
    return matrix;
  }
}
