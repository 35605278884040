import { Injectable } from '@angular/core';
import { GameNotificationType } from '../../../../../Common/data/crossword.game.notification';
import { PushNotification } from '../../../../../Common/data/push.notification';
import { BrowserDeviceType, ComponentCommunicationService } from '../componentcommunication/component-communication.service';
import { FireBaseNotificationService } from './firebase-push-notification.service';
import { PushNotificationInterface } from './push.notification.interface';
import { SWPushNotificationService } from './sw-push-notification.service';

export interface MessageToServiceWorker {
  command: string;
  value: unknown;
}

@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  private readonly pushNotifications: PushNotificationInterface;
  constructor(
    swPushNotificationService: SWPushNotificationService,
    firebasePushNotificationService: FireBaseNotificationService,
    componentCommunicationService: ComponentCommunicationService,
  ) {
    if (componentCommunicationService.deviceType.getValue() == BrowserDeviceType.Browser) {
      this.pushNotifications = swPushNotificationService;
    } else {
      this.pushNotifications = firebasePushNotificationService;
    }
  }
  getNotificationMessageObservable() {
    return this.pushNotifications.getNotificationMessageObservable();
  }
  async closeNotifications(gameId: string, notificationType: GameNotificationType) {
    return this.pushNotifications.closeNotifications(gameId, notificationType);
  }
  async openNotification(notification: PushNotification) {
    return this.pushNotifications.openNotification(notification);
  }
  async subscribeToNotifications() {
    return this.pushNotifications.subscribeToNotifications();
  }
  async unsubscribeToNotifications() {
    return this.pushNotifications.unsubscribeToNotifications();
  }
  public async listenForMessagesIfNotListening() {
    return this.pushNotifications.listenForMessagesIfNotListening();
  }
  public supportsPushNotification() {
    return this.pushNotifications.supportsPushNotification();
  }
  async hasSubscription() {
    return this.pushNotifications.hasSubscription();
  }
}
