@if (showToolbar) {
  <mat-toolbar id="connection-bar" color="warn" style="min-height: 38px">
    <mat-toolbar-row style="height: 38px">
      <div fxFlexFill fxLayout="row" fxLayoutAlign="center center">
        <div fxFlex fxLayoutAlign="center center">
          <span [ngStyle.lt-sm]="'font-size: small'" [ngStyle.gt-xs]="'font-size: medium'">
            {{ 'misc.no-connection-to-server' | translate }}</span
          >
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
}
