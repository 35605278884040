import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HelpInstruction } from '../../../../../../Common/model/player.settings';

export enum HelpDialogClose {
  Close,
  CloseAndDismissInstruction,
}
export interface HelpDialogData {
  helpInstruction: HelpInstruction;
  showDismiss: boolean;
}
@Component({
  selector: 'app-help-dialog-content',
  templateUrl: './help-dialog-content.component.html',
  styleUrls: ['./help-dialog-content.component.scss'],
})
export class HelpDialogContentComponent {
  HelpDialogClose = HelpDialogClose;
  HelpInstruction = HelpInstruction;
  constructor(@Inject(MAT_DIALOG_DATA) public data: HelpDialogData) {}
}
