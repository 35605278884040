import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, timer } from 'rxjs';
import { ObjectUtil } from '../../../../Common/util/object.util';
import { MessageBoxType } from '../components/dialog/message-box-content/message-box-content.component';
import { DialogService } from '../components/dialog/service/dialog.service';
import { ReportErrorService } from '../services/reporterror/report-error.service';
import { UserService } from '../services/user/user.service';

interface ErrorMessageAndStack {
  message: string;
  stack: string;
}
class PreventToShowSameErrorAgain {
  private lastError: ErrorMessageAndStack = { message: '', stack: '' };
  public isItTheSameErrorAgain(error: Error) {
    return ObjectUtil.isObjectEqual(this.lastError.stack, error.stack);
  }
  public setError(error: Error) {
    this.lastError = { message: error.message, stack: error.stack };
  }
  public async clearLastErrorAfterTimeIfStillTheSame(error: Error) {
    await lastValueFrom(timer(10000));
    if (ObjectUtil.isObjectEqual(this.lastError.stack, error.stack)) {
      this.lastError = { message: '', stack: '' };
    }
  }
}

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  private preventToShowSameErrorAgain: PreventToShowSameErrorAgain = new PreventToShowSameErrorAgain();
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private ngZone: NgZone,
    private reportErrorService: ReportErrorService,
    private userService: UserService,
    private router: Router,
  ) {}

  handleError(error: Error) {
    this.ngZone.run(() => this.handleErrorInNgZone(error));
  }

  private handleErrorInNgZone(error: Error) {
    if (!this.preventToShowSameErrorAgain.isItTheSameErrorAgain(error)) {
      this.preventToShowSameErrorAgain.setError(error);
      const rejection = (error as any).rejection;
      if (rejection instanceof HttpErrorResponse) {
        this.handleHttpErrorResponse(rejection);
      } else if (error instanceof HttpErrorResponse) {
        this.handleHttpErrorResponse(error);
      } else {
        this.showAndReportError(error, this.translateService.instant('errors.general-error-messages'));
      }
    }
  }

  private async handleHttpErrorResponse(error: HttpErrorResponse) {
    if (error.status == 401) {
      await this.handleUserLoggedOut();
    } else if (error.status >= 400 && error.status < 500) {
      this.showAndReportError(error, this.translateService.instant('errors.general-http-communication-error-message'));
    } else if (error.status >= 502 && error.status <= 504) {
      this.showErrorAsMessage(error, this.translateService.instant('errors.no-connection-to-server'));
    } else {
      this.showAndReportError(error, this.translateService.instant('errors.general-http-server-error-handler'));
    }
  }
  private async handleUserLoggedOut() {
    await this.dialogService.showMessageBox('', this.translateService.instant('messages.user-logged-out'), MessageBoxType.Ok);
    this.userService.clearLoggedInPlayer();
    this.router.navigateByUrl('/login');
  }

  private async showAndReportError(error: Error, errorMessage: string) {
    if (!this.dialogService.isMessageBoxShowing()) {
      this.dialogService.showMessageBox('', errorMessage, MessageBoxType.Error).then(() => {
        this.preventToShowSameErrorAgain.clearLastErrorAfterTimeIfStillTheSame(error);
      });
    }
    this.reportErrorService.reportError(error.message, error.stack);
    console.error('Got an error:', error);
  }
  private async showErrorAsMessage(error: Error, message: string) {
    if (!this.dialogService.isMessageBoxShowing()) {
      this.dialogService.showMessageBox('', message, MessageBoxType.Ok).then(() => {
        this.preventToShowSameErrorAgain.clearLastErrorAfterTimeIfStillTheSame(error);
      });
    }
  }
}
