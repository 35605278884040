import { PlayScoreValue } from '../data/play.score.value';
import { Point } from '../data/point';
import { Position } from '../data/position';
import { PriorityValue } from '../data/priority.value';
import { CharSetOnTile } from '../data/tile';
import { ICWQuestionBase } from '../model/cwquestion';
import { CrosswordGameChar, CrosswordGamePlayBase, CrosswordGameQuestion } from './crossword.game.play.base';
import { IdUtil } from './id.util';

export class CrosswordGamePlay extends CrosswordGamePlayBase {
  constructor() {
    super();
  }
  getCharsSetOnTiles() {
    return this.getAllCharsSet().map((charSet) => this.mapToCharSetOnTile(charSet));
  }
  getCorrectCharPoints(charSetOnTiles: CharSetOnTile[]) {
    const charSetOnTilesTmp = charSetOnTiles.filter((charSetOnTile) => this.isCharSetCorrect(charSetOnTile));
    return this.mapCharSetToPoint(charSetOnTilesTmp);
  }
  getWrongCharPoints(charSetOnTiles: CharSetOnTile[]) {
    const charSetOnTilesTmp = charSetOnTiles.filter((charSetOnTile) => !this.isCharSetCorrect(charSetOnTile));
    return this.mapCharSetToPoint(charSetOnTilesTmp);
  }
  getCorrectChar(x: number, y: number) {
    return this.crosswordGameChars[x][y].charAnswer.toLowerCase();
  }
  getScoreForCharSet(charSetOnTiles: Point[]) {
    const setOfChars = new Set<CrosswordGameChar>();
    const charsScore = this.getCharsScore(charSetOnTiles, setOfChars);
    const completeWordsScore = this.getCompleteWordsScore(setOfChars);
    return new PlayScoreValue(charsScore, completeWordsScore);
  }

  getFirstCWQuestionFromPoint(x: number, y: number, questionIndexes: number[]) {
    return this.getFirstCrosswordQuestionFromPoint(x, y, questionIndexes)?.cwQuestion;
  }

  getFirstIntervalFromQuestionPoint(x: number, y: number) {
    const gameQuestion = this.crosswordGameQuestions.find((gameQuestion) => gameQuestion.interval.x == x && gameQuestion.interval.y == y);
    return gameQuestion?.interval;
  }
  getIntervalFromQuestion(question: ICWQuestionBase) {
    if (!question) {
      return null;
    }
    const gameQuestion = this.crosswordGameQuestions.find((gameQuestion) => gameQuestion.cwQuestion.indexId == question.indexId);
    return gameQuestion?.interval;
  }
  getCrosswordGameQuestions() {
    return this.crosswordGameQuestions;
  }

  private getCharsNotSetInQuestion(crosswordGameQuestion: CrosswordGameQuestion) {
    return crosswordGameQuestion.crosswordGameChars.filter((crosswordGameChar) => crosswordGameChar.charIsSet == null);
  }

  private getFirstCrosswordQuestionFromPoint(x: number, y: number, questionIndexes: number[]) {
    const questions = this.crosswordGameQuestions.filter((q) => IdUtil.containsIndex(questionIndexes, q.cwQuestion.indexId));
    const questionsThatContainsPoint = questions.filter((q) => q.interval.insideInterval(x, y));
    return this.getClosestQuestionToPoint(questionsThatContainsPoint, new Position(x, y));
  }
  private getClosestQuestionToPoint(questions: CrosswordGameQuestion[], position: Position) {
    const orderedByClosest = questions.sort(
      (question1, question2) => question1.interval.distanceTo(position) - question2.interval.distanceTo(position)
    );
    return orderedByClosest[0];
  }
  private mapCharSetToPoint(correctCharSetOnTiles: CharSetOnTile[]) {
    return correctCharSetOnTiles.map((charSetOnTile) => {
      return { x: charSetOnTile.x, y: charSetOnTile.y } as Point;
    });
  }

  private isCharSetCorrect(charSetOnTile: CharSetOnTile): unknown {
    return this.crosswordGameChars[charSetOnTile.x][charSetOnTile.y].charAnswer.toLowerCase() === charSetOnTile.char.toLowerCase();
  }
  private mapToCharSetOnTile(charSet: CrosswordGameChar): CharSetOnTile {
    return { x: charSet.point.x, y: charSet.point.y, char: charSet.charAnswer };
  }
  private getCompleteWordsScore(setOfChars: Set<CrosswordGameChar>) {
    let score = 0;
    const allQuestions = [...setOfChars.values()].map((c) => c.crosswordQuestions).flat();
    const setOfQuestions = new Set(allQuestions);
    for (const crosswordGameQuestion of setOfQuestions) {
      const charsNotSet = this.getCharsNotSetInQuestion(crosswordGameQuestion);
      if (this.doesSetOfCharsContainAllChars(setOfChars, charsNotSet)) {
        const multiply = crosswordGameQuestion.priority == PriorityValue.Theme ? 2 : 1;
        const scoreForQuestion = crosswordGameQuestion.cwQuestion.answer.length * multiply;
        score += scoreForQuestion;
      }
    }
    return score;
  }

  private doesSetOfCharsContainAllChars(setOfChars: Set<CrosswordGameChar>, chars: CrosswordGameChar[]) {
    return chars.find((c) => !setOfChars.has(c)) == null;
  }

  private getCharsScore(charSetOnTiles: Point[], setOfChars: Set<CrosswordGameChar>) {
    let score = 0;
    for (const charSetOnTile of charSetOnTiles) {
      const crosswordChar = this.crosswordGameChars[charSetOnTile.x][charSetOnTile.y];
      setOfChars.add(crosswordChar);

      score += this.getScoreForChar(crosswordChar);
    }
    return score;
  }

  private getScoreForChar(crosswordChar: CrosswordGameChar) {
    const multiplyQuestion1 = this.getPriorityScoreForQuestion(crosswordChar.crosswordQuestions[0]);
    if (crosswordChar.crosswordQuestions.length == 2) {
      const multiplyQuestion2 = this.getPriorityScoreForQuestion(crosswordChar.crosswordQuestions[1]);
      return multiplyQuestion1 * multiplyQuestion2;
    }
    return multiplyQuestion1;
  }

  private getPriorityScoreForQuestion(question: CrosswordGameQuestion) {
    return question.priority == PriorityValue.Theme ? 2 : 1;
  }

  private getMultiplierAfterCorrectUsedChars(charsSet: number, usedNumberOfChars: number) {
    const correctPercentage = charsSet / usedNumberOfChars;
    if (correctPercentage > 0.9) {
      return 1;
    }
    if (correctPercentage > 0.8) {
      return 0.5;
    }
    if (correctPercentage > 0.7) {
      return 0.3;
    }
    return 0;
  }
}
