import { RandomizeUtil } from '../../../../../../Common/builder/util/randomize.util';
import { Interval } from '../../../../../../Common/data/interval';
import { Direction } from '../../../../../../Common/data/position';
import { CrosswordTile, CrosswordTileEvent, TileEventType, TileType } from '../../../../../../Common/data/tile';
import { ICrossword, ICrosswordSize } from '../../../../../../Common/model/crossword';
import { ICWQuestionBase } from '../../../../../../Common/model/cwquestion';
import { MatrixUtil } from '../../../../../../Common/util/matrix.util';
import { CrosswordConstants } from '../crossword.constants';
import { compare } from '../sort.util';
import { TileUtil } from '../tile.util';
import {
  MatrixInFocusChangedTileEventType,
  MatrixKeyUpTileEventType,
  QuestionClickedTileEventType,
  ReadonlyCharClickedTileEventType,
  TextAddedTileEventType,
  TextRemovedTileEventType,
} from './game.handle.event.types';
import { HandleCrosswordView, IHandleTileEventTypeModel } from './handle.crossword.view';

const DefaultTileState = { focused: { tile: null, cwQuestion: null, interval: null }, canAddChars: true };

export class GameSmallHandleCrosswordView extends HandleCrosswordView implements IHandleTileEventTypeModel {
  private readonly questionIntervals = new Map<ICWQuestionBase, Interval>();
  private crossword: ICrossword;
  private correctChars: string[][];
  private tilesToSet: CrosswordTile[];
  constructor() {
    super();
    this.handleMatrixEventTypes[TileEventType.QuestionTileClicked] = new QuestionClickedTileEventType(this);
    this.handleMatrixEventTypes[TileEventType.ReadonlyCharTileClicked] = new ReadonlyCharClickedTileEventType(this);
    this.handleMatrixEventTypes[TileEventType.InFocus] = new MatrixInFocusChangedTileEventType(this);
    this.handleMatrixEventTypes[TileEventType.TextAdded] = new TextAddedTileEventType(this);
    this.handleMatrixEventTypes[TileEventType.KeyUp] = new MatrixKeyUpTileEventType(this);
    this.handleMatrixEventTypes[TileEventType.TextRemoved] = new TextRemovedTileEventType(this);
  }

  refresh(crossword: ICrossword) {
    this.crossword = crossword;
    this.correctChars = MatrixUtil.createMatrix(crossword.size.mainAxis);
    this.refreshQuestionIntervals();
    this.matrixTiles = this.createMatrixCrosswordTiles();
    this.rowTiles = [];
    this.tilesChangedSubject.next();
    this.updateTilesState(DefaultTileState);
  }
  getCrosswordSize(): ICrosswordSize {
    return this.crossword.size;
  }

  handleRowTileEvent(): void {
    throw new Error('Crossword small can only be shown as matrix');
  }
  handleMatrixTileEvent(tileEvent: CrosswordTileEvent, matrixDirection: Direction): void {
    const handleEvent = this.handleMatrixEventTypes[tileEvent.event];
    if (handleEvent != null) {
      this.updateTilesState(
        handleEvent.handleMatrixTileEventType(tileEvent, this.currentTilesState.getValue(), this.matrixTiles, matrixDirection),
      );
    } else {
      throw new Error('Unhandled event:' + tileEvent.event);
    }
  }
  getIntervalFromQuestion(cwQuestion: ICWQuestionBase) {
    return this.questionIntervals.get(cwQuestion);
  }
  getMatrixQuestionAndIntervalFromPosition(x: number, y: number) {
    return [...this.questionIntervals].find(([, interval]) => interval.insideInterval(x, y));
  }
  getFirstCWQuestionFromPoint(x: number, y: number) {
    const questionAndInterval = this.getMatrixQuestionAndIntervalFromPosition(x, y);
    return questionAndInterval[0];
  }
  isCorrect() {
    if (!this.isAllSet()) {
      return false;
    }
    return this.tilesToSet.find((t) => !this.hasTileCorrectChar(t)) == null;
  }

  isAllSet() {
    return this.tilesToSet.find((t) => !t.text) == null;
  }
  getNumberOfCharsSet() {
    const charsNotSet = this.tilesToSet.filter((t) => !t.text).length;
    return CrosswordConstants.CaptchaCharsUnset - charsNotSet;
  }
  getNumberOfCharsToSet() {
    return CrosswordConstants.CaptchaCharsUnset;
  }
  protected onShowCrosswordAsChanged() {
    throw new Error('Show crossword state should never change!');
  }
  removeCharSetOnRowTile(): void {
    throw new Error('Can never be a row tile!');
  }
  removeCharSetOnMatrixTile(): void {
    //Do nothing
  }
  addCharSetOnRowTile(): void {
    throw new Error('Can never be a row tile!');
  }
  addCharSetOnMatrixTile(): void {
    //Do nothing
  }
  canCharsBeAdded(): boolean {
    return true;
  }
  protected refreshQuestionIntervals() {
    this.questionIntervals.clear();
    for (const crosswordQuestion of this.crossword.crosswordQuestions) {
      const position = crosswordQuestion.position;
      const question = crosswordQuestion.cwQuestion;
      const interval = new Interval(position.x, position.y, position.direction, question.answer.length + 1);
      this.questionIntervals.set(question, interval);
      this.setCorrectChars(question, interval);
    }
  }
  private setCorrectChars(question: ICWQuestionBase, interval: Interval) {
    for (let i = 1; i < question.answer.length + 1; i++) {
      const charPosition = interval.add(i);
      this.correctChars[charPosition.x][charPosition.y] = question.answer[i - 1];
    }
  }

  protected createMatrixCrosswordTiles() {
    const tiles: CrosswordTile[][] = TileUtil.createCrosswordTiles(this.crossword.size);
    TileUtil.setQuestionAndInputTilesFromCWQuestions(tiles, this.crossword.crosswordQuestions);
    const allInputTiles = tiles.flatMap((t) => t).filter((t) => t.type == TileType.Input);
    const allInputTilesRandom = RandomizeUtil.shuffle(allInputTiles);
    const allInputTilesRandomSorted = allInputTilesRandom.sort((c1, c2) => compare(c1.cwQuestions.length, c2.cwQuestions.length, false));
    const tilesToSetAsSetChar = allInputTilesRandomSorted.slice(CrosswordConstants.CaptchaCharsUnset);
    TileUtil.setTilesAsSetChar(tilesToSetAsSetChar, this.correctChars);
    this.tilesToSet = allInputTilesRandomSorted.slice(0, CrosswordConstants.CaptchaCharsUnset);
    return tiles;
  }

  private hasTileCorrectChar(t: CrosswordTile): boolean {
    const correctChar = this.correctChars[t.x][t.y];
    return t.text.toLowerCase() == correctChar.toLowerCase();
  }
}
