import { LanguageCode } from '../data/language';
import { IPosition } from '../data/position';
import { PriorityValue } from '../data/priority.value';
import { ICWQuestionBase } from './cwquestion';
import { IModel } from './model';
import { IPlayer } from './player';

export const DefaultSize: ICrosswordSize = { crossAxis: 20, mainAxis: 12 };

export enum CrosswordDifficultyEnum {
  Easy,
  Medium,
  Hard,
}
export enum CrosswordType {
  Random,
  Built,
  RandomCaptcha,
}
export interface ICrosswordCWQuestion {
  position: IPosition;
  cwQuestion: ICWQuestionBase;
  priority: PriorityValue;
}

export interface ICrosswordSize {
  mainAxis: number;
  crossAxis: number;
}

export interface CrosswordListItem {
  _id: any;
  difficulty: CrosswordDifficultyEnum;
  name: string;
  author: {
    name: string;
  };
  date: Date;
  type: CrosswordType;
  externalId: string;
}

export interface CrosswordBase extends IModel {
  languageCode: LanguageCode;
  crosswordQuestions: ICrosswordCWQuestion[];
  date: Date;
  size: ICrosswordSize;
}

export interface ICrossword extends CrosswordBase {
  difficulty: CrosswordDifficultyEnum;
  name: string;
  description: string;
  author: IPlayer;
  type: CrosswordType;
  externalId: string;
}
