import { BehaviorSubject, Observable } from 'rxjs';
import { GameState } from '../../../../../../Common/data/game.state';
import { Point } from '../../../../../../Common/data/point';
import { Direction } from '../../../../../../Common/data/position';
import {
  CharSetOnTile,
  CharsSetStatisitics,
  CrosswordTile,
  CrosswordTileEvent,
  QuestionWithCrosswordTiles,
  TileEventType,
  TileShowCorrect,
  TilesState,
} from '../../../../../../Common/data/tile';
import { ICrosswordSize } from '../../../../../../Common/model/crossword';
import { ICrosswordGame } from '../../../../../../Common/model/crossword.game';
import { ICWQuestionBase } from '../../../../../../Common/model/cwquestion';
import { IPlayer } from '../../../../../../Common/model/player';
import { CrosswordGamePlay } from '../../../../../../Common/util/crossword.game.play';
import { CrosswordGamePlayerUtil } from '../../../../../../Common/util/crossword.game.player.util';
import { IdUtil } from '../../../../../../Common/util/id.util';
import { TileUtil } from '../tile.util';
import {
  MatrixInFocusChangedTileEventType,
  MatrixKeyUpTileEventType,
  QuestionClickedTileEventType,
  ReadonlyCharClickedTileEventType,
  RowInFocusChangedTileEventType,
  RowKeyUpTileEventType,
  TextAddedTileEventType,
  TextRemovedTileEventType,
} from './game.handle.event.types';
import { HandleCrosswordTileUtil } from './handle.crossword.tile.util';
import { DefaultTileState, HandleCrosswordView, IHandleTileEventTypeModel, ShowCrosswordAs } from './handle.crossword.view';
export interface GameAddQuestionTileData {
  x: number;
  y: number;
  matrixDirection: Direction;
}
const DefaultCharStatistics = {
  charsSetScore: 0,
  charsSetCount: 0,
  maxChars: 0,
};
export class GameHandleCrosswordView extends HandleCrosswordView implements IHandleTileEventTypeModel {
  private readonly matchedRowMatrixTiles = new Map<CrosswordTile, CrosswordTile>();

  private charsSetOnTile: CharSetOnTile[] = [];
  private crosswordGame: ICrosswordGame;
  private readonly charsSetStatisticsSubject = new BehaviorSubject<CharsSetStatisitics>(DefaultCharStatistics);

  constructor(
    private readonly player: IPlayer,
    private readonly crosswordGamePlay: CrosswordGamePlay,
  ) {
    super();
    this.handleRowEventTypes[TileEventType.InFocus] = new RowInFocusChangedTileEventType();
    this.handleMatrixEventTypes[TileEventType.InFocus] = new MatrixInFocusChangedTileEventType(this);
    this.handleRowEventTypes[TileEventType.TextAdded] = this.handleMatrixEventTypes[TileEventType.TextAdded] = new TextAddedTileEventType(
      this,
    );
    this.handleRowEventTypes[TileEventType.TextRemoved] = this.handleMatrixEventTypes[TileEventType.TextRemoved] =
      new TextRemovedTileEventType(this);
    this.handleMatrixEventTypes[TileEventType.KeyUp] = new MatrixKeyUpTileEventType(this);
    this.handleRowEventTypes[TileEventType.KeyUp] = new RowKeyUpTileEventType(() => this.canCharsBeAdded());
    this.handleMatrixEventTypes[TileEventType.QuestionTileClicked] = new QuestionClickedTileEventType(this);
    this.handleRowEventTypes[TileEventType.ReadonlyCharTileClicked] = this.handleMatrixEventTypes[TileEventType.ReadonlyCharTileClicked] =
      new ReadonlyCharClickedTileEventType(this);
  }
  charSetStatisticsObservable() {
    return this.charsSetStatisticsSubject.asObservable();
  }
  getCharStatisticts() {
    return this.charsSetStatisticsSubject.getValue();
  }
  setCrosswordGame(crosswordGame: ICrosswordGame) {
    this.crosswordGame = crosswordGame;
  }
  refreshTiles(charsThatCanBeAdded: number) {
    this.charsSetOnTile.splice(0);
    this.charsSetStatisticsSubject.next({
      charsSetScore: 0,
      charsSetCount: 0,
      maxChars: charsThatCanBeAdded,
    });
    this.matrixTiles = this.createMatrixCrosswordTiles();
    this.rowTiles = this.createRowCrosswordTiles();
    this.tilesChangedSubject.next();
    this.updateTilesState({ ...DefaultTileState, canAddChars: this.canCharsBeAdded() });
  }
  refreshTileTurnOnTiles(charsThatCanBeAdded: number) {
    TileUtil.setTileTurnOnTiles(this.matrixTiles, this.crosswordGame, this.player._id);
    this.refreshTileTurnOnRowTilesFromMatrixTiles();
    this.tilesChangedSubject.next();
    this.updateCharStatistics({ ...this.getCharStatisticts(), maxChars: charsThatCanBeAdded });
    this.updateTilesState({ ...this.getCurrentTilesState() }, true);
  }

  setAsReadonlyAndShowCorrectAndWrongTiles(correctPoints: Point[], wrongPoints: Point[]) {
    const correctTiles = TileUtil.findTilesFromPoints(this.matrixTiles, correctPoints);
    const wrongTiles = TileUtil.findTilesFromPoints(this.matrixTiles, wrongPoints);
    TileUtil.setTilesAsSetCharAndFlagCorrect(this.crosswordGame, this.player._id, correctTiles, TileShowCorrect.Correct);
    TileUtil.setTilesAsSetCharAndFlagCorrect(this.crosswordGame, this.player._id, wrongTiles, TileShowCorrect.Wrong);
    TileUtil.setAllInputTilesAsReadOnlyChars(this.matrixTiles);
    this.rowTiles = this.createRowCrosswordTiles();
    this.tilesChangedSubject.next();
    this.updateTilesState({ ...DefaultTileState });
  }
  showSelectedChar() {
    const tile = this.getCurrentTilesState().focused.tile;

    if (this.showCrosswordAs.getValue() == ShowCrosswordAs.Matrix) {
      const correctChar = this.crosswordGamePlay.getCorrectChar(tile.x, tile.y).toUpperCase();
      this.removeCharSetOnMatrixTile(tile);
      this.addCharSetOnMatrixTile(tile, correctChar);
    } else {
      const matrixTile = this.matchedRowMatrixTiles.get(tile);
      const correctChar = this.crosswordGamePlay.getCorrectChar(matrixTile.x, matrixTile.y).toUpperCase();
      this.removeCharSetOnRowTile(tile);
      this.addCharSetOnRowTile(tile, correctChar);
    }
    this.updateTilesState({ ...this.getCurrentTilesState(), canAddChars: this.canCharsBeAdded() }, true);
  }
  tilesStateObservable(): Observable<TilesState> {
    return this.currentTilesState.asObservable();
  }
  clearChars() {
    TileUtil.clearMatrixInputCrosswordTiles(this.matrixTiles);
    TileUtil.clearRowInputCrosswordTiles(this.rowTiles);

    this.charsSetOnTile.splice(0);
    this.updateCharStatistics(this.getCharStatisticts());
    this.tilesChangedSubject.next();
    this.updateTilesState({ ...DefaultTileState, canAddChars: this.canCharsBeAdded() });
  }
  getCrosswordSize(): ICrosswordSize {
    return this.crosswordGame.initial.crossword.size;
  }
  handleRowTileEvent(tileEvent: CrosswordTileEvent, tiles: CrosswordTile[]): void {
    const handleEvent = this.handleRowEventTypes[tileEvent.event];
    if (handleEvent != null) {
      this.updateTilesState(handleEvent.handleRowTileEventType(tileEvent, this.currentTilesState.getValue(), tiles));
    } else {
      throw new Error('Unhandled row event:' + tileEvent);
    }
  }
  handleMatrixTileEvent(tileEvent: CrosswordTileEvent, matrixDirection: Direction): void {
    const handleEvent = this.handleMatrixEventTypes[tileEvent.event];
    if (handleEvent != null) {
      this.updateTilesState(
        handleEvent.handleMatrixTileEventType(tileEvent, this.currentTilesState.getValue(), this.matrixTiles, matrixDirection),
      );
    } else {
      throw new Error('Unhandled matrix event:' + tileEvent);
    }
  }
  getCharsSetOnTile() {
    return this.charsSetOnTile;
  }
  addCharSetOnRowTile(rowTile: CrosswordTile, char: string) {
    this.addCharSetOnMatrixTile(this.matchedRowMatrixTiles.get(rowTile), char);
  }
  removeCharSetOnRowTile(rowTile: CrosswordTile) {
    this.removeCharSetOnMatrixTile(this.matchedRowMatrixTiles.get(rowTile));
  }
  addCharSetOnMatrixTile(matrixTile: CrosswordTile, char: string) {
    if (this.canCharsBeAdded()) {
      this.setRowTilesTextFromMatrixTile(matrixTile, char);

      this.charsSetOnTile.push({ x: matrixTile.x, y: matrixTile.y, char: char });
      this.updateCharStatistics(this.getCharStatisticts());
    }
  }
  removeCharSetOnMatrixTile(matrixTile: CrosswordTile) {
    this.setRowTilesTextFromMatrixTile(matrixTile, '');

    this.charsSetOnTile = this.charsSetOnTile.filter(
      (tilesCharSetTmp) => !(tilesCharSetTmp.x === matrixTile.x && tilesCharSetTmp.y === matrixTile.y),
    );
    this.updateCharStatistics(this.getCharStatisticts());
  }

  getFirstCWQuestionFromPoint(x: number, y: number) {
    const indexes = this.getAllQuestionIndexes();
    return this.crosswordGamePlay.getFirstCWQuestionFromPoint(x, y, indexes);
  }
  getIntervalFromQuestion(cwQuestion: ICWQuestionBase) {
    return this.crosswordGamePlay.getIntervalFromQuestion(cwQuestion);
  }
  updateCharStatistics(charsLeft: CharsSetStatisitics) {
    this.charsSetStatisticsSubject.next({
      ...charsLeft,
      charsSetScore: this.crosswordGamePlay.getScoreForCharSet(this.charsSetOnTile).getTotalScore(),
      charsSetCount: this.charsSetOnTile.length,
    });
  }
  canCharsBeAdded() {
    const charsLeft = this.charsSetStatisticsSubject.getValue();
    return (
      this.crosswordGame.global.state == GameState.Started &&
      this.isPlayerCurrentPlayer() &&
      this.charsSetOnTile.length < charsLeft.maxChars
    );
  }
  protected onShowCrosswordAsChanged(value: ShowCrosswordAs) {
    this.updateTilesState(
      value == ShowCrosswordAs.Matrix ? this.getMatrixTilesStateFromRowTilesState() : this.getRowTilesStateFromMatrixTilesState(),
    );
  }
  private setRowTilesTextFromMatrixTile(matrixTile: CrosswordTile, text: string) {
    const rowTiles = HandleCrosswordTileUtil.getRowTilesFromMatrixTile(matrixTile, this.matchedRowMatrixTiles);
    rowTiles.forEach((rowTile) => (rowTile.text = text));
    matrixTile.text = text;
  }
  private isPlayerCurrentPlayer() {
    return CrosswordGamePlayerUtil.isCurrentPlayer(this.crosswordGame, this.player);
  }
  private createRowCrosswordTiles() {
    this.matchedRowMatrixTiles.clear();
    const questionWithCrosswordTiles: QuestionWithCrosswordTiles[] = [];

    const crosswordGameQuestionsNotCompleted = this.crosswordGamePlay.getCrosswordGameQuestionsNotCompleted();
    const viewingIndex = this.getAllQuestionIndexes();
    const showGameQuestions = crosswordGameQuestionsNotCompleted.filter((q) => IdUtil.containsIndex(viewingIndex, q.cwQuestion.indexId));
    for (const gameQuestion of showGameQuestions) {
      const rowTiles: CrosswordTile[] = [];
      for (const gameChar of gameQuestion.crosswordGameChars) {
        const matrixTile: CrosswordTile = this.matrixTiles[gameChar.point.x][gameChar.point.y];
        const rowTile: CrosswordTile = {
          ...matrixTile,
          x: rowTiles.length,
          y: questionWithCrosswordTiles.length,
          cwQuestions: [gameQuestion.cwQuestion],
        };
        rowTiles.push(rowTile);
        this.matchedRowMatrixTiles.set(rowTile, matrixTile);
      }
      questionWithCrosswordTiles.push({ cwQuestion: { ...gameQuestion.cwQuestion, priority: gameQuestion.priority }, tiles: rowTiles });
    }

    return questionWithCrosswordTiles;
  }
  private createMatrixCrosswordTiles() {
    const crossword = this.crosswordGame.initial.crossword;
    const tiles: CrosswordTile[][] = TileUtil.createCrosswordTiles(crossword.size);

    TileUtil.setQuestionAndInputTilesFromCWQuestions(tiles, crossword.crosswordQuestions);
    TileUtil.setSetCharOnTiles(tiles, this.crosswordGamePlay.getCharsSetOnTiles());
    TileUtil.setTileTurnOnTiles(tiles, this.crosswordGame, this.player._id);
    if (!this.isPlayerCurrentPlayer() || this.crosswordGame.global.state != GameState.Started) {
      TileUtil.setAllInputTilesAsReadOnlyChars(tiles);
    }
    if (this.crosswordGame.global.state == GameState.Ended) {
      TileUtil.setSetCharTilesFromQuestions(tiles, crossword.crosswordQuestions, true);
    }
    return tiles;
  }
  private getMatrixTilesStateFromRowTilesState(): TilesState {
    const tilesState: TilesState = this.currentTilesState.getValue();
    const focusedTile = this.matchedRowMatrixTiles.get(tilesState.focused.tile);
    if (focusedTile != null) {
      const interval = this.crosswordGamePlay.getIntervalFromQuestion(tilesState.focused.cwQuestion);
      return { ...tilesState, focused: { tile: focusedTile, interval: interval, cwQuestion: tilesState.focused.cwQuestion } };
    } else if (tilesState.focused.cwQuestion != null) {
      const interval = this.crosswordGamePlay.getIntervalFromQuestion(tilesState.focused.cwQuestion);
      return { ...tilesState, focused: { tile: null, interval: interval, cwQuestion: tilesState.focused.cwQuestion } };
    } else {
      return { ...tilesState, focused: { tile: null, interval: null, cwQuestion: null } };
    }
  }
  private getRowTilesStateFromMatrixTilesState(): TilesState {
    const tilesState: TilesState = this.currentTilesState.getValue();
    const focusedTiles = new Set<CrosswordTile>(
      HandleCrosswordTileUtil.getRowTilesFromMatrixTile(tilesState.focused.tile, this.matchedRowMatrixTiles),
    );
    if (focusedTiles.size > 0) {
      return HandleCrosswordTileUtil.getFocusedRowTilesStateFromState(tilesState, focusedTiles, this.rowTiles);
    } else if (tilesState.focused.cwQuestion != null) {
      return HandleCrosswordTileUtil.getFocusedRowStateFromFocusedQuestion(tilesState, this.rowTiles);
    } else {
      return { ...tilesState, focused: { tile: null, interval: null, cwQuestion: null } };
    }
  }
  private refreshTileTurnOnRowTilesFromMatrixTiles() {
    const allRowTiles = this.rowTiles.flatMap((rt) => rt.tiles);
    for (const rowTile of allRowTiles) {
      const matrixTile = this.matchedRowMatrixTiles.get(rowTile);
      TileUtil.setTileTurnFromTile(matrixTile, rowTile);
    }
  }
  private getAllQuestionIndexes() {
    return this.crosswordGame.initial.crossword.crosswordQuestions.map((c) => c.cwQuestion.indexId);
  }
}
