<h3 mat-dialog-title style="text-align: center">{{ 'help-instruction.title' | translate }}</h3>
<mat-dialog-content>
  <div
    test-id="invite-to-game-text"
    [innerHTML]="'misc.invite-to-game' | translate: { gameCode: data.crosswordGame.initial.joinCode }"
  ></div>
</mat-dialog-content>
<mat-dialog-actions>
  @if (initiated) {
    <div fxFlex fxLayout="row" fxLayoutAlign="end center">
      @if (supportsDeviceShare) {
        <button id="share-button" mat-raised-button (click)="onShareGame()">
          <mat-icon>share</mat-icon>{{ 'buttons.share-invitation' | translate }}
        </button>
      }
      @if (!supportsDeviceShare) {
        <a id="share-button" mat-raised-button [href]="getEmailLink()">
          <mat-icon>share</mat-icon>{{ 'buttons.share-invitation' | translate }}
        </a>
      }
      <button id="close-button" mat-raised-button [mat-dialog-close]="false">
        {{ 'buttons.close' | translate }}
      </button>
    </div>
  }
</mat-dialog-actions>
