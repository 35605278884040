<div listenForResize (sizeChanged)="onSizeChanged($event)" fxFlexFill class="flex-row">
  @for (tile of tiles; track tile) {
    <app-crossword-tile-border
      [tile]="tile"
      [tilesState]="tilesState"
      [tileContainerType]="TileContainerType.Row"
      [ngStyle]="{
        'width.px': tileSize,
        'height.px': tileSize,
      }"
    >
      <app-crossword-tile
        [tile]="tile"
        [tileSize]="tileSize"
        [tilesState]="tilesState"
        [tileContainerType]="TileContainerType.Row"
        (tileEvent)="onTileEvent($event)"
      ></app-crossword-tile
    ></app-crossword-tile-border>
  }
</div>
