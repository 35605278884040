import { ReQuestionPositionData } from '../models/re.question.position.data';
import { BuildQuestion, OpenInterval } from './matrix';
import { PositionAndQuestion } from './position.and.question';

export class MatchPositionAndQuestion implements PositionAndQuestion {
  constructor() {}
  public getPositionAndQuestion(questions: BuildQuestion[], intervals: OpenInterval[]) {
    let highestPositionData: ReQuestionPositionData | null = null;
    for (const question of questions) {
      const positionForQuestion = this.getPositionForQuestion(question, intervals);
      if (positionForQuestion) {
        if (highestPositionData == null || positionForQuestion.points > highestPositionData.points) {
          highestPositionData = positionForQuestion;
        }
      }
    }
    return highestPositionData;
  }
  public getPositionForQuestion(question: BuildQuestion, intervals: OpenInterval[]) {
    let highestPositionData: ReQuestionPositionData | null = null;
    for (const interval of intervals) {
      if (interval.canAddQuestion(question)) {
        const questionPositionData = this.getQuestionPositionData(question, interval);
        if (highestPositionData == null || questionPositionData.points > highestPositionData.points) {
          highestPositionData = questionPositionData;
        }
      }
    }
    return highestPositionData;
  }
  private getQuestionPositionData(question: BuildQuestion, interval: OpenInterval): ReQuestionPositionData {
    const crossings = interval.getCrossings(question);
    return {
      question,
      position: interval.interval,
      points: question.score.perCrossing * crossings + question.answer.length * question.score.perChar,
    };
  }
}
