<h3 mat-dialog-title style="text-align: center">
  {{ 'titles.select-player-color' | translate }}
</h3>
<mat-dialog-content style="overflow-y: hidden">
  <div fxFlexFill fxLayout="column" fxLayoutGap="20px" style="padding-bottom: 10px">
    @if (playersPrimaryColorIsTaken) {
      <mat-label>{{ 'misc.players-primary-color-is-taken' | translate }}</mat-label>
    }
    <mat-radio-group
      [(ngModel)]="selectedColor"
      aria-label="misc.select-player-color"
      fxLayout="row"
      fxLayoutGap="20px"
      fxLayoutAlign="center"
      style="padding-top: 5px"
    >
      @for (colorColumn of colors; track colorColumn) {
        <div fxLayout="column" fxLayoutGap="10px">
          @for (color of colorColumn; track color) {
            <mat-radio-button [id]="color.id" [value]="color" [ngStyle]="{ color: color.color }"
              ><mat-icon class="icon-display" [ngStyle]="{ color: color.color }"> circle </mat-icon></mat-radio-button
            >
          }
        </div>
      }
    </mat-radio-group>
    @if (!playersPrimaryColorIsTaken) {
      <mat-checkbox id="remember-color" [(ngModel)]="rememberDecision">{{
        'misc.remember-player-color-decision' | translate
      }}</mat-checkbox>
    }
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center">
    <button
      id="select-color-ok"
      color="primary"
      [disabled]="!selectedColor"
      (click)="onCloseDialogWithValue()"
      mat-raised-button
      cdkFocusInital
    >
      {{ 'buttons.ok' | translate }}
    </button>
    @if (data.allowCancel) {
      <button id="select-color-cancel" color="primary" (click)="onCancelDialog()" mat-raised-button cdkFocusInital>
        {{ 'buttons.cancel' | translate }}
      </button>
    }
  </div>
</mat-dialog-actions>
