import { KrysskampenConstants } from '../../../../../../Common/const/krysskampen.constants';
import { CrosswordUtil } from '../../../../../../Common/util/crossword.util';
import { ShowHandleCrosswordView } from './show.handle.crossword.view';

export class CrosswordLandingHandleCrosswordView extends ShowHandleCrosswordView {
  constructor() {
    super(false);
  }
  protected getVisibleQuestions() {
    return CrosswordUtil.getQuestionsSortedAfterDistanceToCenter(this.crossword.crosswordQuestions).slice(
      0,
      KrysskampenConstants.NumberOfVisibleQuestionsFromStart,
    );
  }
}
