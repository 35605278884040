import { GenerateCrosswordData } from '../data/generate.crossword.data';
import { PriorityValue } from '../data/priority.value';
import { ICrossword } from '../model/crossword';
import { ArrayUtil } from '../util/array.util';
import { AddQuestions } from './modifiers/add.questions';
import { CrosswordBuildAction } from './modifiers/build.action';
import { RemoveQuestionsForBetterFit } from './modifiers/remove.questions.for.better.fit';
import { RemoveQuestionsWithToLittleCrossings } from './modifiers/remove.questions.with.to.little.crossings';
import { RepositionQuestionsToBetterPositions } from './modifiers/reposition.questions.to.better.positions';
import { AddQuestionsToPosition } from './util/add.questions.to.position';
import { MatchPositionAndQuestion } from './util/match.position.for.question';
import { BuildMatrix } from './util/matrix';
import { QuestionsContainer } from './util/questions.container';
import { RemoveQuestions } from './util/remove.questions';

export class BuildCrosswordMatrix {
  private matrixes: BuildMatrix[] = [];
  private readonly storedCrosswordsCount = 50;
  constructor(private settings: GenerateCrosswordData) {
    this.initMatrixes();
  }
  static build(settings: GenerateCrosswordData) {
    return new BuildCrosswordMatrix(settings).buildCrossword();
  }
  private buildCrossword() {
    this.buildCrosswordMatrix();
    this.sortMatrixAfterScore();
    const crossword = this.matrixes[0].getCrossword();
    this.logStatistics(crossword);
    return crossword;
  }

  private sortMatrixAfterScore() {
    this.matrixes = this.matrixes.sort((c1, c2) => c1.getScore().compare(c2.getScore())).reverse();
  }

  private buildCrosswordMatrix() {
    for (const priority of [PriorityValue.Theme, PriorityValue.High, PriorityValue.Medium, PriorityValue.Low]) {
      const buildActions = this.getBuildActionsForPriority(priority);
      this.buildWithActions(this.settings.accuracy.randomQResets, buildActions);
    }
  }
  private buildWithActions(iterations: number, actions: CrosswordBuildAction[]) {
    for (let i = 0; i < iterations; i++) {
      let matrix = ArrayUtil.getRandom(this.matrixes).copy();

      for (const action of actions) {
        matrix = action.run(matrix, i);
      }

      this.addToMatrixesIfBetter(matrix);
    }
  }

  private getBuildActionsForPriority(priority: PriorityValue) {
    const buildActions: CrosswordBuildAction[] = [];
    const removeQuestions = new RemoveQuestions();
    const questionsContainer = new QuestionsContainer(this.settings.questions);
    const matchPositionAndQuestion = new MatchPositionAndQuestion();
    const addQuestionsToPosition = new AddQuestionsToPosition(this.settings, matchPositionAndQuestion, questionsContainer);

    buildActions.push(new RemoveQuestionsWithToLittleCrossings(this.settings.accuracy, priority));
    buildActions.push(new RemoveQuestionsForBetterFit(this.settings.accuracy, removeQuestions, priority));
    buildActions.push(new AddQuestions(addQuestionsToPosition, priority));
    buildActions.push(new RepositionQuestionsToBetterPositions(this.settings, matchPositionAndQuestion, removeQuestions, priority));
    return buildActions;
  }

  private logStatistics(crossword: ICrossword) {
    const questionsContainer = new QuestionsContainer(this.settings.questions);
    console.log(
      'Built crosswords with score:',
      this.matrixes.map((c) => c.getScore().withPriority)
    );
    let text = 'Questions with priority ';
    text += this.getTextOfPriority('theme', questionsContainer, crossword, PriorityValue.Theme);
    text += this.getTextOfPriority('high', questionsContainer, crossword, PriorityValue.High);
    text += this.getTextOfPriority('medium', questionsContainer, crossword, PriorityValue.Medium);
    text += this.getTextOfPriority('low', questionsContainer, crossword, PriorityValue.Low);

    console.log(text);
  }
  private getTextOfPriority(text: string, questionsContainer: QuestionsContainer, crossword: ICrossword, priorityValue: PriorityValue) {
    const crosswordQuestionOfPriority = crossword.crosswordQuestions.filter((c) => c.priority == priorityValue);
    const allQuestionsOfPriority = questionsContainer.getQuestionsOfPriority(priorityValue);
    return `${text}:${crosswordQuestionOfPriority.length}(${allQuestionsOfPriority.length}), `;
  }
  private initMatrixes() {
    this.matrixes.length = 0;
    this.matrixes.push(new BuildMatrix(this.settings.size));
  }

  private addToMatrixesIfBetter(matrixToCheck: BuildMatrix) {
    this.sortMatrixAfterScore();
    if (this.checkMatrixInList(matrixToCheck)) {
      return;
    }
    const matrixScore = matrixToCheck.getScore();
    for (let i = 0; i < this.matrixes.length; i++) {
      const matrix = this.matrixes[i];
      if (matrixScore.compare(matrix.getScore()) > 0) {
        this.matrixes.splice(i, 0, matrixToCheck);
        break;
      }
    }
    this.matrixes = this.matrixes.slice(0, this.storedCrosswordsCount);
  }
  private checkMatrixInList(matrix: BuildMatrix) {
    return this.matrixes.some((m) => m.getScore().compare(matrix.getScore()) == 0);
  }
}
