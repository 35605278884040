<div fxFlexFill fxLayoutAlign="center">
  @if (crosswordGame != null) {
    <mat-table fxFlexFill #table matSort matSortActive="order" matSortDirection="asc" [dataSource]="dataSource">
      <ng-container matColumnDef="currentPlayer">
        <mat-header-cell *matHeaderCellDef ngClass="w-30"></mat-header-cell>
        <mat-cell *matCellDef="let playerInfo" ngClass="w-30">
          @if (isCurrentPlayer(playerInfo)) {
            <mat-icon> person </mat-icon>
          }
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="order">
        <mat-header-cell *matHeaderCellDef mat-sort-header ngClass="w-60">{{ 'columns.order' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let playerInfo" ngClass="w-60"> {{ playerInfo.order }}</mat-cell>
      </ng-container>
      <ng-container matColumnDef="playerName">
        <mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'columns.name' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let playerInfo">{{ playerInfo.playerName }} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="score">
        <mat-header-cell *matHeaderCellDef mat-sort-header ngClass="w-70">{{ 'columns.score' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let playerInfo" ngClass="w-70">
          {{ 'columns-value.score' | translate: { score: playerInfo.score, lastScore: playerInfo.lastScore } }}</mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="percentageCorrect">
        <mat-header-cell *matHeaderCellDef mat-sort-header ngClass="w-60">{{ 'columns.percentage-correct' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let playerInfo" ngClass="w-60">
          {{ 'columns-value.percentage-correct' | translate: { percentageCorrect: playerInfo.percentageCorrect } }}</mat-cell
        >
      </ng-container>
      <ng-container matColumnDef="color">
        <mat-header-cell *matHeaderCellDef ngClass="w-70">{{ 'columns.color' | translate }}</mat-header-cell>
        <mat-cell *matCellDef="let playerInfo" ngClass="w-70">
          <mat-icon [ngStyle]="{ color: playerInfo.color }"> circle </mat-icon>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let playerInfo; columns: displayedColumns"> </mat-row>
    </mat-table>
  }
</div>
