@if (priority == PriorityValue.Theme) {
  <div
    [ngStyle]="{
      'border-top.px': 0,
      'border-right.px': 0,
      'border-bottom.px': triangleSize,
      'border-left.px': triangleSize,
    }"
    class="triangle-top-left"
  ></div>
  @if (questionDirection == ArrowDirection.Horizontal) {
    <div
      [ngStyle]="{
        'border-top.px': triangleSize,
        'border-right.px': 0,
        'border-bottom.px': 0,
        'border-left.px': triangleSize,
      }"
      class="triangle-bottom-left"
    ></div>
  }
  @if (questionDirection == ArrowDirection.Vertical) {
    <div
      [ngStyle]="{
        'border-top.px': 0,
        'border-right.px': triangleSize,
        'border-bottom.px': triangleSize,
        'border-left.px': 0,
      }"
      class="triangle-top-right"
    ></div>
  }
}
@if (tileType == TileType.PriorityQuestion) {
  <div
    class="background-priority-triangle"
    [ngStyle]="{
      'border-top.px': triangleSize,
      'border-right.px': triangleSize,
      'border-bottom.px': 0,
      'border-left.px': 0,
    }"
  ></div>
  <div
    [ngClass]="priorityColorClass"
    [ngStyle]="{
      'border-top.px': encapsulatedTriangleSize,
      'border-right.px': encapsulatedTriangleSize,
      'border-bottom.px': 0,
      'border-left.px': 0,
    }"
  ></div>
}
