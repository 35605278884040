<div class="stretch-max flex-column disable-text-selection" style="cursor: pointer; position: relative; justify-content: center">
  @if (question.type != CWQuestionType.Image) {
    <span [ngStyle]="{ 'font-size.px': textCompressed.fontSize }"> {{ textCompressed.line1 }}</span>
    @if (textCompressed.line2) {
      <span [ngStyle]="{ 'font-size.px': textCompressed.fontSize, 'margin-top.px': textCompressed.gapBetweenLines }">{{
        textCompressed.line2
      }}</span>
    }
  }
  @if (question.type == CWQuestionType.Image) {
    <div class="flex-row" style="justify-content: center">
      <mat-icon [ngStyle]="{ 'height.px': imageSize, 'width.px': imageSize, 'font-size.px': imageSize }">image</mat-icon>
    </div>
    <span [ngStyle]="{ 'font-size.px': textCompressed.fontSize }"> {{ textCompressed.line1 }}</span>
  }
</div>
