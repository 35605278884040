<mat-form-field fxFlex>
  <mat-label>{{ 'options.select-filter' | translate }}</mat-label>
  <mat-select id="select-filter-questions" [formControl]="form" multiple>
    <mat-select-trigger>
      {{ form.value?.length > 0 ? form.value[0].title : '' }}
      @if (form.value?.length > 1) {
        <span class="additional-selection">{{ 'options.additional-selection' | translate: { count: form.value.length - 1 } }} </span>
      }
    </mat-select-trigger>

    @for (group of groups; track group) {
      <mat-optgroup [id]="group.id">
        <div>
          <mat-checkbox [checked]="isGroupSelected(group)" (change)="toggleSelection(group)" (click)="$event.stopPropagation()">
            {{ group.title }}
          </mat-checkbox>
          <button [id]="group.id + '-expand'" mat-button (click)="group.expanded = !group.expanded">
            <mat-icon>{{ group.expanded ? 'keyboard_arrow_down' : 'keyboard_arrow_right' }}</mat-icon>
          </button>
        </div>
        @if (group.expanded) {
          @for (value of group.values; track value) {
            <mat-option [id]="value.id" [value]="value" class="option">
              {{ value.title }}
            </mat-option>
          }
        }
      </mat-optgroup>
    }
    <mat-option [style.display]="'none'"></mat-option>
  </mat-select>
</mat-form-field>
