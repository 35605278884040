<div #container [ngStyle]="{ 'left.px': positionAndSize.x, 'top.px': positionAndSize.y }" style="position: absolute; z-index: 100">
  <div
    class="image-container"
    [ngStyle]="{
      'width.px': positionAndSize.width,
      'maxWidth.px': positionAndSize.width,
      'height.px': positionAndSize.height,
      'maxHeight.px': positionAndSize.height,
    }"
    fxLayout="column"
    fxLayoutGap="5px"
    fxLayoutAlign="center center"
  >
    <span>{{ question.question }}</span>
    @if (!imageLoaded && !imageFailedToLoad) {
      <mat-progress-spinner mode="indeterminate" class="progress-spinner"></mat-progress-spinner>
    }
    @if (!imageFailedToLoad) {
      <div fxFlex fxLayout="column" style="overflow: auto">
        <img
          fxFlexFill
          [src]="question.imageId | imageUrl"
          [alt]="'Image url:' + question.imageId | imageUrl"
          (load)="onImageLoaded()"
          (error)="onImageFailedToLoad()"
          (click)="onShowLargeImage()"
          style="cursor: pointer"
          [ngClass]="{ showing: imageLoaded, hidden: !imageLoaded }"
        />
      </div>
    } @else {
      <div fxLayout="row" fxLayoutAlign="center center">
        <mat-icon>error</mat-icon>
        <span>{{ 'errors.missing-image' | translate }}</span>
      </div>
    }
  </div>
</div>
