import { PriorityValue } from '../../data/priority.value';
import { AddQuestionsToPosition } from '../util/add.questions.to.position';
import { BuildMatrix } from '../util/matrix';
import { CrosswordBuildAction } from './build.action';

export class AddQuestions implements CrosswordBuildAction {
  constructor(private readonly addQuestionsToPosition: AddQuestionsToPosition, private readonly addQuestionsFrom: PriorityValue) {}
  run(matrix: BuildMatrix) {
    return this.addQuestionsToPosition.addQuestionsToFreePositions(matrix, this.addQuestionsFrom);
  }
}
