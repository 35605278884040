export function compare(a: number | string | boolean | Date, b: number | string | boolean | Date, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

export interface SortOn {
  columnId: string;
  direction: string;
}
export class SortColumn<T> {
  constructor(
    public columnId: string,
    public sort: (t1: T, t2: T, isAsc: boolean) => number,
  ) {}
}

export class SortColumnsUtil<T> {
  constructor(private sortItems: SortColumn<T>[]) {}
  sortItem(sortOn: SortOn, items: T[]) {
    const itemsTmp = items.slice();
    const sortItem = this.sortItems.find((s) => s.columnId == sortOn.columnId);
    itemsTmp.sort((a, b) => sortItem.sort(a, b, sortOn.direction == 'asc'));
    return itemsTmp;
  }
}
