import { Injectable } from '@angular/core';
import { SwPush } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, Subject, take, timeout } from 'rxjs';
import { GameNotificationType } from '../../../../../Common/data/crossword.game.notification';
import { PushNotification } from '../../../../../Common/data/push.notification';
import { IWebSubscription } from '../../../../../Common/model/subscription';
import { AssertUtil } from '../../../../../Common/util/assert.util';
import { RetryUtil } from '../../../../../Common/util/retry.util';
import { MessageBoxType } from '../../components/dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../components/dialog/service/dialog.service';
import { BrowserUtil } from '../../util/browser.util';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ReportErrorService } from '../reporterror/report-error.service';
import { UserService } from '../user/user.service';
import { PushNotificationInterface } from './push.notification.interface';

@Injectable({
  providedIn: 'root',
})
export class SWPushNotificationService implements PushNotificationInterface {
  private notificationMessages = new Subject<unknown>();
  private listening = false;
  constructor(
    private swPush: SwPush,
    private userService: UserService,
    private dialogService: DialogService,
    private translateService: TranslateService,
    private reportErrorService: ReportErrorService,
    private appsettingsService: AppSettingsService,
  ) {}

  async openNotification(notification: PushNotification) {
    if (await this.hasSubscription()) {
      const message = {
        command: 'openNotification',
        value: notification,
      };
      navigator.serviceWorker.controller.postMessage(message);
    }
  }

  getNotificationMessageObservable() {
    return this.notificationMessages.asObservable();
  }
  async closeNotifications(gameId: string, notificationType: GameNotificationType) {
    if (await this.hasSubscription()) {
      const message = {
        command: 'closeNotifications',
        value: { gameId: gameId, notificationType: notificationType },
      };
      navigator.serviceWorker.controller.postMessage(message);
    }
  }
  async subscribeToNotifications() {
    try {
      await this.dialogService.wrapInProgress(async () => {
        const pushSubscription = await this.requestNewSubscription();

        if (!pushSubscription) {
          await this.dialogService.showMessageBox(
            '',
            this.translateService.instant('messages.notifiction-not-approved'),
            MessageBoxType.Ok,
          );
          return false;
        }
        await this.listenForMessages(pushSubscription);
      }, this.translateService.instant('messages.requesting-new-subscription'));
      this.dialogService.showSnackbar(this.translateService.instant('messages.listening-for-notifications'), 3000);
      return true;
    } catch (error) {
      this.handleErrorWhenSubscribingToNotification(error);
      return false;
    }
  }
  public async listenForMessagesIfNotListening() {
    if (!this.listening) {
      const subscription = await this.getCurrentSubscription();
      AssertUtil.assert(() => subscription != null);
      await this.listenForMessages(subscription);
    }
  }
  private async listenForMessages(subscription: PushSubscription) {
    await this.checkAndSetSubscriptionToPlayer(subscription);

    this.swPush.messages.subscribe((message: unknown) => {
      this.notificationMessages.next(message);
    });
    this.listening = true;
  }

  public supportsPushNotification() {
    if (!this.swPush.isEnabled) {
      return false;
    }
    if (!BrowserUtil.supportsPushNotification()) {
      return false;
    }
    if (!this.appsettingsService.appSettings.vapid.publicKey) {
      return false;
    }
    return true;
  }
  async hasSubscription() {
    if (!this.swPush.isEnabled) {
      return false;
    }
    const currentSubscription = await this.getCurrentSubscription();
    if (currentSubscription == null) {
      return false;
    }
    const playerSettings = this.userService.getPlayerSettings();
    const subscription: IWebSubscription = this.getSubscriptionFromPushSubscription(currentSubscription);
    return playerSettings.webSubscription?.endpoint == subscription.endpoint;
  }
  async unsubscribeToNotifications() {
    await this.swPush.unsubscribe();
  }
  private handleErrorWhenSubscribingToNotification(error: Error) {
    const domException: DOMException = error as DOMException;
    if (domException?.name == 'NotAllowedError') {
      this.dialogService.showMessageBox('', this.translateService.instant('messages.notifiction-not-approved'), MessageBoxType.Ok);
    } else {
      if (error != null) {
        console.error('Handle error when subscribing to notification', error, domException);
        this.reportErrorService.reportError('Failed to subscribe to subscription', JSON.stringify(error));
      }
      this.dialogService.showSnackbar(this.translateService.instant('errors.failed-not-subscribe-to-notification'), 2000);
    }
  }
  private async getCurrentSubscription() {
    try {
      return await lastValueFrom(this.swPush.subscription.pipe(take(1)).pipe(timeout(10000)));
    } catch (error) {
      console.log('Failed to get subscription with error', error);
    }
    return null;
  }
  private async requestNewSubscription() {
    return RetryUtil.retry(
      () =>
        this.swPush.requestSubscription({
          serverPublicKey: this.appsettingsService.appSettings.vapid.publicKey,
        }),
      (subscription) => subscription != null,
      2,
    );
  }

  private async checkAndSetSubscriptionToPlayer(pushSubscription: PushSubscription) {
    const playerSettings = this.userService.getPlayerSettings();
    const subscription: IWebSubscription = this.getSubscriptionFromPushSubscription(pushSubscription);
    if (playerSettings.webSubscription?.endpoint != subscription.endpoint) {
      playerSettings.webSubscription = subscription;
      await this.userService.savePlayerSettings();
    }
  }
  private getSubscriptionFromPushSubscription(subscription: PushSubscription): IWebSubscription {
    return JSON.parse(JSON.stringify(subscription.toJSON()));
  }
}
