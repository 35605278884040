import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ICWQuestionBase } from '../../../../../../../Common/model/cwquestion';

export interface ShowLargeImageData {
  question: ICWQuestionBase;
}

@Component({
  selector: 'app-show-large-image',
  templateUrl: './show-large-image.component.html',
  styleUrls: ['show-large-image.component.scss'],
})
export class ShowLargeImageComponent {
  imageLoaded = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ShowLargeImageData,
    private matDialogRef: MatDialogRef<ShowLargeImageComponent>,
  ) {}

  onImageLoaded() {
    this.imageLoaded = true;
  }

  onClose() {
    this.matDialogRef.close();
  }
}
