<div style="padding-bottom: 5px" class="flex-row flex-align-start-center">
  @if (cwQuestion.imageId) {
    <div class="image-container" (click)="onShowLargeImage()" style="margin-right: 5px">
      @if (!imageLoaded && !imageFailedToLoad) {
        <mat-progress-spinner mode="indeterminate" class="progress-spinner"></mat-progress-spinner>
      }
      @if (!imageFailedToLoad) {
        <img
          [src]="cwQuestion.imageId | imageUrl"
          [alt]="'Image url:' + cwQuestion.imageId | imageUrl"
          (load)="onImageLoaded()"
          (error)="onImageFailedToLoad()"
          style="cursor: pointer"
          [ngClass]="{ showing: imageLoaded, hidden: !imageLoaded }"
        />
      } @else {
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon>error</mat-icon>
          <span>{{ 'errors.missing-image' | translate }}</span>
        </div>
      }
    </div>
  }
  <span>{{ cwQuestion.question }}</span>
  @if (cwQuestion.priority == PriorityValue.Theme) {
    <span style="font-weight: bold; margin-left: 5px">{{ 'labels.show-crossword-theme-question' | translate }}</span>
  }
</div>
