@if (chatMessages) {
  <div fxLayout="column" fxFlexFill fxLayoutGap="10px">
    <div id="list-chat-messages" #chatTableContainer class="chat-table-container">
      <mat-table [dataSource]="chatMessages">
        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef style="display: none">{{ 'columns.name' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let chatMessage"> {{ chatMessage.player.name }} skrev:</mat-cell>
        </ng-container>
        <ng-container matColumnDef="message">
          <mat-header-cell *matHeaderCellDef style="display: none">{{ 'columns.message' | translate }}</mat-header-cell>
          <mat-cell *matCellDef="let chatMessage"> {{ chatMessage.message }} </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let chatMessage; columns: displayedColumns"> </mat-row>
      </mat-table>
    </div>
    <div>
      <mat-form-field fxFlex>
        <mat-label>{{ 'labels.enter-message' | translate }}</mat-label>
        <textarea
          id="enter-chat-message"
          cdkTextareaAutosize
          #chatMessageInput
          matInput
          (keyup.enter)="onSendChatMessages(chatMessageInput.value); chatMessageInput.value = ''"
        ></textarea>
        <button
          id="send-chat-message"
          mat-icon-button
          matSuffix
          (click)="onSendChatMessages(chatMessageInput.value); chatMessageInput.value = ''"
        >
          <mat-icon>send</mat-icon>
        </button>
      </mat-form-field>
    </div>
  </div>
}
