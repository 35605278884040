import { Interval } from '../../../../../../Common/data/interval';
import { Point } from '../../../../../../Common/data/point';
import { Direction, Position } from '../../../../../../Common/data/position';
import { CrosswordTile, CrosswordTileEvent, TileType, TilesState } from '../../../../../../Common/data/tile';
import { ICWQuestionBase } from '../../../../../../Common/model/cwquestion';
import { TileKeyUp } from '../../../services/tile-key/tile-key.service';
import { TileUtil } from '../tile.util';
import { IHandleMatrixTileEventType, IHandleRowTileEventType, IHandleTileEventTypeModel } from './handle.crossword.view';

export class QuestionClickedTileEventType implements IHandleMatrixTileEventType {
  constructor(private handleTileEvents: IHandleTileEventTypeModel) {}
  handleMatrixTileEventType(tileEvent: CrosswordTileEvent, state: TilesState, tiles: CrosswordTile[][]): TilesState {
    const cwQuestion: ICWQuestionBase = tileEvent.data;
    const interval = this.handleTileEvents.getIntervalFromQuestion(cwQuestion);
    const position = new Position().parse(interval);

    const tile = TileUtil.getTileFromPoint(position, tiles);
    return interval ? { ...state, focused: { tile: tile, interval: interval, cwQuestion: cwQuestion } } : state;
  }
}
export class ReadonlyCharClickedTileEventType implements IHandleMatrixTileEventType, IHandleRowTileEventType {
  constructor(private handleTileEvents: IHandleTileEventTypeModel) {}
  handleRowTileEventType(tileEvent: CrosswordTileEvent, state: TilesState, tiles: CrosswordTile[]): TilesState {
    return {
      ...state,
      focused: {
        tile: null,
        cwQuestion: tileEvent.tile?.cwQuestions[0],
        interval: new Interval(0, tileEvent.tile.y, Direction.MAINAXIS, tiles.length),
      },
    };
  }
  handleMatrixTileEventType(tileEvent: CrosswordTileEvent, state: TilesState): TilesState {
    const cwQuestion = this.handleTileEvents.getFirstCWQuestionFromPoint(tileEvent.tile.x, tileEvent.tile.y);
    const interval = this.handleTileEvents.getIntervalFromQuestion(cwQuestion);

    return interval != null ? { ...state, focused: { tile: null, cwQuestion: cwQuestion, interval: interval } } : state;
  }
}
export class RowInFocusChangedTileEventType implements IHandleRowTileEventType {
  handleRowTileEventType(tileEvent: CrosswordTileEvent, state: TilesState, tiles: CrosswordTile[]): TilesState {
    return {
      ...state,
      focused: {
        tile: tileEvent.tile,
        cwQuestion: tileEvent.tile.cwQuestions[0],
        interval: new Interval(0, tileEvent.tile.y, Direction.MAINAXIS, tiles.length),
      },
    };
  }
}
export class MatrixInFocusChangedTileEventType implements IHandleMatrixTileEventType {
  constructor(private handleTileEvents: IHandleTileEventTypeModel) {}

  handleMatrixTileEventType(tileEvent: CrosswordTileEvent, state: TilesState): TilesState {
    if (state.focused.interval && state.focused.interval.insideInterval(tileEvent.tile.x, tileEvent.tile.y)) {
      return { ...state, focused: { ...state.focused, tile: tileEvent.tile } };
    } else {
      const cwQuestion = this.handleTileEvents.getFirstCWQuestionFromPoint(tileEvent.tile.x, tileEvent.tile.y);
      const interval = this.handleTileEvents.getIntervalFromQuestion(cwQuestion);

      return interval != null ? { ...state, focused: { tile: tileEvent.tile, cwQuestion: cwQuestion, interval: interval } } : state;
    }
  }
}
export class TextAddedTileEventType implements IHandleMatrixTileEventType, IHandleRowTileEventType {
  constructor(private handleTileEvents: IHandleTileEventTypeModel) {}
  handleRowTileEventType(tileEvent: CrosswordTileEvent, state: TilesState): TilesState {
    this.handleTileEvents.removeCharSetOnRowTile(tileEvent.tile);
    this.handleTileEvents.addCharSetOnRowTile(tileEvent.tile, tileEvent.data);
    return { ...state, canAddChars: this.handleTileEvents.canCharsBeAdded() };
  }
  handleMatrixTileEventType(tileEvent: CrosswordTileEvent, state: TilesState): TilesState {
    this.handleTileEvents.removeCharSetOnMatrixTile(tileEvent.tile);
    this.handleTileEvents.addCharSetOnMatrixTile(tileEvent.tile, tileEvent.data);
    return { ...state, canAddChars: this.handleTileEvents.canCharsBeAdded() };
  }
}
export class TextRemovedTileEventType implements IHandleMatrixTileEventType, IHandleRowTileEventType {
  constructor(private handleTileEvents: IHandleTileEventTypeModel) {}
  handleRowTileEventType(tileEvent: CrosswordTileEvent, state: TilesState): TilesState {
    this.handleTileEvents.removeCharSetOnRowTile(tileEvent.tile);
    return { ...state, canAddChars: this.handleTileEvents.canCharsBeAdded() };
  }
  handleMatrixTileEventType(tileEvent: CrosswordTileEvent, state: TilesState): TilesState {
    this.handleTileEvents.removeCharSetOnMatrixTile(tileEvent.tile);
    return { ...state, canAddChars: this.handleTileEvents.canCharsBeAdded() };
  }
}
export class RowKeyUpTileEventType implements IHandleRowTileEventType {
  constructor(private canCharsBeAdded: () => boolean) {}
  handleRowTileEventType(tileEvent: CrosswordTileEvent, state: TilesState, tiles: CrosswordTile[]): TilesState {
    const tileKeyUp = tileEvent.data as TileKeyUp;
    if (tileKeyUp === TileKeyUp.Character) {
      return this.canCharsBeAdded() ? this.getNewRowCharacterPosition(tileEvent, state, tiles, 1) : state;
    } else if (tileKeyUp === TileKeyUp.Backspace) {
      return this.getNewRowCharacterPosition(tileEvent, state, tiles, -1);
    } else {
      return this.getNewRowArrowPosition(tileEvent, state, tiles);
    }
  }
  private getNewRowArrowPosition(tileEvent: CrosswordTileEvent, state: TilesState, tiles: CrosswordTile[]): TilesState {
    const point = this.getMovedRowArrowPosition(tileEvent);
    const tile = tiles[point.x];
    if (tile?.type === TileType.Input) {
      return { ...state, focused: { ...state.focused, tile: tile } };
    }
    return state;
  }
  private getMovedRowArrowPosition(tileEvent: CrosswordTileEvent): Point {
    const tileKeyUp = tileEvent.data as TileKeyUp;
    switch (tileKeyUp) {
      case TileKeyUp.Up:
        return { x: tileEvent.tile.x, y: tileEvent.tile.y };
      case TileKeyUp.Down:
        return { x: tileEvent.tile.x, y: tileEvent.tile.y };
      case TileKeyUp.Left:
        return { x: tileEvent.tile.x - 1, y: tileEvent.tile.y };
      case TileKeyUp.Right:
        return { x: tileEvent.tile.x + 1, y: tileEvent.tile.y };
      default:
        throw new Error('Unhandled tileKeyUp' + tileKeyUp);
    }
  }
  private getNewRowCharacterPosition(tileEvent: CrosswordTileEvent, state: TilesState, tiles: CrosswordTile[], add: number): TilesState {
    let position = new Position(tileEvent.tile.x, tileEvent.tile.y, state.focused.interval.direction).add(add);
    const interval = state.focused.interval;
    for (; interval.insideInterval(position.x, position.y); position = position.add(add)) {
      const tile = tiles[position.x];
      if (tile.type === TileType.Input) {
        return { ...state, focused: { ...state.focused, tile: tile } };
      }
    }
    return state;
  }
}
export class MatrixKeyUpTileEventType implements IHandleMatrixTileEventType {
  constructor(private handleTileEvents: IHandleTileEventTypeModel) {}

  handleMatrixTileEventType(
    tileEvent: CrosswordTileEvent,
    state: TilesState,
    tiles: CrosswordTile[][],
    matrixDirection: Direction,
  ): TilesState {
    const tileKeyUp = tileEvent.data as TileKeyUp;
    if (tileKeyUp === TileKeyUp.Character) {
      return this.handleTileEvents.canCharsBeAdded() ? this.getNewMatrixCharacterPosition(tileEvent, state, tiles, 1) : state;
    } else if (tileKeyUp === TileKeyUp.Backspace) {
      return this.getNewMatrixCharacterPosition(tileEvent, state, tiles, -1);
    } else {
      return this.getNewMatrixArrowPosition(tileEvent, state, tiles, matrixDirection);
    }
  }

  private getNewMatrixArrowPosition(
    tileEvent: CrosswordTileEvent,
    state: TilesState,
    tiles: CrosswordTile[][],
    matrixDirection: Direction,
  ): TilesState {
    const point =
      matrixDirection == Direction.MAINAXIS
        ? this.getMovedMatrixArrowPositionMain(tileEvent)
        : this.getMovedMatrixArrowPositionCross(tileEvent);
    const tile = TileUtil.getTileFromPoint(point, tiles);
    if (tile?.type === TileType.Input) {
      if (state.focused.interval.insideInterval(point.x, point.y)) {
        return { ...state, focused: { ...state.focused, tile: tile } };
      } else {
        const question = this.handleTileEvents.getFirstCWQuestionFromPoint(point.x, point.y);
        const interval = this.handleTileEvents.getIntervalFromQuestion(question);

        return { ...state, focused: { tile: tile, cwQuestion: question, interval: interval } };
      }
    }
    return state;
  }

  private getMovedMatrixArrowPositionMain(tileEvent: CrosswordTileEvent): Point {
    const tileKeyUp = tileEvent.data as TileKeyUp;
    switch (tileKeyUp) {
      case TileKeyUp.Up:
        return { x: tileEvent.tile.x, y: tileEvent.tile.y - 1 };
      case TileKeyUp.Down:
        return { x: tileEvent.tile.x, y: tileEvent.tile.y + 1 };
      case TileKeyUp.Left:
        return { x: tileEvent.tile.x - 1, y: tileEvent.tile.y };
      case TileKeyUp.Right:
        return { x: tileEvent.tile.x + 1, y: tileEvent.tile.y };
      default:
        throw new Error('Unhandled tileKeyUp' + tileKeyUp);
    }
  }
  private getMovedMatrixArrowPositionCross(tileEvent: CrosswordTileEvent): Point {
    const tileKeyUp = tileEvent.data as TileKeyUp;
    switch (tileKeyUp) {
      case TileKeyUp.Up:
        return { x: tileEvent.tile.x - 1, y: tileEvent.tile.y };
      case TileKeyUp.Down:
        return { x: tileEvent.tile.x + 1, y: tileEvent.tile.y };
      case TileKeyUp.Left:
        return { x: tileEvent.tile.x, y: tileEvent.tile.y - 1 };
      case TileKeyUp.Right:
        return { x: tileEvent.tile.x, y: tileEvent.tile.y + 1 };
      default:
        throw new Error('Unhandled tileKeyUp' + tileKeyUp);
    }
  }

  private getNewMatrixCharacterPosition(
    tileEvent: CrosswordTileEvent,
    state: TilesState,
    tiles: CrosswordTile[][],
    add: number,
  ): TilesState {
    let position = new Position(tileEvent.tile.x, tileEvent.tile.y, state.focused.interval.direction).add(add);
    const interval = state.focused.interval;
    for (; interval.insideInterval(position.x, position.y); position = position.add(add)) {
      const tile = TileUtil.getTileFromPoint(position, tiles);
      if (tile.type === TileType.Input) {
        return { ...state, focused: { ...state.focused, tile: tile } };
      }
    }
    return state;
  }
}
