import { AfterContentInit, Component, Inject } from '@angular/core';
import { FormControl, FormGroup, UntypedFormControl, ValidationErrors, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import {
  CWQuestionCategory,
  CWQuestionDifficultyEnum,
  CWQuestionType,
  CWQuestionVisibility,
  ICWQuestion,
} from '../../../../../../Common/model/cwquestion';
import { FilterCWQuestion } from '../../../../../../Common/util/cwquestion.util';
import { CWQuestionService } from '../../../services/cwquestion/cwquestion.service';
import { UserService } from '../../../services/user/user.service';
import { MessageBoxType } from '../../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../dialog/service/dialog.service';
import { CrosswordConstants } from '../../util/crossword.constants';
import { EditQuestionTiles } from './edit-question-tiles/edit-question-tiles.component';
import { ImageResultType } from './search-images/search-images.component';

export interface EditQuestionData {
  cwQuestion: ICWQuestion;
  filter?: FilterCWQuestion;
}

export interface QuestionFormData {
  question: FormControl<string>;
  answer: FormControl<string>;
  category: FormControl<CWQuestionCategory>;
  difficulty: FormControl<CWQuestionDifficultyEnum>;
  visibility: FormControl<CWQuestionVisibility>;
  tags: FormControl<string[]>;
  tagInput: FormControl<string>;
  type: FormControl<CWQuestionType>;
  imageUrl: FormControl<string>;
  imageId: FormControl<string>;
}

@Component({
  selector: 'app-edit-question',
  templateUrl: './edit-question.component.html',
  styleUrls: ['./edit-question.component.scss'],
})
export class EditQuestionComponent implements AfterContentInit {
  Difficulties = CrosswordConstants.Difficulties;
  CWQuestionVisiblities = CrosswordConstants.CWQuestionVisiblities;
  CWQuestionCategories = CrosswordConstants.CWQuestionCategories;
  cwQuestionForm: FormGroup<QuestionFormData>;
  readonly editQuestionTiles: EditQuestionTiles;

  constructor(
    @Inject(MAT_DIALOG_DATA) public editQuestionData: EditQuestionData,
    private matDialogRef: MatDialogRef<EditQuestionComponent>,
    private translateService: TranslateService,
    private cwQuestionService: CWQuestionService,
    private dialogService: DialogService,
    private userService: UserService,
  ) {
    this.editQuestionTiles = { question: editQuestionData.cwQuestion.base, filter: editQuestionData.filter };
  }
  ngAfterContentInit(): void {
    this.createForm();
  }

  private createForm() {
    const cwQuestion = this.editQuestionData.cwQuestion;
    this.cwQuestionForm = new FormGroup<QuestionFormData>({
      question: new FormControl(cwQuestion.base.question, [Validators.required, Validators.maxLength(50)]),
      answer: new FormControl(cwQuestion.base.answer, [
        Validators.required,
        Validators.pattern(this.translateService.instant('validators.supported-chars')),
        (control: UntypedFormControl) => this.validateAnswerInput(control),
      ]),
      category: new FormControl(cwQuestion.category, [Validators.required]),
      difficulty: new FormControl(cwQuestion.difficulty, [Validators.required]),
      visibility: new FormControl(cwQuestion.visibility, [Validators.required]),
      tags: new FormControl(cwQuestion.tags),
      tagInput: new FormControl<string>(''),
      type: new FormControl<CWQuestionType>(cwQuestion.base.type),
      imageUrl: new FormControl<string>(cwQuestion.imageUrl),
      imageId: new FormControl<string>(cwQuestion.base.imageId),
    });
  }

  async onAddOrEditImage() {
    const value = this.cwQuestionForm.value;
    const result = await this.dialogService.showSearchForImages(value.imageId != null, value.answer);
    if (result.type == ImageResultType.Ok && result.imageUrl != this.cwQuestionForm.value.imageUrl) {
      this.cwQuestionForm.controls.type.setValue(CWQuestionType.Image);
      this.cwQuestionForm.controls.imageUrl.setValue(result.imageUrl);
      this.cwQuestionForm.controls.imageId.setValue(null);
    }
    if (result.type == ImageResultType.Remove) {
      this.cwQuestionForm.controls.type.setValue(CWQuestionType.Text);
      this.cwQuestionForm.controls.imageUrl.setValue('');
      this.cwQuestionForm.controls.imageId.setValue(null);
    }
  }

  async onSaveQuestion() {
    const cwQuestion = this.editQuestionData.cwQuestion;
    const value = this.cwQuestionForm.value;

    const cwQuestionTmp: ICWQuestion = {
      ...cwQuestion,
      base: { ...cwQuestion.base, question: value.question, answer: value.answer, type: value.type, imageId: value.imageId },
      difficulty: value.difficulty,
      visibility: value.visibility,
      category: value.category,
      tags: this.getTagsToSave(),
      imageUrl: value.imageUrl,
    };
    if (await this.cwQuestionService.doesQuestionAndAnswerExist(cwQuestionTmp.base)) {
      this.dialogService.showMessageBox(
        '',
        this.translateService.instant('errors.question-and-answer-already-exists'),
        MessageBoxType.Error,
      );
      return;
    }
    const savedQuestion = await this.saveQuestion(cwQuestionTmp);
    this.matDialogRef.close(savedQuestion);
  }
  onCancel() {
    this.matDialogRef.close(null);
  }
  public validateAnswerInput(control: UntypedFormControl): ValidationErrors | null {
    const answer: string = control.value;
    if (!this.editQuestionData.filter) {
      return null;
    }
    const filter = this.editQuestionData.filter;
    if (!filter.lengths.find((length) => answer.length == length)) {
      return { notOneOfFilterLengths: true };
    }
    return null;
  }
  private async saveQuestion(cwQuestionTmp: ICWQuestion) {
    if (cwQuestionTmp._id) {
      return await this.cwQuestionService.updateCWQuestion(cwQuestionTmp);
    } else {
      const createCWQuestion: ICWQuestion = { ...cwQuestionTmp, author: this.userService.getLoggedIn() };
      return await this.cwQuestionService.createCWQuestion(createCWQuestion);
    }
  }
  private getTagsToSave(): string[] {
    const value = this.cwQuestionForm.value;
    return value.tagInput ? [...value.tags, value.tagInput] : value.tags;
  }
}
