import { Component, Input, OnChanges } from '@angular/core';
import { Direction, Position, Rectangle } from '../../../../../../../Common/data/position';
import { ICrosswordSize } from '../../../../../../../Common/model/crossword';
import { CWQuestionType, ICWQuestionBase } from '../../../../../../../Common/model/cwquestion';
import { DialogService } from '../../../dialog/service/dialog.service';
import { ShowAllImageUtil } from './show-all-image.util';

@Component({
  selector: 'app-show-all-image',
  templateUrl: './show-all-image.component.html',
  styleUrls: ['show-all-image.component.scss'],
})
export class ShowAllImageComponent implements OnChanges {
  CWQuestionType = CWQuestionType;
  @Input()
  questionPosition: Position;
  @Input()
  question: ICWQuestionBase;
  @Input()
  size: number;
  @Input()
  showCrosswordWidth: number;
  @Input()
  matrixDirection: Direction;
  @Input()
  crosswordSize: ICrosswordSize;
  positionAndSize: Rectangle;
  imageLoaded = false;
  imageFailedToLoad = false;

  private readonly wantedImageSize = 200;

  private readonly heightAfterImageLoadFailure = 100;

  constructor(private dialogService: DialogService) {}

  ngOnChanges(changes: any): void {
    this.calculatePosition();
    if (changes.question) {
      this.imageLoaded = false;
      this.imageFailedToLoad = false;
    }
  }
  onImageLoaded() {
    this.imageLoaded = true;
  }
  onImageFailedToLoad() {
    this.imageFailedToLoad = true;
    this.positionAndSize.height = this.heightAfterImageLoadFailure;
  }
  async onShowLargeImage() {
    await this.dialogService.showLargeImage(this.question);
  }
  private calculatePosition() {
    const showAllImageUtil = new ShowAllImageUtil(
      this.crosswordSize,
      this.size,
      this.matrixDirection,
      this.questionPosition,
      this.question.question,
      this.wantedImageSize,
      this.wantedImageSize + 35,
      1,
    );
    this.positionAndSize = showAllImageUtil.getImageRectangle();
    if (this.imageFailedToLoad) {
      this.positionAndSize.height = this.heightAfterImageLoadFailure;
    }
  }
}
