@if (data.title) {
  <h3 mat-dialog-title style="text-align: center">{{ data.title }}</h3>
}
<mat-dialog-content fxLayout="column" fxLayoutGap="10px">
  @if (data.type == MessageBoxType.Error) {
    <mat-icon color="warn">warning</mat-icon>
  }
  <div innerHTML="{{ data.message }}"></div>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center">
    @if (data.type == MessageBoxType.YesNo) {
      <button id="yes-button" mat-raised-button cdkFocusInital [mat-dialog-close]="true">{{ 'buttons.yes' | translate }}</button>
      <button id="no-button" mat-raised-button [mat-dialog-close]="false">{{ 'buttons.no' | translate }}</button>
    }
    @if (data.type == MessageBoxType.Ok) {
      <button id="ok-button" mat-raised-button cdkFocusInital [mat-dialog-close]="true">{{ 'buttons.ok' | translate }}</button>
    }
    @if (data.type == MessageBoxType.OkCancel) {
      <button id="ok-button" mat-raised-button cdkFocusInital [mat-dialog-close]="true">{{ 'buttons.ok' | translate }}</button>
      @if (data.type == MessageBoxType.OkCancel) {
        <button id="cancel-button" [mat-dialog-close]="false">
          {{ 'buttons.cancel' | translate }}
        </button>
      }
    }
    @if (data.type == MessageBoxType.Error) {
      <button id="ok-button" mat-raised-button cdkFocusInital color="warn" [mat-dialog-close]="true">{{ 'buttons.ok' | translate }}</button>
    }
  </div>
</mat-dialog-actions>
