import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { PriorityValue } from '../../../../../../Common/data/priority.value';
import { CrosswordConstants, QuestionPriorityConstant } from '../../util/crossword.constants';
import { PriorityMatchColor } from '../../util/priority.match.color';

@Component({
  selector: 'app-question-priority',
  templateUrl: './question-priority.component.html',
})
export class QuestionPriorityComponent implements OnInit {
  readonly form: UntypedFormGroup;
  QuestionPriorities = CrosswordConstants.QuestionPriorities;
  currentPrioritySelected = new BehaviorSubject<QuestionPriorityConstant>(this.QuestionPriorities[0]);

  @Output()
  prioritySelected = new EventEmitter<PriorityValue>();
  constructor() {
    this.currentPrioritySelected.subscribe((questionPriority) => this.prioritySelected.emit(questionPriority.value));
  }
  ngOnInit(): void {
    this.currentPrioritySelected.next(this.QuestionPriorities[0]);
  }

  getIconColor(constant: QuestionPriorityConstant) {
    return PriorityMatchColor.getColor(constant.value);
  }
}
