import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { urlJoin } from 'url-join-ts';
import { LogoutResult } from '../../../../../Common/data/logout.result';
import { IPlayer, IPlayerWithSettings } from '../../../../../Common/model/player';
import { PlayerSettings } from '../../../../../Common/model/player.settings';
import {
  CreateVerifyUser,
  ForgotPassword,
  ForgotPasswordSetNew,
  IChangePasswordUser,
  ResendVerifyCodeToUser,
  VerifyCreatedUser,
  VerifyForgotPasswordCode,
} from '../../../../../Common/model/user';
import { AssertUtil } from '../../../../../Common/util/assert.util';
import { AppSettingsService } from '../appsettings/app-settings.service';
import { ComponentCommunicationService } from '../componentcommunication/component-communication.service';
import { ConfigService } from '../config/config.service';
import { HttpClientCacheService } from '../http-client-cache/http-client-cache.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private playerSettings: PlayerSettings;
  private loggedInPlayer: IPlayer = null;
  private initialized = new BehaviorSubject<boolean>(false);

  constructor(
    private configService: ConfigService,
    private httpClient: HttpClient,
    private httpClientCache: HttpClientCacheService,
    private appSettingsService: AppSettingsService,
    private componentCommunicationService: ComponentCommunicationService,
  ) {}
  async initialize() {
    const loggedInPlayerOnServer = await this.getLoggedInOnServer();
    if (loggedInPlayerOnServer != null) {
      this.playerSettings = loggedInPlayerOnServer.settings;
      this.loggedInPlayer = loggedInPlayerOnServer;
      this.componentCommunicationService.playerLoggedIn.next(true);
    }
    this.initialized.next(true);
    this.initialized.complete();
  }
  async waitUntilInitialized() {
    if (!this.initialized.getValue()) {
      await lastValueFrom(this.initialized);
    }
  }
  async getLoggedInOnServer() {
    const routes = this.configService.getRoutes();

    return await lastValueFrom(
      this.httpClient.get<IPlayerWithSettings>(this.appSettingsService.appSettings.apiurl + routes.authentication),
    );
  }
  async savePlayerSettings() {
    const routes = this.configService.getRoutes();
    this.playerSettings = await lastValueFrom(
      this.httpClient.post<PlayerSettings>(`${this.appSettingsService.appSettings.apiurl}${routes.player}/settings`, this.playerSettings),
    );
  }
  async updateUserPassword(changePassword: IChangePasswordUser) {
    const routes = this.configService.getRoutes();
    this.loggedInPlayer = await lastValueFrom(
      this.httpClient.post<IPlayer>(`${this.appSettingsService.appSettings.apiurl}${routes.user}/changePassword`, changePassword),
    );
  }
  async updatePlayer(player: IPlayer) {
    const routes = this.configService.getRoutes();
    this.loggedInPlayer = await lastValueFrom(
      this.httpClient.post<IPlayer>(`${this.appSettingsService.appSettings.apiurl}${routes.player}/`, player),
    );
  }
  async login(email: string, password: string) {
    const routes = this.configService.getRoutes();
    const playerWithSettings = await lastValueFrom(
      this.httpClient.post<IPlayerWithSettings>(this.appSettingsService.appSettings.apiurl + routes.authentication, {
        email,
        password,
      }),
    );
    this.playerSettings = playerWithSettings.settings;
    this.loggedInPlayer = playerWithSettings;
    this.componentCommunicationService.playerLoggedIn.next(true);
    return this.getLoggedIn();
  }
  async logout() {
    const routes = this.configService.getRoutes();
    const result = await lastValueFrom(
      this.httpClient.post<LogoutResult>(this.appSettingsService.appSettings.apiurl + routes.authentication + '/logout', {}),
    );

    this.clearLoggedInPlayer();
    return result;
  }
  clearLoggedInPlayer() {
    this.loggedInPlayer = null;
    this.playerSettings = null;
    this.componentCommunicationService.playerLoggedIn.next(false);
  }
  async createVerifyUser(createVerifyUser: CreateVerifyUser) {
    const createUserResult = await lastValueFrom(
      this.httpClient.post<IPlayer>(urlJoin(this.getUrl(), 'createVerifyUser'), createVerifyUser),
    );
    return createUserResult;
  }
  async hasVerifiedCreatedUser() {
    const result = await lastValueFrom(this.httpClient.get<boolean>(urlJoin(this.getUrl(), 'hasVerifiedCreatedUser')));
    return result;
  }
  async resendVerifyCodeToUser(resendVerifyCodeToUser: ResendVerifyCodeToUser) {
    await lastValueFrom(this.httpClient.post<void>(urlJoin(this.getUrl(), 'resendVerifyCodeToUser'), resendVerifyCodeToUser));
  }
  async verifyCreatedUser(verifyCreatedUser: VerifyCreatedUser) {
    return await lastValueFrom(this.httpClient.post<boolean>(urlJoin(this.getUrl(), 'verifyCreatedUser'), verifyCreatedUser));
  }
  async forgotPassword(forgotPassword: ForgotPassword) {
    const routes = await this.configService.getRoutes();
    await lastValueFrom(
      this.httpClient.post<IPlayer>(`${this.appSettingsService.appSettings.apiurl}${routes.user}/forgotPassword`, forgotPassword),
    );
    return true;
  }
  async forgotPasswordSetNewPassword(forgotPasswordSetNew: ForgotPasswordSetNew) {
    const routes = await this.configService.getRoutes();
    await lastValueFrom(
      this.httpClient.post<IPlayer>(
        `${this.appSettingsService.appSettings.apiurl}${routes.user}/forgotPasswordSetNewPassword`,
        forgotPasswordSetNew,
      ),
    );
  }
  async verifyForgotPasswordCode(verifyForgotPasswordCode: VerifyForgotPasswordCode) {
    const routes = this.configService.getRoutes();
    return lastValueFrom(
      this.httpClient.post<boolean>(
        `${this.appSettingsService.appSettings.apiurl}${routes.user}/verifyForgotPasswordCode`,
        verifyForgotPasswordCode,
      ),
    );
  }
  getPlayerSettings() {
    AssertUtil.assert(() => this.initialized.getValue());
    return this.playerSettings;
  }
  setPlayerSettings(playerSettings: PlayerSettings) {
    this.playerSettings = playerSettings;
  }

  getLoggedIn() {
    AssertUtil.assert(() => this.initialized.getValue());
    return this.loggedInPlayer;
  }
  isLoggedIn() {
    return this.getLoggedIn() != null;
  }

  private getUrl() {
    const routes = this.configService.getRoutes();
    return urlJoin(this.appSettingsService.appSettings.apiurl, routes.user);
  }
}
