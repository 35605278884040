<mat-dialog-content fxLayout="column" style="padding-bottom: 10px" fxLayoutGap="10px">
  <span>{{ data.question.question }}</span>
  <div fxFlex>
    @if (!imageLoaded) {
      <mat-progress-spinner mode="indeterminate" fxFlexFill></mat-progress-spinner>
    }
    <img
      fxFlexFill
      [src]="data.question.imageId | imageUrl"
      [alt]="'Image url:' + data.question.imageId | imageUrl"
      (load)="onImageLoaded()"
      [ngClass]="{ showing: imageLoaded, hidden: !imageLoaded }"
    />
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="end">
  <button mat-raised-button (click)="onClose()">{{ 'buttons.close' | translate }}</button>
</mat-dialog-actions>
