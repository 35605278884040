import { Injectable } from '@angular/core';
import { HelpInstruction } from '../../../../../Common/model/player.settings';
import { HelpDialogClose } from '../../components/dialog/help-dialog-content/help-dialog-content.component';
import { DialogService } from '../../components/dialog/service/dialog.service';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class HelpDialogsService {
  constructor(
    private userService: UserService,
    private dialogService: DialogService,
  ) {}
  async checkAndShowDialog(helpInstruction: HelpInstruction) {
    const settings = this.userService.getPlayerSettings();
    if (settings.helpInstructionsDismissed.find((instruction) => instruction == helpInstruction) == null) {
      const result: HelpDialogClose = await this.dialogService.showHelpDialog(helpInstruction, true);
      if (result == HelpDialogClose.CloseAndDismissInstruction) {
        settings.helpInstructionsDismissed.push(helpInstruction);
        await this.userService.savePlayerSettings();
      }
    }
  }
  async showDialog(helpInstruction: HelpInstruction) {
    await this.dialogService.showHelpDialog(helpInstruction, false);
  }
}
