import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CrosswordBuildListModel } from '../../../../../Common/model/crossword.build';
import { IPlayer } from '../../../../../Common/model/player';
import { CrosswordUtil } from '../../../../../Common/util/crossword.util';
import { LanguageUtil } from '../../../../../Common/util/language.util';
import { ComponentCommunicationService } from '../../services/componentcommunication/component-communication.service';
import { CrosswordBuildService } from '../../services/crosswordbuild/crossword-build.service';
import { UserService } from '../../services/user/user.service';
import { LocalizedPaginatorIntl } from '../build-crossword/select-cwquestions/localized.paginator';
import { DialogService } from '../dialog/service/dialog.service';
import { SortColumn, SortColumnsUtil, SortOn, compare } from '../util/sort.util';
import { WaitUtil } from '../util/wait.util';

interface CrosswordBuildListData extends CrosswordBuildListModel {
  hovered?: boolean;
}

@Component({
  selector: 'app-list-crossword-builds',
  templateUrl: './list-crossword-builds.component.html',
  styleUrls: ['./list-crossword-builds.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: LocalizedPaginatorIntl }],
})
export class ListCrosswordBuildsComponent implements OnInit {
  crosswordBuilds = new MatTableDataSource<CrosswordBuildListData>();
  loaded = false;
  dispayedColumns = ['name', 'updated', 'published'];
  private currentSort: SortOn = { columnId: 'updated', direction: 'desc' };
  private sortUtil: SortColumnsUtil<CrosswordBuildListData>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(
    private crosswordBuildService: CrosswordBuildService,
    private userService: UserService,
    private router: Router,
    componentCommunication: ComponentCommunicationService,
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {
    componentCommunication.currentRouteTitle.next(translateService.instant('route-titles.list-crossword-builds'));
  }
  async ngOnInit() {
    const listModel = await this.crosswordBuildService.getListOfCrosswordBuilds(this.userService.getLoggedIn());

    this.crosswordBuilds.data = listModel.map(ListCrosswordBuildsComponent.createCrosswordListData);
    WaitUtil.waitUntilSet(() => this.paginator).then(() => (this.crosswordBuilds.paginator = this.paginator));
    this.populateSortItems();
    this.applySort();
    this.loaded = true;
  }
  applyFilter(filter: string) {
    this.crosswordBuilds.filter = filter;
  }
  onCrosswordBuildSelected(crosswordBuild: CrosswordBuildListData) {
    this.router.navigate(['/buildCrossword', crosswordBuild.externalId]);
  }
  async onCreateCrosswordBuild() {
    const player = this.userService.getLoggedIn();
    this.dialogService.wrapInProgress(async () => {
      const crosswordBuild = await this.crosswordBuildService.create(
        player,
        CrosswordUtil.createEmptyCrossword(LanguageUtil.getLanguageCode(this.translateService.getBrowserLang()), this.getPlayer(), {
          mainAxis: 12,
          crossAxis: 20,
        }),
      );
      this.router.navigate(['/buildCrossword', crosswordBuild.externalId]);
    }, this.translateService.instant('messages.creating-crossword-build'));
  }
  onSortChange(sort: Sort) {
    this.currentSort.columnId = sort.active;
    this.currentSort.direction = sort.direction;
    this.applySort();
    this.paginator?.firstPage();
  }
  private applySort() {
    this.crosswordBuilds.data = this.sortUtil.sortItem(this.currentSort, this.crosswordBuilds.data);
  }
  private static createCrosswordListData(crosswordBuild: CrosswordBuildListModel): CrosswordBuildListData {
    return { ...crosswordBuild, hovered: false };
  }
  private populateSortItems() {
    this.sortUtil = new SortColumnsUtil([
      new SortColumn('name', (t1, t2, isAsc) => compare(t1.name.toLowerCase(), t2.name.toLowerCase(), isAsc)),
      new SortColumn('updated', (t1, t2, isAsc) => compare(t1.updated, t2.updated, isAsc)),
      new SortColumn('published', (t1, t2, isAsc) => compare(t1.published, t2.published, isAsc)),
    ]);
  }
  private getPlayer(): IPlayer {
    return this.userService.getLoggedIn();
  }
}
