import { Injectable } from '@angular/core';
import { CanDeactivate, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { MessageBoxType } from '../../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../dialog/service/dialog.service';
import { BuildCrosswordComponent } from '../build-crossword.component';

@Injectable()
export class CanDeactivateBuildCrosswordComponent implements CanDeactivate<BuildCrosswordComponent> {
  constructor(
    private translateService: TranslateService,
    private dialogService: DialogService,
  ) {}
  canDeactivate(component: BuildCrosswordComponent): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return new Promise(async (resolve, reject) => {
      if (component.hasUnsavedChanges()) {
        const result = await this.dialogService.showMessageBox(
          '',
          this.translateService.instant('messages.crossword-build-unsaved'),
          MessageBoxType.YesNo,
          true,
        );
        resolve(result);
      }
      resolve(true);
    });
  }
}
