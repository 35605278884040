import { Pipe, PipeTransform } from '@angular/core';
import { SetCharTileData, TileShowCorrect, TileType } from '../../../../../../../Common/data/tile';
import { PlayerMatchColor } from '../../../util/player.match.color';

interface TileColorValues {
  text: string;
  typeData: any;
}

class InputStyle implements TileTypeStyles {
  getColor(value: TileColorValues) {
    if (value.text.length > 0) {
      return getComputedStyle(document.documentElement).getPropertyValue('--tile-style-text-set-in-input');
    } else {
      return getComputedStyle(document.documentElement).getPropertyValue('--tile-style-no-text-in-input');
    }
  }
}
class QuestionStyle implements TileTypeStyles {
  getColor() {
    return getComputedStyle(document.documentElement).getPropertyValue('--very-light-grey');
  }
}
class EmptyStyle implements TileTypeStyles {
  getColor() {
    return getComputedStyle(document.documentElement).getPropertyValue('--medium-to-light-grey');
  }
}
class AddQuestionStyle implements TileTypeStyles {
  getColor() {
    return getComputedStyle(document.documentElement).getPropertyValue('--medium-to-light-grey');
  }
}
class SetCharStyle implements TileTypeStyles {
  getColor(value: TileColorValues) {
    const charTileData = value.typeData as SetCharTileData;
    if (!charTileData) {
      return getComputedStyle(document.documentElement).getPropertyValue('--light-grey');
    }
    if (charTileData.showCorrect == TileShowCorrect.Wrong) {
      return getComputedStyle(document.documentElement).getPropertyValue('--tile-style-char-wrong');
    }
    return PlayerMatchColor.getColor(charTileData.playerMatch, charTileData.thisTurn);
  }
}
interface TileTypeStyles {
  getColor(value: TileColorValues);
}

@Pipe({ name: 'crosswordTileStyle' })
export class CrosswordTileStylePipe implements PipeTransform {
  private static readonly tileTypeStyles = new Map<TileType, TileTypeStyles>([
    [TileType.Input, new InputStyle()],
    [TileType.SetChar, new SetCharStyle()],
    [TileType.Question, new QuestionStyle()],
    [TileType.PriorityQuestion, new QuestionStyle()],
    [TileType.Empty, new EmptyStyle()],
    [TileType.AddQuestion, new AddQuestionStyle()],
  ]);
  transform(type: TileType, text: string, typeData: any): any {
    const style = CrosswordTileStylePipe.tileTypeStyles.get(type);
    return style.getColor({ text: text, typeData: typeData });
  }
}
