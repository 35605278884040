import { Component, HostListener, NgZone, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime, takeWhile } from 'rxjs/operators';
import { CrosswordScoreUtil } from '../../../../../Common/builder/util/statistics.util';
import { BuildJobGenerateCrosswordData, GenerateCrosswordAccuracy } from '../../../../../Common/data/generate.crossword.data';
import { PriorityValue } from '../../../../../Common/data/priority.value';
import { CrosswordDifficultyEnum, CrosswordType, ICrossword } from '../../../../../Common/model/crossword';
import { ICrosswordBuild, IndexPriority } from '../../../../../Common/model/crossword.build';
import { ICWQuestionBase } from '../../../../../Common/model/cwquestion';
import { IPlayer } from '../../../../../Common/model/player';
import { HelpInstruction } from '../../../../../Common/model/player.settings';
import { CWQuestionUtil } from '../../../../../Common/util/cwquestion.util';
import { IdUtil } from '../../../../../Common/util/id.util';
import { IndexPriorityUtil } from '../../../../../Common/util/index.priority.util';
import { LanguageUtil } from '../../../../../Common/util/language.util';
import { StoredBool } from '../../models/stored.bool';
import { AppSettingsService } from '../../services/appsettings/app-settings.service';
import { ComponentCommunicationService } from '../../services/componentcommunication/component-communication.service';
import { CrosswordBuildService } from '../../services/crosswordbuild/crossword-build.service';
import { CWQuestionService } from '../../services/cwquestion/cwquestion.service';
import { GenerateCrosswordService } from '../../services/generatecrossword/generate-crossword.service';
import { HelpDialogsService } from '../../services/helpdialogs/help-dialogs.service';
import { UserService } from '../../services/user/user.service';
import { FlexibleButtonInMenu, FlexibleButtonType } from '../common/flexable-button-menu/model/flexible.button.in.menu';
import { MessageBoxType } from '../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../dialog/service/dialog.service';
import { EditQuestionDialogService } from '../dialog/service/edit-question-dialog.service';
import { CrosswordConstants } from '../util/crossword.constants';
import { AddQuestionTileData, BuildCrosswordHandleCrosswordView } from '../util/handleCrosswordView/build.crossword.handle.crossword.view';
import { ShowHandleCrosswordView } from '../util/handleCrosswordView/show.handle.crossword.view';
import { SelectCWQuestionType } from './select-cwquestions/select-cwquestion-type';
import { EditQuestionInBuildService } from './services/edit.question.in.build.service';
import { AddQuestionInBuildUtil } from './util/add.question.in.build.util';

export interface ProgressTime {
  startTime: number;
  percentage: number;
  remainingTime: number;
}
export interface BuildScore {
  withPriority: number;
  withoutPriority: number;
}

export interface CrosswordBuildForm {
  name: FormControl<string>;
  description: FormControl<string>;
  difficulty: FormControl<CrosswordDifficultyEnum>;
  autoSave: FormControl<boolean>;
  cwQuestionsIds: FormControl<IndexPriority[]>;
  crossword: FormControl<ICrossword>;
}

@Component({
  selector: 'app-build-crossword',
  templateUrl: './build-crossword.component.html',
  styleUrls: ['./build-crossword.component.scss'],
})
export class BuildCrosswordComponent implements OnInit, OnDestroy {
  SelectCWQuestionType = SelectCWQuestionType;
  Difficulties = CrosswordConstants.Difficulties;
  Math = Math;

  _crosswordBuild: ICrosswordBuild;
  readonly handleCrosswordView: ShowHandleCrosswordView;
  crosswordBuildForm: FormGroup<CrosswordBuildForm>;

  buildScore: BuildScore;
  selectedQuestionIndex: number;
  buildActionButtons: FlexibleButtonInMenu[];
  crosswordActionButtons: FlexibleButtonInMenu[];

  sampleQuestionsChanged = new Subject<void>();
  showTime = false;
  generatingCrossword = false;
  refreshCrosswordTiles = new Subject<void>();
  autoSave = new StoredBool('autoSaveCrosswordBuild', true);
  private alive = true;
  private addQuestionTilePressed = new Subject<AddQuestionTileData>();
  constructor(
    private dialogService: DialogService,
    private userService: UserService,
    private crosswordBuildService: CrosswordBuildService,
    private generateCrosswordService: GenerateCrosswordService,
    private activatedRoute: ActivatedRoute,
    private ngZone: NgZone,
    private router: Router,
    private componentCommunication: ComponentCommunicationService,
    private translateService: TranslateService,
    private cwquestionService: CWQuestionService,
    private helpDialogsService: HelpDialogsService,
    private matDialog: MatDialog,
    private editQuestionDialogService: EditQuestionDialogService,
    private editQuestionInBuildService: EditQuestionInBuildService,
    private appsettingsService: AppSettingsService,
  ) {
    this.handleCrosswordView = new BuildCrosswordHandleCrosswordView(this.addQuestionTilePressed);
    this.buildScore = { withPriority: 0, withoutPriority: 0 };
  }

  async ngOnInit() {
    this.activatedRoute.params.pipe(takeWhile(() => this.alive)).subscribe(async (paramMap) => {
      const id = paramMap.id;
      this.ngZone.run(async () => {
        await this.initWithId(id);
      });
    });
    this.addQuestionTilePressed.pipe(takeWhile(() => this.alive)).subscribe((position) => this.onAddQuestionTilePressed(position));
    this.handleCrosswordView
      .tilesStateObservable()
      .pipe(takeWhile(() => this.alive))
      .subscribe((tilesState) => {
        const cwQuestion = tilesState.focused?.cwQuestion;
        this.onSelectedQuestionInCrossword(cwQuestion);
      });
  }

  ngOnDestroy(): void {
    this.alive = false;
  }

  async initWithId(id: string) {
    const crosswordBuildTmp = await this.loadCrosswordBuild(id);
    if (!crosswordBuildTmp) {
      await this.dialogService.showMessageBox('', this.translateService.instant('messages.missing-build'), MessageBoxType.Ok);
      this.router.navigateByUrl('/listCrosswordBuilds');
      return;
    }
    this.setCrosswordBuild(crosswordBuildTmp);
    this.createForm();
    this.updateTitle();

    this.reloadCrosswordData();
    this.refreshBuildActionButtons();

    this.helpDialogsService.checkAndShowDialog(HelpInstruction.BuildCrossword);
  }
  private createForm() {
    this.crosswordBuildForm = new FormGroup<CrosswordBuildForm>({
      name: new FormControl(this._crosswordBuild.crossword.name, [Validators.required, Validators.maxLength(30)]),
      description: new FormControl(this._crosswordBuild.crossword.description, [Validators.maxLength(200)]),
      difficulty: new FormControl(this._crosswordBuild.crossword.difficulty, [Validators.required]),
      autoSave: new FormControl(this.autoSave.value),
      crossword: new FormControl(this._crosswordBuild.crossword),
      cwQuestionsIds: new FormControl(this._crosswordBuild.cwQuestionsIds),
    });
    this.crosswordBuildForm.valueChanges.pipe(debounceTime(500)).subscribe(() => this.onCrosswordBuildFormChanged());
    this.crosswordBuildForm.controls.autoSave.valueChanges.subscribe((value) => (this.autoSave.value = value));
    console.log('Created new build form', this.crosswordBuildForm.dirty);
  }

  async onCrosswordBuildFormChanged() {
    const value = this.crosswordBuildForm.value;
    if (value.autoSave) {
      await this.saveCrosswordBuild();
    }
    this.refreshBuildActionButtons();
    this.refreshCrosswordActionButtons();
  }
  async onDeleteBuild() {
    const result = await this.dialogService.showMessageBox(
      '',
      this.translateService.instant('messages.remove-crosswordbuild'),
      MessageBoxType.YesNo,
      true,
    );
    if (result) {
      this.dialogService.showProgress(true);
      this.crosswordBuildService.delete(this._crosswordBuild._id).then(() => {
        this.dialogService.showProgress(false);
        this.router.navigateByUrl('/listCrosswordBuilds');
      });
    }
  }
  async onLayoutQuestionsCrossword() {
    await this.generateCrossword();
  }

  @HostListener('window:beforeunload', ['$event'])
  windowBeforeunload() {
    if (this.hasUnsavedChanges()) {
      return this.translateService.instant('messages.crossword-build-unsaved');
    }
  }
  hasUnsavedChanges() {
    return this.crosswordBuildForm?.dirty;
  }

  async onRemoveSelectedQuestion() {
    const crossword = this.crosswordBuildForm.value.crossword;
    const index = crossword.crosswordQuestions.findIndex(
      (crosswordQuestion) => crosswordQuestion.cwQuestion.indexId == this.selectedQuestionIndex,
    );
    if (index >= 0) {
      crossword.crosswordQuestions.splice(index, 1);
      this.crosswordBuildForm.controls.crossword.setValue(crossword);
      this.reloadCrosswordData();
    }
  }

  async saveCrosswordBuild() {
    const crosswordBuild: ICrosswordBuild = this.getCrosswordBuildFromForm();
    const crosswordBuildUpdated = await this.crosswordBuildService.update(crosswordBuild);
    this.setCrosswordBuild(crosswordBuildUpdated);
    this.crosswordBuildForm.markAsPristine();
    this.updateTitle();
  }
  private getCrosswordBuildFromForm() {
    const value = this.crosswordBuildForm.value;
    const crosswordBuild: ICrosswordBuild = {
      _id: this._crosswordBuild._id,
      crossword: {
        ...value.crossword,
        author: this.getPlayer(),
        name: value.name,
        description: value.description,
        difficulty: value.difficulty,
        languageCode: LanguageUtil.getLanguageCode(this.translateService.getBrowserLang()),
        type: CrosswordType.Built,
        _id: this._crosswordBuild.crossword?._id,
      },
      cwQuestionsIds: value.cwQuestionsIds,
      player: this.getPlayer(),
      published: this._crosswordBuild.published,
      date: new Date(),
      externalId: this._crosswordBuild.externalId,
    };
    return crosswordBuild;
  }

  private setCrosswordBuild(crosswordBuild: ICrosswordBuild) {
    this._crosswordBuild = crosswordBuild;
  }

  private updateTitle() {
    const crossword = this.crosswordBuildForm.value.crossword;
    this.componentCommunication.currentRouteTitle.next(crossword.name ?? '');
  }

  async onSelectedQuestionInCrossword(cwQuestionBase: ICWQuestionBase) {
    if (this.selectedQuestionIndex != cwQuestionBase?.indexId) {
      this.selectedQuestionIndex = cwQuestionBase?.indexId;
      this.refreshCrosswordActionButtons();
    }
  }
  async onAddQuestionTilePressed(addQuestionTileData: AddQuestionTileData) {
    const addQuestionInBuild = new AddQuestionInBuildUtil(
      this.crosswordBuildForm.controls,
      this.sampleQuestionsChanged,
      this.dialogService,
      this.matDialog,
      this.cwquestionService,
      this.editQuestionDialogService,
      this.translateService,
    );
    const result = await addQuestionInBuild.addQuestion(addQuestionTileData);
    if (result) {
      this.reloadCrosswordData();
    }
  }
  async onEditSelectedQuestion() {
    const cwQuestions = (await this.cwquestionService.loadQuestions({ pageIndex: 0, pageSize: 1, ids: [this.selectedQuestionIndex] }))
      .queryResult;
    const cwQuestion = cwQuestions[0];
    if (!IdUtil.idEquals(cwQuestion.authorId, this.getPlayer()._id)) {
      await this.dialogService.showMessageBox('', this.translateService.instant('messages.cant-edit-selected-question'), MessageBoxType.Ok);
      return;
    }
    const question = CWQuestionUtil.getCWQuestionFromSearchQuestionsItem(cwQuestion, this.translateService.getBrowserLang());
    const crossword = this.crosswordBuildForm.value.crossword;
    const crosswordTmp = await this.editQuestionInBuildService.editQuestion(crossword, question);
    if (crosswordTmp) {
      this.crosswordBuildForm.controls.crossword.setValue(crosswordTmp);
      this.sampleQuestionsChanged.next();
      this.reloadCrosswordData();
    }
  }
  private refreshHandleCrosswordTile() {
    const crossword = this.crosswordBuildForm.value.crossword;
    this.handleCrosswordView.refresh(crossword);
    this.refreshCrosswordTiles.next();
  }
  private refreshBuildActionButtons() {
    this.buildActionButtons = [];
    this.buildActionButtons.push({
      id: 'save',
      text: this.translateService.instant('buttons.save'),
      icon: 'save',
      disabled: !this.crosswordBuildForm.valid || !this.crosswordBuildForm.dirty,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onSaveCrosswordBuild() },
    });
    this.buildActionButtons.push({
      id: 'publish-crossword',
      text: this.translateService.instant('buttons.publish-crossword'),
      icon: 'publish',
      visible: !this._crosswordBuild.published,
      disabled: !this.crosswordBuildForm.valid,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onPublishCrossword() },
    });
    this.buildActionButtons.push({
      id: 'unpublish-crossword',
      text: this.translateService.instant('buttons.unpublish-crossword'),
      icon: 'remove',
      visible: this._crosswordBuild.published,
      disabled: !this.crosswordBuildForm.valid,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onUnpublishCrossword() },
    });
    this.buildActionButtons.push({
      id: 'update-crossword',
      text: this.translateService.instant('buttons.update-crossword'),
      icon: 'update',
      visible: this._crosswordBuild.published,
      disabled: !this.crosswordBuildForm.valid,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onPublishCrossword() },
    });
    this.buildActionButtons.push({
      id: 'delete',
      text: this.translateService.instant('buttons.delete'),
      icon: 'delete',
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onDeleteBuild() },
    });
  }
  private refreshCrosswordActionButtons() {
    this.crosswordActionButtons = [];
    this.crosswordActionButtons.push({
      id: 'layout-questions-crossword',
      text: this.translateService.instant('buttons.layout-questions-crossword'),
      icon: 'refresh',
      disabled: this._crosswordBuild.cwQuestionsIds.length == 0,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onLayoutQuestionsCrossword() },
    });
    this.crosswordActionButtons.push({
      id: 'remove-selected-question',
      text: this.translateService.instant('buttons.remove-selected-question'),
      icon: 'delete',
      disabled: this.selectedQuestionIndex == null,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onRemoveSelectedQuestion() },
    });
    this.crosswordActionButtons.push({
      id: 'edit-selected-question',
      text: this.translateService.instant('buttons.edit-selected-question'),
      icon: 'edit',
      disabled: this.selectedQuestionIndex == null,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onEditSelectedQuestion() },
    });
  }

  private reloadCrosswordData() {
    const crossword = this.crosswordBuildForm.value.crossword;
    this.selectedQuestionIndex = null;
    this.buildScore = CrosswordScoreUtil.getScore(crossword);
    this.refreshHandleCrosswordTile();
    this.refreshCrosswordActionButtons();
  }
  private async generateCrossword() {
    this.showTime = true;
    this.generatingCrossword = true;
    const buildJob: BuildJobGenerateCrosswordData = {
      accuracy: GenerateCrosswordAccuracy.Customize,
      questions: this._crosswordBuild.cwQuestionsIds,
      size: { mainAxis: 12, crossAxis: 20 },
      crosswordBuildId: this._crosswordBuild._id,
    };
    const generateCrosswordLocally = this.appsettingsService.appSettings.generateCrosswordLocally;
    const crossword = await (generateCrosswordLocally
      ? this.generateCrosswordService.generateCrosswordOnApp(buildJob)
      : this.generateCrosswordService.generateCrosswordOnServer(buildJob));
    if (!crossword) {
      this.generatingCrossword = false;
      return;
    }
    this.crosswordBuildForm.controls.crossword.setValue(crossword);
    this.reloadCrosswordData();
    this.generatingCrossword = false;

    this.alertUserOnNotContainedQuestions();
  }
  private async alertUserOnNotContainedQuestions() {
    const crosswordBuildValue = this.crosswordBuildForm.value;
    const themeQuestionIds = IndexPriorityUtil.getIndexsWithPriority(crosswordBuildValue.cwQuestionsIds, PriorityValue.Theme);

    const containedQuestionIds = crosswordBuildValue.crossword.crosswordQuestions.map(
      (crosswordQuestion) => crosswordQuestion.cwQuestion.indexId,
    );
    const setContainedQuestionIds = new Set<number>(containedQuestionIds);
    const notContainedThemeQuestions = themeQuestionIds.filter((indexId) => !setContainedQuestionIds.has(indexId));

    if (notContainedThemeQuestions.length) {
      const themeQuestions = await this.cwquestionService.loadQuestionsWithIndexIds(notContainedThemeQuestions);

      this.dialogService.showMessageBox(
        '',
        this.translateService.instant('messages.selected-questions-didnt-fit', {
          questions: themeQuestions.map((cwQuestion) => cwQuestion.question).join('<br>'),
        }),
        MessageBoxType.Ok,
      );
    }
  }

  private getPlayer(): IPlayer {
    return this.userService.getLoggedIn();
  }
  private async loadCrosswordBuild(id: string) {
    try {
      return await this.crosswordBuildService.loadByExternalId(id);
    } catch (error) {
      console.warn('Failed to get crossword build with id', id);
    }
    return null;
  }
  private async onSaveCrosswordBuild() {
    await this.dialogService.wrapInProgress(
      () => this.saveCrosswordBuild(),
      this.translateService.instant('messages.saving-crosswordbuild'),
    );
  }
  private async onPublishCrossword() {
    await this.dialogService.wrapInProgress(async () => {
      const crosswordBuild = this.getCrosswordBuildFromForm();
      if (crosswordBuild.published) {
        const crosswordBuildUpdated = await this.crosswordBuildService.republish(crosswordBuild);
        this.setCrosswordBuild(crosswordBuildUpdated);
      } else {
        const crosswordBuildUpdated = await this.crosswordBuildService.publish(crosswordBuild);
        this.setCrosswordBuild(crosswordBuildUpdated);
      }
    }, this.translateService.instant('messages.publishing-crossword'));
    this.dialogService.showSnackbar(this.translateService.instant('messages.crossword-published'), 2000);
    this.refreshBuildActionButtons();
  }
  private async onUnpublishCrossword() {
    await this.dialogService.wrapInProgress(async () => {
      await this.saveCrosswordBuild();
      const crosswordBuild = await this.crosswordBuildService.unpublish(this.getCrosswordBuildFromForm());
      this.setCrosswordBuild(crosswordBuild);
    }, this.translateService.instant('messages.unpublishing-crossword'));
    this.dialogService.showSnackbar(this.translateService.instant('messages.crossword-unpublished'), 2000);
    this.refreshBuildActionButtons();
  }
}
