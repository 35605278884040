import { AfterContentInit, ChangeDetectorRef, Component, ElementRef, Input, isDevMode, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SizeChanged } from '../../util/resize.directive';

import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged, takeWhile } from 'rxjs/operators';
import { Direction, Position } from '../../../../../../Common/data/position';
import { PriorityValue } from '../../../../../../Common/data/priority.value';
import {
  CrosswordTile,
  CrosswordTileEvent,
  QuestionWithCrosswordTiles,
  TileContainerType,
  TilesState,
} from '../../../../../../Common/data/tile';
import { ICrosswordSize } from '../../../../../../Common/model/crossword';
import { CWQuestionType, ICWQuestionBase } from '../../../../../../Common/model/cwquestion';
import { IHandleCrosswordView, ShowCrosswordAs } from '../../util/handleCrosswordView/handle.crossword.view';
import { TileUtil } from '../../util/tile.util';
import { WaitUtil } from '../../util/wait.util';

export interface ShowQuestionText {
  position: Position;
  question: ICWQuestionBase;
}

@Component({
  selector: 'app-show-crossword',
  templateUrl: './show-crossword.component.html',
  styleUrls: ['./show-crossword.component.scss'],
})
export class ShowCrosswordComponent implements OnInit, OnDestroy, AfterContentInit {
  TileContainerType = TileContainerType;
  ShowCrosswordAs = ShowCrosswordAs;
  Direction = Direction;
  PriorityValue = PriorityValue;
  CWQuestionType = CWQuestionType;
  @Input()
  handleCrosswordView: IHandleCrosswordView;

  showCrosswordAs: ShowCrosswordAs;

  matrixTilesMain: CrosswordTile[][];
  matrixTilesCross: CrosswordTile[][];
  rowTiles: QuestionWithCrosswordTiles[];
  matrixTileSize = 0;
  rowTileSize = 0;
  width = 0;
  crosswordSize: ICrosswordSize = { mainAxis: 0, crossAxis: 0 };
  matrixDirection: Direction = Direction.MAINAXIS;
  tilesState: TilesState;
  showShowQuestionText: ShowQuestionText;
  tileContainerWidth = new BehaviorSubject<number>(0);
  tileContainerHeight: number;
  private alive = true;

  @ViewChild('showCrossword')
  showCrossword: ElementRef;

  constructor(private changeDetection: ChangeDetectorRef) {}

  async ngAfterContentInit() {
    await WaitUtil.waitUntilSet(() => this.showCrossword?.nativeElement);
    const width = this.showCrossword.nativeElement.clientWidth;
    this.tileContainerWidth.next(width);
  }

  ngOnDestroy(): void {
    this.alive = false;
  }
  ngOnInit(): void {
    this.tileContainerWidth
      .pipe(takeWhile(() => this.alive))
      .pipe(distinctUntilChanged())
      .subscribe((width) => this.refreshTileSize(width));

    this.resetCrosswordTiles();
    this.handleCrosswordView
      .tilesChangedObservable()
      .pipe(takeWhile(() => this.alive))
      .subscribe(() => {
        this.resetCrosswordTiles();
        this.refreshTileSize(this.tileContainerWidth.value);
      });
    this.handleCrosswordView
      .tilesStateObservable()
      .pipe(takeWhile(() => this.alive))
      .subscribe((tilesState) => {
        this.tilesState = tilesState;
        this.updateSelectedQuestion();
      });
    this.handleCrosswordView
      .showCrosswordAsObservable()
      .pipe(takeWhile(() => this.alive))
      .subscribe((showCrosswordAs) => (this.showCrosswordAs = showCrosswordAs));
  }
  onMatrixTileEvent(tileEvent: CrosswordTileEvent) {
    this.handleCrosswordView.handleMatrixTileEvent(tileEvent, this.matrixDirection);
  }
  onRowTileEvent(tileEvent: CrosswordTileEvent, rowTiles: CrosswordTile[]) {
    this.handleCrosswordView.handleRowTileEvent(tileEvent, rowTiles);
  }
  private updateSelectedQuestion() {
    const question = this.tilesState.focused?.cwQuestion;
    this.logSelectedQuestionIfDevMode(question);
    if (question && this.showCrosswordAs == ShowCrosswordAs.Matrix) {
      const position = this.tilesState.focused.interval;
      const positionAccordingToMatrixDirection = this.matrixDirection == Direction.MAINAXIS ? position : position.rotate90Degrees();
      this.showShowQuestionText = {
        position: new Position(
          positionAccordingToMatrixDirection.x * this.matrixTileSize,
          positionAccordingToMatrixDirection.y * this.matrixTileSize,
          position.direction,
        ),
        question: question,
      };
    } else {
      this.showShowQuestionText = null;
    }
  }

  private logSelectedQuestionIfDevMode(question: ICWQuestionBase) {
    if (isDevMode()) {
      if (question) {
        console.log('Selected question is:%s with answer:%s', question.question, question.answer);
      }
    }
  }

  private resetCrosswordTiles() {
    this.matrixTilesMain = this.handleCrosswordView.getMatrixCrosswordTiles();
    this.matrixTilesCross = TileUtil.getCrosswordTilesCross(this.matrixTilesMain);
    this.rowTiles = this.sortRowTilesAfterTheme(this.handleCrosswordView.getRowCrosswordTiles());
  }
  private sortRowTilesAfterTheme(rowTiles: QuestionWithCrosswordTiles[]): QuestionWithCrosswordTiles[] {
    return rowTiles.sort((a, b) => a.cwQuestion.priority - b.cwQuestion.priority);
  }

  private refreshTileSize(tileContainerWidth: number) {
    if (!this.matrixTilesMain) {
      return;
    }
    this.matrixDirection = tileContainerWidth > 1000 ? Direction.CROSSAXIS : Direction.MAINAXIS;
    this.crosswordSize = this.handleCrosswordView.getCrosswordSize();
    this.matrixTileSize = this.getMatrixTileSize(tileContainerWidth);
    const maxRowTilesCount =
      this.rowTiles.length > 0
        ? this.rowTiles.map((rowTile) => rowTile.tiles.length).reduce((length1, length2) => (length1 > length2 ? length1 : length2))
        : 0;
    this.rowTileSize = Math.min(tileContainerWidth / maxRowTilesCount, 60);
    this.tileContainerHeight = this.matrixTileSize * this.crosswordSize.crossAxis;
    this.updateSelectedQuestion();
    this.changeDetection.detectChanges();
  }

  private getMatrixTileSize(tileContainerWidth: number) {
    const playFieldWidth = this.matrixDirection == Direction.CROSSAXIS ? this.crosswordSize.crossAxis : this.crosswordSize.mainAxis;
    return tileContainerWidth / playFieldWidth;
  }

  onSizeChanged(size: SizeChanged) {
    this.tileContainerWidth.next(size.width);
  }
}
