import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { ErrorResult, ErrorType } from '../../../../../../Common/data/error.result';
import { ComponentCommunicationService } from '../../../services/componentcommunication/component-communication.service';
import { StoreRouteService } from '../../../services/storeroute/store.route.service';
import { UserService } from '../../../services/user/user.service';
import { BrowserUtil } from '../../../util/browser.util';
import { MessageBoxType } from '../../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../dialog/service/dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: UntypedFormGroup;
  submitButtonValid = true;
  loggedIn = false;
  showCreateUser = false;
  redirectUrl: string = null;
  private readonly defaultLoggedInUrl = '/listCrosswordGames';
  private alive = true;

  constructor(
    private userService: UserService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private storeRouteService: StoreRouteService,
    private componentCommunicationService: ComponentCommunicationService,
  ) {
    this.createForm();
    this.loggedIn = this.userService.isLoggedIn();
  }
  ngOnDestroy(): void {
    this.alive = false;
  }

  async ngOnInit() {
    this.route.queryParamMap.pipe(takeWhile(() => this.alive)).subscribe((paramMap) => {
      const redirectUrlKey = 'redirectUrl';
      if (paramMap.has(redirectUrlKey)) {
        this.redirectUrl = paramMap.get(redirectUrlKey);
        console.log('Init with url:', this.redirectUrl);
      }
      this.showCreateUser = this.shouldShowCreateUser(paramMap);
    });
    if (this.loggedIn) {
      this.router.navigateByUrl(await this.getStoredOrDefaultLoggedInUrl());
    }
  }

  private shouldShowCreateUser(paramMap: ParamMap): boolean {
    if (paramMap.has('invitedBy')) {
      return true;
    }
    if (this.redirectUrl?.search('playCrossword')) {
      return true;
    }
    if (BrowserUtil.isPWA()) {
      return true;
    }
    return false;
  }

  async onSubmit() {
    if (!this.componentCommunicationService.allowCookies.value) {
      this.dialogService.showMessageBox('', this.translateService.instant('messages.allow-cookies-before-continue'), MessageBoxType.Ok);
      return;
    }
    if (this.loginForm.valid) {
      this.dialogService.showProgress(true, this.translateService.instant('messages.signing-in'));
      try {
        await this.userService.login(this.loginForm.get('email').value, this.loginForm.get('password').value);

        if (this.redirectUrl != null) {
          this.router.navigateByUrl(decodeURIComponent(this.redirectUrl));
        } else {
          this.router.navigateByUrl(await this.getStoredOrDefaultLoggedInUrl());
        }
      } catch (error) {
        const errorResult: ErrorResult = error.error;
        if (errorResult?.type === ErrorType.WRONG_EMAIL) {
          this.loginForm.controls.email.setErrors({ wrongEmail: true });
        } else if (errorResult?.type === ErrorType.WRONG_PASSWORD) {
          this.loginForm.controls.password.setErrors({ wrongPassword: true });
        } else {
          throw error;
        }
      } finally {
        this.dialogService.showProgress(false, '');
      }
    }
  }

  private async getStoredOrDefaultLoggedInUrl() {
    const storedRoute = await this.storeRouteService.getStoredRoute();
    if (LoginComponent.shouldNavigateToStoredRoute(storedRoute)) {
      return storedRoute;
    } else {
      return this.defaultLoggedInUrl;
    }
  }

  private static shouldNavigateToStoredRoute(storedRoute: string) {
    if (!storedRoute) {
      return false;
    }
    if (storedRoute == '/') {
      return false;
    }
    return storedRoute.indexOf('login') < 0;
  }

  private createForm() {
    this.loginForm = new UntypedFormGroup({
      email: new UntypedFormControl('', [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
    });
  }
}
