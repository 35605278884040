import { Pipe, PipeTransform } from '@angular/core';
import { ImageService } from './image.service';

@Pipe({ name: 'imageUrl' })
export class ImageUrlPipe implements PipeTransform {
  constructor(private imageService: ImageService) {}
  transform(value: any) {
    return this.imageService.getImageUrl(value);
  }
}
