<mat-dialog-content fxLayoutAlign="center center" style="overflow: hidden">
  @if (!data.minimal) {
    <div id="progress-dialog" fxLayout="column" fxFlex class="background-div" fxLayoutGap="10px" fxLayoutAlign="center center">
      <h3 mat-dialog-title style="text-align: center; margin-right: 5px; margin-left: 5px">{{ data.message }}</h3>
      <mat-spinner style="height: 100px"> </mat-spinner>
    </div>
  }
  @if (data.minimal) {
    <div id="progress-dialog" fxLayout="row" fxLayoutGap="10px" class="background-div" fxLayoutAlign="center center">
      <span fxFlex>{{ data.message }}</span>
      <mat-progress-bar mode="indeterminate"> </mat-progress-bar>
    </div>
  }
</mat-dialog-content>
