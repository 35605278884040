import { Subject } from 'rxjs';
import { Direction } from '../../../../../../Common/data/position';
import { CrosswordTile, CrosswordTileEvent, TileEventType, TilesState } from '../../../../../../Common/data/tile';
import { HandleCrosswordTileUtil } from './handle.crossword.tile.util';
import { IHandleMatrixTileEventType } from './handle.crossword.view';
import { ShowHandleCrosswordView } from './show.handle.crossword.view';

class HandleAddQuestionTileClicked implements IHandleMatrixTileEventType {
  constructor(protected handleTileEvents: BuildCrosswordHandleCrosswordView) {}

  handleMatrixTileEventType(
    tileEvent: CrosswordTileEvent,
    state: TilesState,
    tiles: CrosswordTile[][],
    matrixDirection: Direction,
  ): TilesState {
    this.handleTileEvents.onAddQuestionTilePressed(tileEvent.tile.x, tileEvent.tile.y, matrixDirection);
    return state;
  }
}

export interface AddQuestionTileData {
  x: number;
  y: number;
  matrixDirection: Direction;
}

export class BuildCrosswordHandleCrosswordView extends ShowHandleCrosswordView {
  constructor(private addQuestionTilePressed: Subject<AddQuestionTileData>) {
    super(true);

    this.handleMatrixEventTypes[TileEventType.AddQuestionTileClicked] = new HandleAddQuestionTileClicked(this);
  }
  protected getVisibleQuestions() {
    return this.crossword.crosswordQuestions;
  }
  protected createMatrixCrosswordTiles() {
    const tiles = super.createMatrixCrosswordTiles();
    HandleCrosswordTileUtil.setTilesAsAddCrosswordTiles(tiles, this.crossword);
    HandleCrosswordTileUtil.setQuestionTilesAsShowPriority(tiles, this.crossword);
    return tiles;
  }

  public async onAddQuestionTilePressed(x: number, y: number, matrixDirection: Direction) {
    this.addQuestionTilePressed.next({ x, y, matrixDirection });
  }
}
