import { PriorityValue } from '../../data/priority.value';
import { QuestionBasePriority } from '../../model/cwquestion';
import { ArrayUtil } from '../../util/array.util';
import { BuildMatrix, BuildQuestion } from './matrix';

export class QuestionsContainer {
  private readonly questionsContainer: Map<PriorityValue, BuildQuestion[]>;
  private readonly priorityValues = [PriorityValue.Low, PriorityValue.Medium, PriorityValue.High, PriorityValue.Theme];
  constructor(readonly questionsBase: QuestionBasePriority[]) {
    this.questionsContainer = new Map<PriorityValue, BuildQuestion[]>([
      [PriorityValue.Low, []],
      [PriorityValue.Medium, []],
      [PriorityValue.High, []],
      [PriorityValue.Theme, []],
    ]);

    for (const q of questionsBase) {
      this.questionsContainer.get(q.priority).push(new BuildQuestion(q));
    }
  }
  public getQuestionsToFit(matrix: BuildMatrix, priorityValue: PriorityValue, maxLength: number) {
    const ids = this.getIdsSet(matrix);
    const allQuestionsOfPriority = this.getQuestionsOfPriority(priorityValue);
    return ArrayUtil.shuffleArray(allQuestionsOfPriority)
      .filter((question) => !ids.has(question.indexId))
      .slice(0, maxLength);
  }
  public getQuestionsOfPriorityOrHigherToFit(matrix: BuildMatrix, priorityValue: PriorityValue, maxLength: number) {
    const ids = this.getIdsSet(matrix);
    const allQuestionsOfPriority = this.getQuestionsOfPriorityOrHigher(priorityValue);
    return ArrayUtil.shuffleArray(allQuestionsOfPriority)
      .filter((question) => !ids.has(question.indexId))
      .slice(0, maxLength);
  }
  public getQuestionsOfPriority(priorityValue: PriorityValue) {
    return this.questionsContainer.get(priorityValue);
  }
  public getQuestionsOfPriorityOrHigher(priorityValue: PriorityValue) {
    const questionsofHigherPriority: BuildQuestion[] = [];
    const index = this.priorityValues.indexOf(priorityValue);
    for (let i = index; i < this.priorityValues.length; i++) {
      questionsofHigherPriority.push(...this.questionsContainer.get(this.priorityValues[i]));
    }
    return questionsofHigherPriority;
  }
  private getIdsSet(crosswordMatrix: BuildMatrix) {
    return new Set(crosswordMatrix.getQuestionIds());
  }
  private getRandomQuestions() {
    return [
      ...this.questionsContainer.get(PriorityValue.Low),
      ...this.questionsContainer.get(PriorityValue.Medium),
      ...this.questionsContainer.get(PriorityValue.High),
    ];
  }
}
