import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom, takeWhile, timer } from 'rxjs';
import { debounceTime, mergeWith } from 'rxjs/operators';
import { GameNotificationType } from '../../../../../../Common/data/crossword.game.notification';
import { ErrorResult, ErrorType } from '../../../../../../Common/data/error.result';
import { GameState } from '../../../../../../Common/data/game.state';
import { GameType } from '../../../../../../Common/data/game.type';
import { PlayerInfo } from '../../../../../../Common/data/player.score';
import { Point } from '../../../../../../Common/data/point';
import { CrosswordType } from '../../../../../../Common/model/crossword';
import { GamePlayerColor, ICrosswordGame } from '../../../../../../Common/model/crossword.game';
import { IPlayer } from '../../../../../../Common/model/player';
import { HelpInstruction } from '../../../../../../Common/model/player.settings';
import { AssertUtil } from '../../../../../../Common/util/assert.util';
import { CrosswordGamePlay } from '../../../../../../Common/util/crossword.game.play';
import { CrosswordGamePlayerUtil } from '../../../../../../Common/util/crossword.game.player.util';
import { CrosswordGameTurnUtil } from '../../../../../../Common/util/crossword.game.turn.util';
import { CrosswordGameUtil } from '../../../../../../Common/util/crossword.game.util';
import { AddNotificationService } from '../../../services/addnotification/add.notification.service';
import { ComponentCommunicationService } from '../../../services/componentcommunication/component-communication.service';
import { CrosswordGameService } from '../../../services/crosswordgame/crossword-game.service';
import { GamePlayerColorService } from '../../../services/game-player-color/game-player-color.service';
import { HelpDialogsService } from '../../../services/helpdialogs/help-dialogs.service';
import { PushNotificationService } from '../../../services/pushnotifcation/push-notification.service';
import { UserService } from '../../../services/user/user.service';
import { FlexibleButtonInMenu, FlexibleButtonType } from '../../common/flexable-button-menu/model/flexible.button.in.menu';
import { MessageBoxType } from '../../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../../dialog/service/dialog.service';
import { CrosswordConstants } from '../../util/crossword.constants';
import { GameHandleCrosswordView } from '../../util/handleCrosswordView/game.handle.crossword.view';
import { StringUtil } from '../../util/string.util';

enum ShareType {
  ByEmail,
  ByDeviceShare,
}

@Component({
  selector: 'app-crossword-actions',
  templateUrl: './crossword-actions.component.html',
  styleUrls: ['./crossword-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrosswordActionsComponent implements AfterContentInit, OnDestroy {
  ShareType = ShareType;
  GameState = GameState;
  GameType = GameType;

  crosswordGame: ICrosswordGame;
  readonly gameHandleCrosswordView: GameHandleCrosswordView;
  private readonly crosswordGamePlay: CrosswordGamePlay;
  crosswordActionButtons: FlexibleButtonInMenu[];
  private alive = true;
  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private crf: ChangeDetectorRef,
    private dialogService: DialogService,
    private addNotificationService: AddNotificationService,
    private crosswordGameService: CrosswordGameService,
    private gamePlayerColorService: GamePlayerColorService,
    private helpDialogsService: HelpDialogsService,
    private componentCommunicationService: ComponentCommunicationService,
    private pushNotificationService: PushNotificationService,
  ) {
    this.gameHandleCrosswordView = this.crosswordGameService.getGameHandleCrosswordView();
    this.crosswordGamePlay = this.crosswordGameService.getCrosswordGamePlay();
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
  ngAfterContentInit() {
    this.crosswordGame = this.crosswordGameService.getCrosswordGame();
    this.refreshActionButtonsAndTexts();
    this.gameHandleCrosswordView
      .tilesStateObservable()
      .pipe(
        mergeWith(this.gameHandleCrosswordView.charSetStatisticsObservable()),
        mergeWith(this.crosswordGameService.crosswordGameSubject),
      )
      .pipe(takeWhile(() => this.alive))
      .pipe(debounceTime(50))
      .subscribe(() => {
        this.crosswordGame = this.crosswordGameService.getCrosswordGame();
        this.refreshActionButtonsAndTexts();
      });
    this.componentCommunicationService.userWantsToJoin.pipe(takeWhile(() => this.alive)).subscribe(async (value) => {
      if (value) {
        await this.handleUserWantsToJoinGame();
      }
    });
  }

  private refreshActionButtonsAndTexts() {
    const crossword = this.crosswordGame.initial.crossword;
    const playerLeftToJoin = CrosswordGameUtil.getAvailablePlayerColors(this.crosswordGame).length;
    const playerCreatedGame = CrosswordGamePlayerUtil.isCreatorOfGame(this.crosswordGame, this.getPlayer());
    const isCurrentPlayer = CrosswordGamePlayerUtil.isCurrentPlayer(this.crosswordGame, this.getPlayer());
    const canLayChars = isCurrentPlayer;
    const canClearChars = this.crosswordGame.global.state === GameState.Started && isCurrentPlayer;
    const matchGame = this.crosswordGame.global.type == GameType.Match;
    const allowToSendShare = playerCreatedGame && this.crosswordGame.global.state === GameState.Created;
    const allowToStartGame = playerCreatedGame && this.crosswordGame.global.state === GameState.Created;
    const currentPlayerIsInGame = CrosswordGamePlayerUtil.isPlayerInCrosswordGame(this.crosswordGame, this.getPlayer());
    const canSeeCharActions = this.crosswordGame.global.state === GameState.Started && currentPlayerIsInGame;
    const charStatistics = this.gameHandleCrosswordView.getCharStatisticts();
    const canJoinGame =
      this.crosswordGame.global.state == GameState.Created &&
      !this.hasPlayedCrosswordGameBefore() &&
      !currentPlayerIsInGame &&
      playerLeftToJoin > 0;
    const canLeaveGame =
      this.crosswordGame.global.state != GameState.Ended && currentPlayerIsInGame && this.crosswordGame.playersInfo.players.length > 1;
    const canEndGame = playerCreatedGame && this.crosswordGame.global.state != GameState.Ended;
    const descriptionExists = crossword.description != null;
    const canPublishResult =
      this.crosswordGame.global.state === GameState.Ended &&
      playerCreatedGame &&
      !this.crosswordGame.global.published &&
      crossword.type == CrosswordType.Built;
    const canUnpublishResult =
      this.crosswordGame.global.state === GameState.Ended &&
      playerCreatedGame &&
      this.crosswordGame.global.published &&
      crossword.type == CrosswordType.Built;
    const canChangeGamePlayerColor = isCurrentPlayer && CrosswordGameUtil.getAvailablePlayerColors(this.crosswordGame).length > 0;
    this.crosswordActionButtons = [];
    this.crosswordActionButtons.push({
      id: 'start-crossword-game',
      text: this.translateService.instant('buttons.start-play'),
      icon: 'launch',
      visible: allowToStartGame,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onStartGame() },
    });

    this.crosswordActionButtons.push({
      id: 'lay-characters',
      text: this.translateService.instant('buttons.lay-characters', {
        charsSet: charStatistics.charsSetCount,
        maxChars: charStatistics.maxChars,
      }),
      badgeText: charStatistics.charsSetCount > 0 ? charStatistics.charsSetScore.toString() : null,
      icon: 'done',
      visible: canSeeCharActions && isCurrentPlayer,
      disabled: !canLayChars,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onLayChars() },
    });
    this.crosswordActionButtons.push({
      id: 'join-crossword-game',
      text: this.translateService.instant('buttons.join-game'),
      icon: 'person_add',
      visible: canJoinGame,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onJoinGame() },
    });
    this.crosswordActionButtons.push({
      id: 'invite-players',
      text: this.translateService.instant('buttons.invite-players'),
      icon: 'share',
      visible: allowToSendShare,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onInvitePlayers() },
    });
    this.crosswordActionButtons.push({
      id: 'end-game',
      text: matchGame ? this.translateService.instant('buttons.end-game') : this.translateService.instant('buttons.end-play'),
      icon: 'exit_to_app',
      visible: canEndGame,
      menuOnly: true,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onEndGame() },
    });
    this.crosswordActionButtons.push({
      id: 'leave-game',
      text: this.translateService.instant('buttons.leave-game'),
      icon: 'person_remove',
      menuOnly: true,
      visible: canLeaveGame,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onLeaveGame() },
    });
    this.crosswordActionButtons.push({
      id: 'publish-result',
      text: this.translateService.instant('buttons.publish-crossword-game'),
      icon: 'publish',
      visible: canPublishResult,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onChangePublishResult(true) },
    });
    this.crosswordActionButtons.push({
      id: 'unpublish-crossword-game',
      text: this.translateService.instant('buttons.unpublish-crossword-game'),
      icon: 'unpublished',
      visible: canUnpublishResult,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onChangePublishResult(false) },
    });
    this.crosswordActionButtons.push({
      id: 'clear-characters',
      text: this.translateService.instant('buttons.clear-chars'),
      icon: 'clear_all',
      visible: canClearChars,
      disabled: charStatistics.charsSetCount == 0,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onClearChars() },
    });
    this.crosswordActionButtons.push({
      id: 'change-game-player-color',
      text: this.translateService.instant('buttons.change-game-player-color'),
      icon: 'palette',
      visible: canChangeGamePlayerColor,
      menuOnly: true,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onChangeGamePlayerColor() },
    });
    this.crosswordActionButtons.push({
      id: 'show-crossword-description',
      text: this.translateService.instant('buttons.show-crossword-description'),
      icon: 'lightbulb',
      visible: descriptionExists,
      menuOnly: true,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onShowDescription() },
    });
    this.crosswordActionButtons.push({
      id: 'show-crossword-game-rules',
      text: this.translateService.instant('buttons.show-crossword-game-rules'),
      icon: 'rule',
      menuOnly: true,
      type: FlexibleButtonType.Standard,
      standardAction: { event: () => this.onShowRules() },
    });
    this.crf.detectChanges();
  }
  private async onChangeGamePlayerColor() {
    const newColor = await this.gamePlayerColorService.getNewPlayerColor(this.crosswordGame, false, true);
    if (!newColor) {
      return;
    }
    await this.dialogService.wrapInProgress(async () => {
      const crosswordGameToUpdate: ICrosswordGame = CrosswordGameUtil.getCopyOfCrosswordGame(this.crosswordGame);
      CrosswordGamePlayerUtil.setPlayerToGamePlayerColor(crosswordGameToUpdate, this.getPlayer(), newColor);
      await this.updateCrosswordGame(crosswordGameToUpdate);
    }, this.translateService.instant('messages.save-color-in-game'));
  }
  private async onChangePublishResult(value: boolean) {
    const crosswordGameToUpdate: ICrosswordGame = CrosswordGameUtil.getCopyOfCrosswordGame(this.crosswordGame);
    crosswordGameToUpdate.global.published = value;
    await this.dialogService.wrapInProgress(
      () => this.updateCrosswordGame(crosswordGameToUpdate),
      this.translateService.instant('messages.publish-crossword-game-result'),
    );
    this.componentCommunicationService.publishedChanged.next();
  }
  private async onInvitePlayers() {
    this.dialogService.showShareGame({ crosswordGame: this.crosswordGame, player: this.getPlayer() });
  }

  async onStartGame() {
    this.closeCurrentGameNotifications();
    const message = this.translateService.instant('messages.start-game');
    this.dialogService.wrapInProgress(
      () => this.crosswordGameService.startGame({ gameId: this.crosswordGame._id, player: this.getPlayer() }),
      message,
    );
  }
  async onLeaveGame() {
    const result = await this.dialogService.showMessageBox(
      this.translateService.instant('message-titles.leave-crossword'),
      this.translateService.instant('messages.leave-crossword'),
      MessageBoxType.YesNo,
      true,
    );
    if (result) {
      this.closeCurrentGameNotifications();
      this.dialogService.wrapInProgress(
        () => this.crosswordGameService.leaveGame({ gameId: this.crosswordGame._id, player: this.getPlayer() }),
        this.translateService.instant('messages.leave-game'),
      );
    }
  }
  async onEndGame() {
    const result = await this.dialogService.showMessageBox(
      this.translateService.instant('message-titles.end-crossword'),
      this.translateService.instant('messages.end-crossword'),
      MessageBoxType.YesNo,
      true,
    );
    if (result) {
      this.closeCurrentGameNotifications();
      this.dialogService.wrapInProgress(
        () => this.crosswordGameService.stopGame({ gameId: this.crosswordGame._id, player: this.getPlayer() }),
        this.translateService.instant('messages.end-game'),
      );
    }
  }
  private closeCurrentGameNotifications() {
    this.pushNotificationService.closeNotifications(this.crosswordGame._id, GameNotificationType.CurrentPlayerChanged);
  }

  async onJoinGame() {
    const crosswordGameToUpdate = CrosswordGameUtil.getCopyOfCrosswordGame(this.crosswordGame);
    AssertUtil.assert(() => !CrosswordGamePlayerUtil.isPlayerInCrosswordGame(this.crosswordGame, this.getPlayer()));
    AssertUtil.assert(() => CrosswordGameUtil.getAvailablePlayerColors(this.crosswordGame).length > 0);
    const playerInfo = CrosswordGamePlayerUtil.getPlayersPlayerInfo(this.getPlayer(), this.getPlayerSettings());
    if (CrosswordGamePlayerUtil.isPlayerColorDefault(playerInfo.gamePlayerColor)) {
      playerInfo.gamePlayerColor = await this.gamePlayerColorService.getNewPlayerColor(crosswordGameToUpdate, false);
    } else if (CrosswordGamePlayerUtil.isPlayerColorAvailable(this.crosswordGame, playerInfo.gamePlayerColor)) {
      playerInfo.gamePlayerColor = await this.gamePlayerColorService.getNewPlayerColor(crosswordGameToUpdate, true);
    }

    this.dialogService.wrapInProgress(async () => {
      await this.joinGameAndHandleIfColorIsTaken(playerInfo);
    }, this.translateService.instant('messages.join-game'));
  }
  private async joinGameAndHandleIfColorIsTaken(playerInfo: PlayerInfo) {
    try {
      await this.crosswordGameService.joinGame({ gameId: this.crosswordGame._id, player: this.getPlayer(), playerInfo: playerInfo });
    } catch (error) {
      const errorResult: ErrorResult = error.error;
      if (errorResult?.type === ErrorType.USER_COLOR_TAKEN) {
        setTimeout(async () => {
          await this.crosswordGameService.checkIfCrosswordGameIsUpdated();
          await this.dialogService.showMessageBox('', this.translateService.instant('messages.player-color-taken'), MessageBoxType.Ok);
          this.getPlayerSettings().preferredPlayerColor = GamePlayerColor.Default;
          this.onJoinGame();
        }, 0);
      } else if (errorResult?.type === ErrorType.GAME_ALREADY_STARTED) {
        setTimeout(
          async () =>
            await this.dialogService.showMessageBox('', this.translateService.instant('errors.game-already-started'), MessageBoxType.Error),
          0,
        );
      } else {
        throw error;
      }
    }
  }

  getPlayerSettings() {
    return this.userService.getPlayerSettings();
  }
  async onLayChars() {
    const crosswordGameToUpdate = CrosswordGameUtil.getCopyOfCrosswordGame(this.crosswordGame);

    const charsSetOnTile = this.gameHandleCrosswordView.getCharsSetOnTile();
    const correctCharsPoints = this.crosswordGamePlay.getCorrectCharPoints(charsSetOnTile);
    const wrongCharPoints = this.crosswordGamePlay.getWrongCharPoints(charsSetOnTile);
    const playScore = this.crosswordGamePlay.getScoreForCharSet(correctCharsPoints);
    const percentageCorrect = Math.round((correctCharsPoints.length / this.crosswordGamePlay.getTotalNumberOfChars()) * 100);
    const resultFromCheckingAndStoringChars = await this.checkAndAddStoredCharsWhenLayChars(crosswordGameToUpdate);
    if (!resultFromCheckingAndStoringChars) {
      return;
    }
    this.dialogService.wrapInProgress(
      async () => {
        this.closeCurrentGameNotifications();
        this.gameHandleCrosswordView.setAsReadonlyAndShowCorrectAndWrongTiles(correctCharsPoints, wrongCharPoints);
        await lastValueFrom(timer(500));
        this.addNotificationService.charsAdded(crosswordGameToUpdate, charsSetOnTile.length, correctCharsPoints, playScore);
        this.updateCrosswordGameWhenCharsBeenSet(crosswordGameToUpdate, correctCharsPoints, playScore.getTotalScore(), percentageCorrect);
        await this.updateCrosswordGame(crosswordGameToUpdate);
      },
      this.translateService.instant('messages.lay-chars'),
      true,
    );
  }
  async onSendChatMessages(message: string) {
    const currentPlayer = this.userService.getLoggedIn();
    await this.crosswordGameService.addChatMessage({
      gameId: this.crosswordGame._id,
      chatMessage: { player: currentPlayer, message: message },
    });
  }
  async onClearChars() {
    this.gameHandleCrosswordView.clearChars();
  }
  async onShowDescription() {
    await this.dialogService.showMessageBox(
      this.translateService.instant('titles.crossword-description', { name: this.crosswordGame.initial.crossword.name }),
      StringUtil.replaceNewLineWithBr(this.crosswordGame.initial.crossword.description),
      MessageBoxType.Ok,
    );
  }
  async onShowRules() {
    this.helpDialogsService.showDialog(HelpInstruction.Game);
  }
  private async checkAndAddStoredCharsWhenLayChars(crosswordGameToUpdate: ICrosswordGame) {
    const charsLeftToSet = this.gameHandleCrosswordView.getCharStatisticts();
    const remainingChars = charsLeftToSet.maxChars - charsLeftToSet.charsSetCount;
    if (remainingChars > CrosswordConstants.MaxCharsStored) {
      const result = await this.dialogService.showMessageBox(
        '',
        this.translateService.instant('messages.remaing-chars-more-then-possible-to-store', {
          maxStoredChars: CrosswordConstants.MaxCharsStored,
          numberOfCharsThrown: remainingChars - CrosswordConstants.MaxCharsStored,
        }),
        MessageBoxType.YesNo,
        true,
      );
      if (!result) {
        return false;
      }
    }
    CrosswordGameUtil.setCharStoredToPlayer(crosswordGameToUpdate, Math.min(remainingChars, CrosswordConstants.MaxCharsStored));
    return true;
  }

  private async handleUserWantsToJoinGame() {
    const currentPlayerIsInGame = CrosswordGamePlayerUtil.isPlayerInCrosswordGame(this.crosswordGame, this.getPlayer());
    const playerCreatedGame = CrosswordGamePlayerUtil.isCreatorOfGame(this.crosswordGame, this.getPlayer());
    if (!currentPlayerIsInGame && !playerCreatedGame) {
      const result = await this.dialogService.showMessageBox(
        '',
        this.translateService.instant('messages.user-wants-to-join-game'),
        MessageBoxType.YesNo,
        true,
      );
      if (result) {
        this.onJoinGame();
      }
    }
  }
  private async updateCrosswordGame(game: ICrosswordGame) {
    await this.crosswordGameService.updateCrosswordGame(game);
  }
  private updateCrosswordGameWhenCharsBeenSet(
    crosswordGameToUpdate: ICrosswordGame,
    correctCharsSet: Point[],
    score: number,
    percentageCorrect: number,
  ) {
    const currentTurn = CrosswordGameTurnUtil.getCurrentGameTurn(crosswordGameToUpdate);
    const totalNumberOfTurns = crosswordGameToUpdate.turns.totalNumberOfTurns;
    const nextPlayerTurn = CrosswordGameTurnUtil.getNextPlayerTurn(
      crosswordGameToUpdate.turns.playerTurns.length,
      crosswordGameToUpdate.playersInfo.players.length,
    );
    CrosswordGameTurnUtil.addPlayerTurn(crosswordGameToUpdate, correctCharsSet);
    CrosswordGameUtil.addPlayerScoreTo(crosswordGameToUpdate, score, percentageCorrect);
    crosswordGameToUpdate.turns.turn = currentTurn;
    const allCharsSet = CrosswordGameUtil.areAllCharsSet(crosswordGameToUpdate);
    if (allCharsSet || nextPlayerTurn >= totalNumberOfTurns) {
      crosswordGameToUpdate.global.state = GameState.Ended;
      this.addNotificationService.gameCompleted(crosswordGameToUpdate);
    } else {
      if (crosswordGameToUpdate.global.type == GameType.Match) {
        CrosswordGameTurnUtil.changeToNextPlayer(crosswordGameToUpdate);
        this.addNotificationService.currentPlayerChanged(crosswordGameToUpdate);
      }
    }
  }
  private getPlayer(): IPlayer {
    return this.userService.getLoggedIn();
  }
  private hasPlayedCrosswordGameBefore() {
    return this.crosswordGameService.hasPlayedCrosswordGameBefore();
  }
}
