export enum ErrorType {
  WRONG_EMAIL,
  WRONG_PASSWORD,
  NO_USER_LOGGED_IN,
  NO_ADMINISTER_USER_LOGGED_IN,
  USER_CANT_ACCESS_DATA,
  NOT_IMPLEMENTED,
  UNKNOWN,
  NOT_ENABLED,
  OLD_PASSWORD_DOSNT_MATCH,
  QUESTION_ALREADY_EXISTS,
  CROSSWORD_WITH_NAME_ALREADY_EXISTS,
  USER_COLOR_TAKEN,
  GAME_ALREADY_STARTED,
  FORGOT_USER_EMAIL_DOSNT_EXIST,
  USER_WITH_EMAIL_EXISTS,
  USER_CODE_DOSNT_MATCH,
  FAILED_TO_GENERATE_QUESTION,
  CREATE_USER_EMAIL_DOESNT_EXIST,
  MORE_THEN_ONE_CROSSWORD_GAME_SHARE_CODE,
  FAILED_TO_SEARCH_FOR_IMAGES,
  FAILED_TO_DOWNLOAD_IMAGE,
  QUESTION_DOESNT_EXIST,
  INVALID_ROLE,
  CROSSWORD_NOT_FOUND,
}
export interface ErrorResult {
  message: string;
  code: number;
  type: ErrorType;
}
export function ToErrorResult(error) {
  if (typeof error.code === 'number') {
    return error as ErrorResult;
  }
  if (typeof error.error.code === 'number') {
    return error.error as ErrorResult;
  }
  throw error;
}
export class ErrorResultUtil {
  static async ExecuteAndCatchError<T, V>(func: () => Promise<T>, errorType: ErrorType, errorHandler: () => Promise<V>) {
    try {
      return await func();
    } catch (error) {
      const errorResult: ErrorResult = error.error;
      if (errorResult?.type === errorType) {
        return await errorHandler();
      }
      throw error;
    }
  }
}
