<h3 mat-dialog-title style="text-align: center">{{ 'titles.questions-priority' | translate }}</h3>
<mat-dialog-content fxLayout="column" fxLayoutGap="10px">
  <app-question-priority (prioritySelected)="onPrioritySelected($event)"></app-question-priority>
</mat-dialog-content>
<mat-dialog-actions>
  <div fxFlex fxLayout="row" fxLayoutAlign="end center">
    <button test-id="ok-button" [disabled]="priorityValue < 0" (click)="onCloseDialogWithValue()" mat-raised-button cdkFocusInital>
      {{ 'buttons.ok' | translate }}
    </button>
    @if (!data.disableCancel) {
      <button test-id="cancel-button" (click)="onCancel()" mat-raised-button>
        {{ 'buttons.cancel' | translate }}
      </button>
    }
  </div>
</mat-dialog-actions>
