<mat-form-field fxFlex>
  <mat-label>{{ 'labels.new-tag' | translate }}</mat-label>
  <mat-chip-grid #chipList>
    @for (tag of tags.value; track tag) {
      <mat-chip-row [removable]="true" (removed)="onMatChipRemoved(tag)">
        {{ tag }}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip-row>
    }
    <input
      placeholder="{{ 'labels.new-tag' | translate }}"
      #tagsInputElement
      [formControl]="tagInput"
      [matAutocomplete]="auto"
      [matChipInputFor]="chipList"
      [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
      (matChipInputTokenEnd)="onMatChipSeperatorKey()"
    />
  </mat-chip-grid>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onTagSelected($event)">
    @for (tag of filteredTags | async; track tag) {
      <mat-option [value]="tag">
        {{ tag }}
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
