<div fxFlexFill #parentFlexButton>
  <div fxFlexFill listenForResize (sizeChanged)="onSizeChanged($event)">
    <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="5px">
      @for (button of standardButtons; track button) {
        @if (canShowButton(button, FlexibleButtonType.Standard)) {
          <button
            [id]="button.id"
            [disabled]="button.disabled ? true : false"
            mat-raised-button
            [ngClass]="{ 'stretch-badge': button.badgeText?.length > 2 }"
            [matBadge]="button.badgeText"
            [matBadgeHidden]="button.badgeText ? false : true"
            (click)="button.standardAction.event()"
            [ngStyle]="{ 'z-index': button.badgeText ? '100' : '1' }"
          >
            @if (button.icon != null) {
              <mat-icon>{{ button.icon }}</mat-icon>
            }
            {{ button.text }}
          </button>
        }
        @if (canShowButton(button, FlexibleButtonType.Link)) {
          <a [id]="button.id" [disabled]="button.disabled ? true : false" mat-raised-button [href]="button.link.href">
            @if (button.icon != null) {
              <mat-icon>{{ button.icon }}</mat-icon>
            }
            {{ button.text }}
          </a>
        }
      }
      @if (menuButtons && menuButtons.length > 0) {
        <button id="expand-button-menu" mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      }
      <mat-menu #menu>
        @for (button of menuButtons; track button) {
          @if (canShowButton(button, FlexibleButtonType.Standard)) {
            <button
              [id]="button.id"
              [disabled]="button.disabled ? true : false"
              mat-menu-item
              [matBadge]="button.badgeText"
              [matBadgeHidden]="button.badgeText != null ? true : false"
              matBadgeSize="small"
              class="stretch-badge"
              (click)="button.standardAction.event()"
            >
              @if (button.icon != null) {
                <mat-icon>{{ button.icon }}</mat-icon>
              }
              {{ button.text }}
            </button>
          }
          @if (canShowButton(button, FlexibleButtonType.Link)) {
            <a [id]="button.id" [disabled]="button.disabled ? true : false" mat-menu-item [href]="button.link.href">
              @if (button.icon != null) {
                <mat-icon>{{ button.icon }}</mat-icon>
              }
              {{ button.text }}
            </a>
          }
        }
      </mat-menu>
    </div>
  </div>
</div>
