import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import scrollIntoViewIfNeeded from 'scroll-into-view-if-needed';
import { Direction } from '../../../../../../../Common/data/position';
import {
  CrosswordTile,
  CrosswordTileEvent,
  TileContainerType,
  TileEventType,
  TileType,
  TilesState,
} from '../../../../../../../Common/data/tile';
import { AssertUtil } from '../../../../../../../Common/util/assert.util';
import { TileKeyService, TileKeyUp } from '../../../../services/tile-key/tile-key.service';
import { ShowCrosswordAs } from '../../../util/handleCrosswordView/handle.crossword.view';
import { WaitUtil } from '../../../util/wait.util';

class StateAndColors {
  static readonly textSetInInput = getComputedStyle(document.documentElement).getPropertyValue('--tile-style-text-set-in-input');
  static textInputDisabled = 'rgba(0,0,0,0)';
}
export enum ArrowDirection {
  Vertical,
  Horizontal,
}

@Component({
  selector: 'app-crossword-tile',
  templateUrl: './crossword-tile.component.html',
  styleUrls: ['./crossword-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CrosswordTileComponent implements OnInit, OnChanges {
  ArrowDirection = ArrowDirection;
  Math = Math;
  TileType = TileType;
  StateAndColors = StateAndColors;
  @ViewChild('tileInput')
  tileInput: ElementRef;

  @Input()
  tile: CrosswordTile;

  @Input()
  tileSize: number;

  @Input()
  tilesState: TilesState;

  @Input()
  tileContainerType: TileContainerType;

  @Output()
  tileEvent = new EventEmitter();

  @Input()
  matrixDirection: Direction;

  @Input()
  showCrosswordAs: ShowCrosswordAs;

  canEdit = true;
  questionDirection?: ArrowDirection;
  private inited = false;

  constructor(private tileKeyService: TileKeyService) {}
  async ngOnInit() {
    if (this.isQuestionType()) {
      this.setQuestionDirection();
    }
    if (this.tile.type == TileType.Input) {
      await WaitUtil.waitUntilSet(() => this.tileInput);
    }
    this.setValuesFromTilesState();
    this.inited = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.inited) {
      return;
    }
    if (changes.tilesState) {
      this.setValuesFromTilesState();
    }
    if (changes.matrixDirection) {
      if (this.isQuestionType()) {
        this.setQuestionDirection();
      }
    }
    if (changes.showCrosswordAs) {
      if (this.isTheCurrentFocusedTile()) {
        this.focusTile();
      }
    }
  }

  onQuestionTileClicked() {
    this.emitEvent(TileEventType.QuestionTileClicked, this.tile.cwQuestions[0]);
  }
  onAddQuestionTileClicked() {
    this.emitEvent(TileEventType.AddQuestionTileClicked);
  }
  onReadonlyCharClicked() {
    this.emitEvent(TileEventType.ReadonlyCharTileClicked);
  }
  onFocused() {
    if (!this.isTheCurrentFocusedTile()) {
      this.emitEvent(TileEventType.InFocus);
    }
  }
  onInputTextChanged(text: string) {
    this.tile.text = text.toUpperCase();
    this.emitEvent(text.length > 0 ? TileEventType.TextAdded : TileEventType.TextRemoved, text.toUpperCase());
  }
  onKeyup(event: KeyboardEvent) {
    if (event.key && event.code) {
      this.handlePhysicalKeyboardEvent(event);
    } else {
      this.handleOnScreenKeyboardEvent(event);
    }
  }

  private handleOnScreenKeyboardEvent(event: KeyboardEvent) {
    const firstChar: string = this.getCharFromHtmlElement(event);
    const tileKeyUp: TileKeyUp = this.tileKeyService.getTileKeyCode(firstChar);
    AssertUtil.assert(
      () => tileKeyUp == null || tileKeyUp == TileKeyUp.Character,
      "Doesn't handle nonchar keyboard events on onscreen keyboard",
    );
    if (tileKeyUp == TileKeyUp.Character) {
      this.handleSetCharEvent(firstChar);
    } else if (!firstChar) {
      //Probably a delete event on onscreen keyboard
      this.handleBackspaceKey();
    }
  }

  private handlePhysicalKeyboardEvent(event: KeyboardEvent) {
    const key = event.key;
    const tileKeyUp: TileKeyUp = this.tileKeyService.getTileKeyCode(key);
    if (tileKeyUp != null) {
      if (tileKeyUp == TileKeyUp.Character) {
        this.handleSetCharEvent(key);
      } else if (tileKeyUp == TileKeyUp.Backspace) {
        this.handleBackspaceKey();
      } else {
        this.handleArrowKey(tileKeyUp);
      }
    } else {
      this.setTextOnTile('');
      this.emitEvent(TileEventType.TextRemoved, '');
    }
  }
  private handleArrowKey(tileKeyUp: TileKeyUp) {
    this.emitEvent(TileEventType.KeyUp, tileKeyUp);
  }

  private handleBackspaceKey() {
    if (this.tile.text) {
      this.setTextOnTile('');
      this.emitEvent(TileEventType.TextRemoved, '');
    } else {
      this.emitEvent(TileEventType.KeyUp, TileKeyUp.Backspace);
    }
  }
  private handleSetCharEvent(key: string) {
    this.setTextOnTile(key.toUpperCase());
    this.emitEvent(TileEventType.KeyUp, TileKeyUp.Character);
    this.emitEvent(TileEventType.TextAdded, this.tile.text);
  }
  private getCharFromHtmlElement(event: KeyboardEvent) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: string = (event.target as any).value;
    const firstChar: string = value ? value.substring(0, 1) : '';
    return firstChar;
  }

  private setValuesFromTilesState() {
    if (this.tilesState.focused.tile === this.tile) {
      this.focusTile();
    }
    if (this.tile.type === TileType.Input) {
      this.canEdit = this.tilesState.canAddChars || this.tile.text.length > 0;
    }
  }
  private focusTile() {
    if (this.tile.type == TileType.Input) {
      this.focusNativeElementAndScrollIntoView();
    }
  }

  private focusNativeElementAndScrollIntoView() {
    if (document.activeElement == this.tileInput.nativeElement) {
      return;
    }
    this.tileInput.nativeElement.focus();
    this.tileInput.nativeElement.select();
    scrollIntoViewIfNeeded(this.tileInput.nativeElement, { scrollMode: 'if-needed' });
  }

  private setTextOnTile(text: string) {
    this.tile.text = text;
    this.tileInput.nativeElement.value = text;
  }

  private emitEvent(tileEventType: TileEventType, data: unknown = null) {
    this.tileEvent.emit({ tile: this.tile, event: tileEventType, data } as CrosswordTileEvent);
  }
  private isTheCurrentFocusedTile() {
    return this.tilesState?.focused?.tile === this.tile ?? false;
  }
  private isQuestionType() {
    return this.tile.type === TileType.Question || this.tile.type == TileType.PriorityQuestion;
  }
  private setQuestionDirection() {
    if (this.matrixDirection == Direction.MAINAXIS) {
      this.questionDirection =
        this.tile.tileCWQuestion.direction == Direction.MAINAXIS ? ArrowDirection.Horizontal : ArrowDirection.Vertical;
    } else {
      this.questionDirection =
        this.tile.tileCWQuestion.direction == Direction.MAINAXIS ? ArrowDirection.Vertical : ArrowDirection.Horizontal;
    }
  }
}
