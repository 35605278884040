import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ICrossword } from '../../../../../Common/model/crossword';
import { CrosswordService } from '../../services/crossword/crossword.service';
import { MessageBoxType } from '../dialog/message-box-content/message-box-content.component';
import { DialogService } from '../dialog/service/dialog.service';
import { GameSmallHandleCrosswordView } from '../util/handleCrosswordView/game.small.handle.crossword.view';

class StoredResetNumber {
  private value: number;
  private timestamp: number;
  constructor(
    private id: string,
    private valueMax: number,
    private timeoutAfterMinutes: number,
  ) {
    const storedValue = localStorage.getItem(this.id);
    if (storedValue) {
      this.value = Number.parseInt(storedValue);
      this.timestamp = Number.parseInt(localStorage.getItem(this.id + '_timestamp'));
    }
    setInterval(() => this.checkTimeoutAndReset(), 60000);
  }
  getValue() {
    return this.value;
  }
  getTimestamp() {
    return this.timestamp;
  }
  setValue(value: number) {
    this.value = value;
    this.timestamp = Date.now();
    localStorage.setItem(this.id, this.value.toString());
    localStorage.setItem(this.id + '_timestamp', this.timestamp.toString());
  }
  isValueMoreThenMax() {
    return this.value > this.valueMax;
  }
  private checkTimeoutAndReset() {
    if (this.isValueMoreThenMax() && this.hasTimedout()) {
      this.setValue(0);
    }
  }
  private hasTimedout() {
    return Date.now() > this.timestamp + this.timeoutAfterMinutes * 60 * 1000;
  }
}

@Component({
  selector: 'app-captcha-game',
  templateUrl: './captcha-game.component.html',
})
export class CaptchaGameComponent implements OnInit {
  readonly handleCrosswordView: GameSmallHandleCrosswordView;
  crossword: ICrossword;
  toManyFailedAttempts = false;

  private failedAttempts = new StoredResetNumber('failed-captcha-attempts', 2, 15);
  private refreshedCrosswordsCount = new StoredResetNumber('refreshed-crosswords', 2, 15);
  constructor(
    private dialogService: DialogService,
    private translateService: TranslateService,
    private crosswordsService: CrosswordService,
    public matDialogRef: MatDialogRef<CaptchaGameComponent>,
  ) {
    this.handleCrosswordView = new GameSmallHandleCrosswordView();
  }

  async ngOnInit() {
    if (this.failedAttempts.isValueMoreThenMax()) {
      this.toManyFailedAttempts = true;
      return;
    }
    const crosswordTmp = await this.crosswordsService.getRandomCaptchaCrossword();
    this.setCrossword(crosswordTmp);
  }

  async onCompletedCrosswordClicked() {
    if (this.handleCrosswordView.isCorrect()) {
      this.dialogService.showSnackbar(this.translateService.instant('messages.captcha-crossword-is-correct'), 3000);
      this.failedAttempts.setValue(0);
      this.matDialogRef.close(true);
      return;
    }
    this.failedAttempts.setValue(this.failedAttempts.getValue() + 1);
    if (this.failedAttempts.isValueMoreThenMax()) {
      this.toManyFailedAttempts = true;
      return;
    }
    await this.dialogService.showMessageBox(
      '',
      this.translateService.instant('messages.captcha-crossword-is-incorrect'),
      MessageBoxType.Ok,
    );
    const crosswordTmp = await this.dialogService.wrapInProgress(() => this.crosswordsService.getRandomCaptchaCrossword());
    this.setCrossword(crosswordTmp);
  }

  async onRefreshCrossword() {
    if (this.refreshedCrosswordsCount.isValueMoreThenMax()) {
      await this.dialogService.showMessageBox(
        '',
        this.translateService.instant('messages.small-crossword-has-been-refreshed-to-many-times'),
        MessageBoxType.Ok,
      );
      return;
    }

    this.refreshedCrosswordsCount.setValue(this.refreshedCrosswordsCount.getValue() + 1);
    const crosswordTmp = await this.dialogService.wrapInProgress(() => this.crosswordsService.getRandomCaptchaCrossword());
    console.log('Refreshed to crossword', this.crossword);
    this.setCrossword(crosswordTmp);
  }

  private setCrossword(crosswordTmp: ICrossword) {
    this.handleCrosswordView.refresh(crosswordTmp);
    this.crossword = crosswordTmp;
  }
}
