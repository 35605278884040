import { Injectable } from '@angular/core';
import { CrosswordGameNotification, GameNotificationType } from '../../../../../Common/data/crossword.game.notification';
import { PlayScoreValue } from '../../../../../Common/data/play.score.value';
import { PlayerInfo } from '../../../../../Common/data/player.score';
import { Point } from '../../../../../Common/data/point';
import { ICrosswordGame } from '../../../../../Common/model/crossword.game';
import { IPlayer } from '../../../../../Common/model/player';
import { CrosswordGamePlayerUtil } from '../../../../../Common/util/crossword.game.player.util';
import { CrosswordGameUtil } from '../../../../../Common/util/crossword.game.util';
import { ScoreAndCharsMessageData, ScoreMessageData } from '../../../../../Common/util/game.notification.text.util';
import { GenerateNotificationUtil } from '../../../../../Common/util/generate.notification.util';
import { UserService } from '../user/user.service';

@Injectable({
  providedIn: 'root',
})
export class AddNotificationService {
  constructor(private userService: UserService) {}
  public charsAdded(crosswordGame: ICrosswordGame, charsLayed: number, correctPoints: Point[], playScore: PlayScoreValue) {
    const messageData: ScoreAndCharsMessageData = {
      count: correctPoints.length,
      score: playScore.getTotalScore(),
      extraScore: {
        chars: playScore.charScore,
        completeWords: playScore.completeWordScore,
      },
      charsLayed: charsLayed,
    };
    crosswordGame.notifications.notifications.push(
      this.getNotification(crosswordGame, GameNotificationType.CharsAdded, this.getPlayer(), messageData),
    );
  }
  public passTurn(crosswordGame: ICrosswordGame) {
    crosswordGame.notifications.notifications.push(
      this.getNotification(crosswordGame, GameNotificationType.PassedTurn, this.getPlayer(), null),
    );
  }
  public gameCompleted(crosswordGame: ICrosswordGame) {
    const playerInfo: PlayerInfo = CrosswordGameUtil.getHighestScorePlayer(crosswordGame);
    const highestScorePlayer = CrosswordGamePlayerUtil.getPlayer(crosswordGame, playerInfo.playerId);
    const scoreMessageData: ScoreMessageData = {
      score: playerInfo.score,
      percentageCorrect: playerInfo.percentageCorrect,
      manuallyEnded: false,
    };
    crosswordGame.notifications.notifications.push(
      this.getNotification(crosswordGame, GameNotificationType.GameCompleted, highestScorePlayer, scoreMessageData),
    );
  }
  public currentPlayerChanged(crosswordGame: ICrosswordGame) {
    const currentPlayer = CrosswordGamePlayerUtil.getCurrentPlayer(crosswordGame);
    crosswordGame.notifications.notifications.push(
      this.getNotification(crosswordGame, GameNotificationType.CurrentPlayerChanged, currentPlayer, null),
    );
  }
  public addStartGame(crosswordGame: ICrosswordGame) {
    crosswordGame.notifications.notifications.push(
      this.getNotification(crosswordGame, GameNotificationType.GameStarted, this.getPlayer(), null),
    );
  }
  public joinGame(crosswordGame: ICrosswordGame) {
    crosswordGame.notifications.notifications.push(
      this.getNotification(crosswordGame, GameNotificationType.PlayerJoined, this.getPlayer(), null),
    );
  }
  public leaveGame(crosswordGame: ICrosswordGame) {
    crosswordGame.notifications.notifications.push(
      this.getNotification(crosswordGame, GameNotificationType.PlayerLeft, this.getPlayer(), null),
    );
  }

  private getNotification(
    crosswordGame: ICrosswordGame,
    type: GameNotificationType,
    player: IPlayer,
    messageData: unknown,
  ): CrosswordGameNotification {
    return GenerateNotificationUtil.generateNotification(player, crosswordGame._id, type, messageData);
  }
  private getPlayer(): IPlayer {
    return this.userService.getLoggedIn();
  }
}
