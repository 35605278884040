import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeWhile } from 'rxjs/operators';
import { UserService } from 'src/app/services/user/user.service';
import { ErrorResult, ErrorType } from '../../../../../../Common/data/error.result';
import { CreateUser } from '../../../../../../Common/model/user';
import { LanguageUtil } from '../../../../../../Common/util/language.util';
import { ComponentCommunicationService } from '../../../services/componentcommunication/component-communication.service';
import { StoreRouteService } from '../../../services/storeroute/store.route.service';
import { PropertyErrorStateMatcher } from '../../../util/property.error.state.matcher';
import { MessageBoxType } from '../../dialog/message-box-content/message-box-content.component';
import { CaptchaDialogService } from '../../dialog/service/captcha-dialog.service';
import { DialogService } from '../../dialog/service/dialog.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
})
export class CreateUserComponent implements OnInit, OnDestroy {
  createUserForm: UntypedFormGroup;
  confirmPasswordMatcher = new PropertyErrorStateMatcher('notTheSamePassword');

  confirmPasswordValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const pass = control.get('password').value;
    const confirmPassword = control.get('confirmPassword').value;
    return pass === confirmPassword ? null : { notTheSamePassword: true };
  };
  redirectUrl: string = null;
  checkedAndConfirmedThatPlayerIsHuman = false;
  private alive = true;
  private readonly defaultLoggedInUrl = '/listCrosswordGames';
  constructor(
    private userService: UserService,
    private dialogService: DialogService,
    private router: Router,
    private translateService: TranslateService,
    private storeRouteService: StoreRouteService,
    private route: ActivatedRoute,
    private componentCommunicationService: ComponentCommunicationService,
    private capchaDialogService: CaptchaDialogService,
  ) {
    this.createForm();
  }
  ngOnDestroy(): void {
    this.alive = false;
  }
  async ngOnInit() {
    this.route.queryParamMap.pipe(takeWhile(() => this.alive)).subscribe((paramMap) => {
      const redirectUrlKey = 'redirectUrl';
      if (paramMap.has(redirectUrlKey)) {
        this.redirectUrl = paramMap.get(redirectUrlKey);
      }
    });
  }
  async checkThatPlayerIsHuman() {
    this.checkedAndConfirmedThatPlayerIsHuman = await this.capchaDialogService.showDialog();
  }
  private createForm() {
    this.createUserForm = new UntypedFormGroup(
      {
        name: new UntypedFormControl('', [Validators.required]),
        email: new UntypedFormControl('', [Validators.required, Validators.email]),
        password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
        confirmPassword: new UntypedFormControl('', [Validators.required]),
      },
      { validators: [this.confirmPasswordValidator] },
    );
  }
  private async getStoredOrDefaultLoggedInUrl() {
    const storedRoute = await this.storeRouteService.getStoredRoute();
    console.log('Stored route', storedRoute);
    if (storedRoute && storedRoute.indexOf('login') < 0) {
      return storedRoute;
    } else {
      return this.defaultLoggedInUrl;
    }
  }
  async onSubmit() {
    if (!this.componentCommunicationService.allowCookies.value) {
      this.dialogService.showMessageBox('', this.translateService.instant('messages.allow-cookies-before-continue'), MessageBoxType.Ok);
      return;
    }
    const controls = this.createUserForm.controls;
    const createUser: CreateUser = {
      email: controls.email.value,
      name: controls.name.value,
      password: controls.password.value,
      languageCode: LanguageUtil.getLanguageCode(this.translateService.getBrowserLang()),
    };
    try {
      await this.dialogService.wrapInProgress(() => this.userService.createVerifyUser(createUser));

      await this.dialogService.wrapInProgress(
        () => this.userService.login(createUser.email, createUser.password),
        this.translateService.instant('messages.signing-in'),
      );
      if (this.redirectUrl != null) {
        this.router.navigateByUrl(decodeURIComponent(this.redirectUrl));
      } else {
        this.router.navigateByUrl(await this.getStoredOrDefaultLoggedInUrl());
      }
    } catch (error) {
      const errorResult: ErrorResult = error.error;
      if (errorResult?.type === ErrorType.USER_WITH_EMAIL_EXISTS) {
        await this.dialogService.showMessageBox(
          '',
          this.translateService.instant('errors.user-with-email-already-exists', { emailAdress: createUser.email }),
          MessageBoxType.Error,
        );
      } else if (errorResult?.type === ErrorType.USER_CODE_DOSNT_MATCH) {
        await this.dialogService.showMessageBox('', this.translateService.instant('errors.user-code-doesnt-match'), MessageBoxType.Error);
      }
    }
  }
}
