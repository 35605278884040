<div fxFlexFill fxLayoutAlign="center center">
  <mat-card test-id="forgot-password-card" fxFlex="0 1 500px" style="margin: 5px 0px">
    <mat-card-header>
      <mat-card-title>
        {{ 'titles.forgot-password' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content fxFlex>
      @if (!showSetNewPassword) {
        <form fxLayout="column" fxFlex [formGroup]="forgotUserForm" (ngSubmit)="onSubmitForValidationCode()" novalidate>
          <div fxFlex fxLayout="column" style="padding-top: 5px">
            <mat-form-field>
              <mat-label>{{ 'labels.enter-email' | translate }}</mat-label>
              <input test-id="enter-email" matInput formControlName="email" required autocomplete="email" />
              @if (forgotUserForm.controls.email.hasError('required')) {
                <mat-error>
                  {{ 'errors.email-missing' | translate }}
                </mat-error>
              }
              @if (forgotUserForm.controls.email.hasError('email')) {
                <mat-error>
                  {{ 'errors.email-adress-invalid' | translate }}
                </mat-error>
              }
            </mat-form-field>
          </div>
        </form>
      }
      @if (showSetNewPassword) {
        <form fxLayout="column" fxFlex [formGroup]="newPasswordForm" (ngSubmit)="onSubmitForValidationCode()" novalidate>
          <div class="form-group" fxFlex fxLayout="column" fxLayoutGap="10px">
            <mat-form-field>
              <mat-label>{{ 'labels.enter-password' | translate }}</mat-label>
              <input matInput test-id="enter-password" formControlName="password" type="password" required autocomplete="new-password" />
              @if (newPasswordForm.controls.password.hasError('required')) {
                <mat-error>
                  {{ 'errors.password-missing' | translate }}
                </mat-error>
              }
              @if (newPasswordForm.controls.password.hasError('minlength')) {
                <mat-error>
                  {{ 'errors.password-to-short' | translate }}
                </mat-error>
              }
            </mat-form-field>
            <mat-form-field>
              <mat-label>{{ 'labels.enter-password-again' | translate }}</mat-label>
              <input
                matInput
                test-id="enter-password-again"
                formControlName="confirmPassword"
                type="password"
                [errorStateMatcher]="confirmPasswordMatcher"
              />
              @if (newPasswordForm.hasError('notTheSamePassword')) {
                <mat-error>
                  {{ 'errors.not-the-same-password' | translate }}
                </mat-error>
              }
            </mat-form-field>
          </div>
        </form>
      }
    </mat-card-content>
    <mat-card-actions fxLayoutGap="10px" fxLayout="row" fxLayoutAlign="end">
      @if (!showSetNewPassword) {
        <mat-checkbox
          test-id="captcha-checkbox"
          color="primary"
          [disabled]="checkedAndConfirmedThatPlayerIsHuman"
          [value]="checkedAndConfirmedThatPlayerIsHuman"
          (change)="checkThatPlayerIsHuman()"
        >
          {{ 'checkboxes.captcha-user-check' | translate }}
        </mat-checkbox>
        <div fxFlex fxLayout="row" fxLayoutAlign="end">
          <button
            test-id="send-code-for-new-password"
            mat-raised-button
            (click)="onSubmitForValidationCode()"
            [disabled]="!forgotUserForm.valid || !checkedAndConfirmedThatPlayerIsHuman"
            color="primary"
            style="margin-right: 8px; margin-bottom: 5px"
          >
            {{ 'buttons.send-code-for-new-password' | translate }}
          </button>
        </div>
      }
      @if (showSetNewPassword) {
        <button
          test-id="set-new-password"
          mat-raised-button
          (click)="onSubmitForNewPassword()"
          [disabled]="!newPasswordForm.valid"
          color="primary"
        >
          {{ 'buttons.set-new-password' | translate }}
        </button>
      }
    </mat-card-actions>
  </mat-card>
</div>
