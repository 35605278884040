import { Injectable, isDevMode } from '@angular/core';
import { BrowserDeviceType, ComponentCommunicationService } from '../componentcommunication/component-communication.service';
import { HttpClientCacheService } from '../http-client-cache/http-client-cache.service';

export interface AppSettings {
  generateCrosswordLocally: boolean;
  apiurl: string;
  vapid: {
    publicKey: '';
  };
  domain: string;
}

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  public appSettings: AppSettings;
  constructor(
    private httpClient: HttpClientCacheService,
    private componentCommunicationService: ComponentCommunicationService,
  ) {}
  public async initialize() {
    this.appSettings = await this.httpClient.get<AppSettings>('assets/appsettings.json', 60000);

    if (isDevMode() && this.componentCommunicationService.deviceType.getValue() == BrowserDeviceType.Android) {
      this.appSettings.apiurl = 'http://192.168.72.20:3200/';
    }
  }
}
